import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Paper, TextField } from '@mui/material';
import Axios from 'axios';
import moment from 'moment-timezone';
import '../../../../styles/userStyles/Admin.css'
export default function AdminAttendance() {


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterValue, setFilterValue] = useState('');
    const [subscription, setSubscription] = useState([])
    const [sortDirection, setSortDirection] = useState('asc');
    const [checkboxChanged, setCheckboxChanged] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL;


    useEffect(() => {
        const fetchData = async () => {
            const data = await Axios.get(`${API_URL}/lukatme/subscription-admin`).then((data) => {
                console.log(data)
                setSubscription(data.data)

            }
            ).catch((err) => {
                console.log("the error is", err)
            })
        };
        fetchData();
    }, [checkboxChanged]);




    const toggleSortDirection = () => {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    };


    const handleCheckbox = async (subscriptionId, classId, traineeId, isChecked) => {
        try {
            const attended = isChecked ? true : false;
            console.log('attended', attended)
            await Axios.put(`${API_URL}/lukatme/update-attendance`, { subscription_id: subscriptionId, class_id: classId, attended: attended });

            setCheckboxChanged(prevCheckboxes => [...prevCheckboxes, classId]);
        } catch (err) {
            console.error(err);
        }
    };
    console.log("check", checkboxChanged)


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleFilterChange = (event) => {
        setFilterValue(event.target.value);
    };

    const useStyles = makeStyles({
        root: {
            fontSize: '16px',
        },
    });

    const renderRowsPerPageLabel = ({ from, to, count }) => (
        <span style={{ fontSize: '10px' }}>{`${from}-${to} of ${count} Rows per page:`}</span>
    );

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div className='adminclass'>
                <Typography sx={{ pt: 2, pb: 1, flex: '1 1 100%' }} variant="body2" textAlign={'right'}>
                    جدول التحضير
                </Typography>

            </div>
            <Typography sx={{ pt: 2, pb: 5 }} variant="body2" textAlign={'right'}>
                الفئات المضافة الحديثة
            </Typography>
            <TableContainer component={Paper}>

                <TextField label="Filter" value={filterValue} onChange={handleFilterChange}>

                </TextField>
                <button onClick={toggleSortDirection}>
                    {sortDirection === 'asc' ? '▲' : '▼'}
                </button>
                <Table className='attendance-table' variant="body4" >
                    <TableHead>
                        <TableRow>
                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                اسم الكلاس
                            </Typography></TableCell>
                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                وقت الكلاس
                            </Typography></TableCell>
                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                تاريخ الكلاس
                            </Typography></TableCell>
                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                اسم المدربة
                            </Typography></TableCell>
                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                اسم المتدربة
                            </Typography></TableCell>

                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                ID
                            </Typography></TableCell>
                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                حالة الحضور
                            </Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            subscription.map(row => (
                                row.enrolledClasses.map((classInfo) => {
                                    return (
                                        <TableRow key={classInfo._id}>
                                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                                {classInfo.class_name}
                                            </Typography></TableCell>
                                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                                {/* {moment.tz(classInfo.class.date.start_date, "Asia/Riyadh").format('h:mm A')} */}
                                                {classInfo.class?.date?.start_date ? moment.tz(classInfo.class.date.start_date, "Asia/Riyadh").format('h:mm A') : 'N/A'}
                                            </Typography></TableCell>
                                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                                {classInfo.class?.date?.start_date ? new Date(classInfo.class.date.start_date).toLocaleDateString() : 'N/A'}
                                            </Typography></TableCell>
                                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                                {classInfo.class?.trainer_info?.trainer_name || 'N/A'}
                                            </Typography></TableCell>
                                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                                {row.user.name}
                                            </Typography></TableCell>
                                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                                {row.package_name}
                                            </Typography></TableCell>
                                            <TableCell> <Typography sx={{ pt: 2, pb: 5 }} variant="body4" textAlign={'right'}>
                                                <input
                                                    className="attendance-checkbox"
                                                    type="checkbox"
                                                    checked={classInfo.attendance}
                                                    onChange={(event) => {
                                                        handleCheckbox(row._id, classInfo.class?._id, row.user._id, event.target.checked)
                                                    }}
                                                />
                                            </Typography></TableCell>
                                        </TableRow>
                                    )
                                })
                            ))
                        }
                    </TableBody>

                </Table>

                <TablePagination
                    classes={{ root: useStyles.root }}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    style={{ fontSize: '10px' }}
                    labelRowsPerPage=''
                    labelDisplayedRows={renderRowsPerPageLabel}
                />
            </TableContainer >
        </ThemeProvider>
    )

}