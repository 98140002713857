import { useState } from 'react';
import axios from 'axios';
import '../styles/ForgotPassword.css'
import { Link } from "react-router-dom";
import { Button, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from './GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
const ForgotPassword = () => {
    const [message, setMessage] = useState('');
    const API_URL = process.env.REACT_APP_API_URL;

    const validationSchema = Yup.object({
        email: Yup.string().email('إيميل غير صحيح').required('زودينا بإيميلك من فضلك'),
    });


    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (e, { resetForm }) => {
            const { email } = e;
            try {
                const response = await axios.post(`${API_URL}/lukatme/forgotpassword`, { email });
                formik.resetForm();
                formik.setErrors({
                    errorLabel: 'تم إرسال خطوات تغيير كلمة السر إلى بريدك',
                });

            } catch (error) {
                console.log(error)
                if (error.response) {
                    let message = '';
                    console.log(error.response.status, error.response)
                    switch (error.response.status) {
                        case 404:
                            message = 'إيميل غير صحيح';
                            break;
                        case 500:
                        case 200:
                            setIsRegistered(true);
                            console.log('updated')
                            message = 'تم إرسال خطوات تغيير كلمة السر إلى بريدك';
                            break;
                        default:
                            message = 'خطأ في النظام ';
                    }
                    formik.setErrors({
                        errorLabel: message,
                    });
                }
            }
        }
    });

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <Grid container sx={{ height: '100vh' }}>
                <Grid item xs={false} sm={4} md={7}
                    sx={{
                        backgroundImage: 'url(/assets/120.jpg)',
                        backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover'
                    }}
                >
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className='resetpass--form--div'>
                        <h6>نسيتي كلمة السر؟</h6>
                        {formik.errors.errorLabel && <div style={{ color: 'red' }}>{formik.errors.errorLabel}</div>}
                        <Box component="form" method="POST" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                type='email'
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="البريد الالكتروني"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                sx={{ color: 'primary.main' }}
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <Button
                                onClick={formik.handleSubmit}
                                fullWidth
                                type='submit'
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }} className="btn btn-primary w-100 theme-btn mx-auto">
                                ارسال
                            </Button>
                        </Box>
                        {message && <p>{message}</p>}
                    </div>

                    <section className='resetpass--go--signin'>

                        <Link className="resetpass--link" to="/signin" >   <Typography variant="body3" color="primary.main" mt={4} mb={4} textAlign='center'> العودة لتسجيل الدخول   </Typography> </Link>


                    </section>
                </Grid>
            </Grid>
        </ThemeProvider >

    );
};

export default ForgotPassword;
