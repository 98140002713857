import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import logo from '../../../assets/logo.png'
import '../../../styles/userStyles/Trainer.css'
import { Image } from "antd";
import {
    MDBNavbar,
    MDBNavbarNav,
    MDBNavItem,
} from "mdbreact";
import GlobalStyleOverrides from '../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../../../context/auth';
import { useNavigate } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import SideMenu from './Sidemenu';
export default function Navbar() {
    const [auth, setAuth] = useAuth()
    const navigate = useNavigate();

    const logoStyle = {
        width: '80px',
        height: '80px',
    };
    useEffect(() => {
        const storedAuth = localStorage.getItem('auth');
        if (storedAuth) {
            setAuth(JSON.parse(storedAuth));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('auth', JSON.stringify(auth));
    }, [auth]);


    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div>
                <MDBNavbar className='navbar'>

                    <SideMenu />
                    {
                        !auth.user ? (
                            navigate('/signin')
                        ) :
                            (

                                <MDBNavbarNav right className='w-auto d-flex flex-row align-items-center w-100' >
                                    <MDBNavItem active>

                                        <Typography
                                            sx={{ pt: 5, pb: 5 }}
                                            variant="body3"
                                            color="primary.main"
                                            textAlign={"center"}
                                        >
                                            {auth?.user.name} ،اهلا وسهلا
                                        </Typography>
                                    </MDBNavItem>

                                    <MDBNavItem active>
                                        <Link to='/'> <Image
                                            src={logo} alt="LukAtMeLogo" style={logoStyle}
                                        ></Image></Link>

                                    </MDBNavItem>


                                </MDBNavbarNav>


                            )
                    }


                </MDBNavbar>
            </div >
        </ThemeProvider>
    );
}
