import React, { useEffect, useState } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { TableContainer } from '@mui/material';
import Axios from 'axios';
import { useAuth } from '../../../../context/auth'
import moment from 'moment-timezone';

function TraineeSubscription() {
    const [subscription, setSubscription] = useState([])
    const [auth, setAuth] = useAuth()
    let token = localStorage.getItem('token')
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const getData = async () => {
            const userId = auth?.user._id
            try {
                // setLoading(true);
                const response = await Axios.get(`${API_URL}/lukatme/allSubscriptions/${userId}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Assuming token is stored in localStorage
                            'Content-Type': 'application/json',
                            Accept: 'application/json',

                        },
                    });
                console.log("data", response.data);
                setSubscription(response.data)
                // setLoading(false)
            } catch (err) {
                console.error('Error:', err);

            };
        }
        getData()
    }, [auth])

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div className='payment--history'>
                <Typography sx={{ pt: 5 }} variant="body2" color="" textAlign={'right'}>
                    الإشتراك الحالي
                </Typography>

                <Typography sx={{ pt: 5, pb: 5 }} variant="body4" color="" textAlign={'right'}>
                    يمكنك الاطلاع على حالة اشتراكك الحالي
                </Typography>
            </div>
            <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
                <Table aria-label="responsive table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right">حالة الاشتراك</TableCell>
                            <TableCell align="right">عدد الكلاسات</TableCell>

                            <TableCell align="right">تاريخ انتهاء الاشتراك</TableCell>
                            <TableCell align="right">تاريخ بداية الاشتراك</TableCell>
                            <TableCell align="right">اسم الباكج</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subscription && subscription.length > 0 ? (subscription.map(subscripe => (
                            <TableRow key={subscription._id}>
                                <TableCell></TableCell>
                                {subscripe.is_active == true ? (
                                    <TableCell align="right">مستمر</TableCell>
                                ) : <TableCell align="right">غير مستمر</TableCell>}
                                <TableCell align="right">{subscripe.class_limit}</TableCell>

                                <TableCell align="right">{moment(subscripe.end_date).format('YYYY-MM-DD')}</TableCell>
                                <TableCell align="right">{moment(subscripe.start_date).format('YYYY-MM-DD')}</TableCell>
                                <TableCell align="right">{subscripe?.package_name} {subscripe?.child_info?.[0]?.child_name ? ' - ' : ''}
                                    {subscripe?.child_info?.[0]?.child_name}</TableCell>
                            </TableRow>
                        ))) : (
                            <TableRow>
                                <TableCell align="center">لا يوجد اشتراكات</TableCell>

                            </TableRow>

                        )}
                    </TableBody>
                </Table>
            </TableContainer>


        </ThemeProvider >
    )

}

export default TraineeSubscription