import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import CustomButton from '../CustomButton.js'
import '../../styles/homeComponentsStyles/EmailNewsLetter.css';
import { BUTTON_TYPES } from "../../data/Button_const";
import axios from 'axios';
import {

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

function EmailNewsLetter() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = React.useState('')
  const [emailValid, setEmailValid] = useState(true);
  const validateEmail = (email) => {
    if (!email) {
      return false;
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    if (validateEmail(enteredEmail)) {
      setEmailValid(true)
    } else {
      setEmailValid(false);
    }


  };

  const handleSubmit = (e) => {
    console.log("comes here .. handle submit", email)
    e.preventDefault();
    if (!validateEmail(email)) {
      return;
    }

    try {
      console.log("try ")
      const response = axios.post(`${API_URL}/api/subscribe`, { email });
      console.log(" resopnse .....")
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 1500);


      // response handling
      if (response.data.success) {
        console.log(" resopnse success.....")
        console.log("valid email")
      } else {
        alert('An error occurred. Please try again.');
      }
    } catch (error) {
      console.log("catche part: ", error)

    }

  }

  return (
    <ThemeProvider theme={GlobalStyleOverrides()} >
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <div className='news--letter'>
        <Typography variant="body2" color="primary.main" textAlign={'center'} sx={{
          pt: {
            xs: 7,
            sm: 5,
            md: 5,
          },

          pb: {
            xs: 4,
            sm: 4,
            md: 4,
          }

          , fontSize: {
            xs: '1.2rem',
            sm: '1.5rem',
            md: '1.7rem',
          }
        }}>
          اشتركي معانا عشان لايفوتك شي
        </Typography>
        <div className="form-container">
          <form onSubmit={handleSubmit} target='#' className='news--section'>
            <span className="form--btn">
              <CustomButton disabled={!emailValid} type={BUTTON_TYPES.REGISTER} submit={true} BtnTxt='اشتراك'> </CustomButton>
            </span>
            <input className="form--email" type="email" placeholder="حطي ايميلك" onChange={handleEmailChange} value={email} />
          </form>
          {!emailValid && <p className="error-text">ادخلي ايميل صحيح</p>}
        </div>

      </div>

      <Dialog open={showPopup} onClose={() => setShowPopup(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CheckCircleIcon style={{ color: 'green', fontSize: '50px', marginRight: '10px', marginTop: '10px' }} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>تم الإشتراك بنجاح</span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* success message description goes here */}
          </DialogContentText>
        </DialogContent>
      </Dialog>

    </ThemeProvider >
  )
}

export default EmailNewsLetter