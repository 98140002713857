import React, { useEffect, useState } from 'react'
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CheckIcon from '@mui/icons-material/Check';
import ChairIcon from '@mui/icons-material/Chair';
import PeopleIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
import PersonIcon from '@mui/icons-material/Person';
import KidsIcon from '@mui/icons-material/Face3';
import { Layout, Menu, theme, Drawer } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import logo from '../../../assets/logo.png';
import { Image } from "antd";
import { Button } from "antd";
import GlobalStyleOverrides from '../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import '../../../styles/userStyles/Admin.css'
import { HashLink as Link } from 'react-router-hash-link';
const { Header, Sider } = Layout;

export default function SideMenu() {
    const [visible, setVisible] = useState(false);
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState("/");
    const [collapsed, setCollapsed] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;

    const logoStyle = {
        width: '100px',
        height: '100px',
    };

    useEffect(() => {
        const pathName = location.pathname;
        setSelectedKeys(pathName);
    }, [location.pathname]);

    const {
        token: { colorBgContainer }
    } = theme.useToken();

    const navigate = useNavigate();

    function handleLogout() {
        // Remove token from local storage
        const token = localStorage.getItem('token');
        const emails = localStorage.getItem('emails') || "";
        if (!token) {
            // Token not found in local storage
            console.error('Token not found in local storage');
            navigate('/');
            return;
        }
        // Send a POST request to the server
        fetch(`${API_URL}/lukatme/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': token
            },
            body: JSON.stringify({})
        })
            .then(response => {
                console.log(response);
                if (response.ok) {
                    // Logout successful
                    console.log('Logout successful');
                    // Remove token from local storage
                    localStorage.removeItem('token');
                    localStorage.clear();
                    localStorage.setItem('emails', emails);
                    // Redirect to login page
                    window.location.href = '/home';
                } else {
                    // Logout failed
                    console.error('Logout failed');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >

            <Button
                className="menu--button"
                type="primary"
                icon={<UserOutlined style={{ color: 'white' }} />}
                onClick={() => setVisible(true)}
            />

            <main className='sidemenu'>

                <Layout >
                    <Drawer
                        width={'250px'}
                        placement="left"
                        onClick={() => setVisible(false)}
                        onClose={() => setVisible(false)}
                        open={visible}
                    >
                        <div className='sidemenu--logo'>
                            <Image
                                src={logo} alt="LukAtMeLogo" style={logoStyle}
                            ></Image>
                        </div>

                        <Sider trigger={null} collapsible collapsed={collapsed}>

                            <Menu
                                className="SideMenuVertical"
                                mode="vertical"
                                onClick={(item) => {
                                    navigate(item.key);
                                }}
                                selectedKeys={[selectedKeys]}
                                items={[
                                    {
                                        label: "جدول التحضير",
                                        icon: <CheckIcon />,
                                        key: "classes",
                                    },
                                    {
                                        label: "الكلاسات",
                                        icon: <ChairIcon />,
                                        key: "trainers",

                                    },
                                    {
                                        label: "المدربات",
                                        icon: <PeopleIcon />,
                                        key: "trainerslist",

                                    },
                                    {
                                        label: "المتدربات",
                                        icon: <PersonIcon />,
                                        key: "trainees",
                                    },
                                    {

                                        label: "Minis",
                                        icon: <KidsIcon />,
                                        key: "kidsinfo",

                                    },
                                    {
                                        label: "إدارة الدفع",
                                        icon: <PaymentIcon />,
                                        key: "orders",
                                    },
                                    {

                                        label: "طلبات الدفع",
                                        icon: <PaymentIcon />,
                                        key: "payment_requests",
                                    },
                                    {

                                        label: "نشرة الأخبار",
                                        icon: <AutoGraphIcon />,
                                        key: "news_letter",
                                    },
                                ]}
                            >

                            </Menu>
                            <Link className='register--link' onClick={handleLogout}>  تسجيل الخروج  </Link>
                        </Sider>
                    </Drawer>
                </Layout>

            </main>
        </ThemeProvider >
    );
}

