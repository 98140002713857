import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export default function GlobalStyleOverrides() {
    const theme = createTheme({

        typography: {
            direction: 'rtl',
            fontFamily: 'IBM Plex Sans Arabic',
            fontStyle: 'normal',
            body1: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '600',
                fontSize: '15px'
            },
            body2: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '700',
                fontSize: '19px'
            },

            body3: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '200',
                fontSize: '15px'
            },

            body4: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '200',
                fontSize: '13px'
            },

            body5: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '200',
                fontSize: '30px',

            },
            body6: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '100',
                fontSize: '40px',

            },
            body7: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '500',
                fontSize: '30px',

            },

            body8: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '500',
                fontSize: '25px',

            },
            body9: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '300',
                fontSize: '25px',

            },
            subtitle1: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '200',
                fontSize: '20px',

            },

            subtitle2: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '400',
                fontSize: '20px',

            },

            body10: {
                fontFamily: 'IBM Plex Sans Arabic',
                fontWeight: '400',
                fontSize: '15px'
            },


        },
        palette: {
            primary: {
                main: '#B10D33',
                other: '#fff',
            },
            secondary: {
                main: '#000000',
                other: '#606060',
            },
            packages: {
                main: '#EA358C',
                other: '#FFFFFF',
            },
            classes: {
                main: '#c42f62',
                other: '#5f112c',
            },
        },

        Slider: {
            color: "white",
        }

    }
    );


    return responsiveFontSizes(theme);

}



