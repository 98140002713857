import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import '../../../../styles/userStyles/Trainee.css'
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { Button } from "react-bootstrap";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
function TraineeLoyalty() {




    const [progress, setProgress] = useState(10);
    let id = 0;
    let clientPoints = 20;
    function createData(prize, requiredPoints) {
        id += 1;
        return { id, requiredPoints, prize };
    }

    const rows = [
        createData('مشروب مجاني ', 20),
        createData('كلاسين تدريب شخصي ', 70),
        createData('كلاس مجاني ', 100),
    ]


    const handleIncrement = () => {
        if (progress < 100) {
            setProgress(progress + 10);
        }
    };

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >

            <div className='loyalty--title'>
                <Typography sx={{ pt: 5 }} variant="body2" color="" textAlign={'right'}>
                    نقاط الولاء
                </Typography>

                <Typography sx={{ pt: 5, pb: 5 }} variant="body4" color="" textAlign={'right'}>
                    حضورك للحصص يكسبك نقاط ولاء يمكنك لاحقاً استبدالها بحصص مجانية
                </Typography>
            </div>

            <div className='client--progress'>
                <div className="loyalty--card">

                    <div className='client--info'>
                        <div className='client-name'>
                            <Typography variant="body2" color="#fff" >
                                Sarah Muhammed
                            </Typography>
                            <Typography variant="body2" color="#fff" >
                                VIP
                            </Typography>
                        </div>
                        <div className='client-points' />
                        <Typography variant="body2" color="#fff" >
                            {progress} points
                        </Typography>
                    </div>

                    <div className="progress-container">
                        <div className="progress-bar" style={{ backgroundColor: '#EA3560', width: `${progress}%` }}>
                        </div>
                    </div>

                </div>
                <div className="progress-circle-wrapper">
                    <div>
                        <CircularProgressbarWithChildren

                            value={progress}
                            strokeWidth={1}
                            styles={buildStyles({
                                textColor: "#EA3560",
                                pathColor: "#EA3560",
                                trailColor: "#d6d6d6",
                                strokeLinecap: "round",
                                pathTransitionDuration: 0.5,

                            })}
                        >

                            <div className="progress-circle-img-wrapper">

                                <img
                                    src={process.env.PUBLIC_URL + '/assets/Gift.png'}

                                />
                                <Typography variant="body5" color="primary.main" textAlign={'right'}>
                                    {progress}%
                                </Typography>
                            </div>


                        </CircularProgressbarWithChildren>
                    </div>
                    <Typography sx={{ pt: 2 }} variant="body5" color="" width={340}>
                        باقي لك 20 نقطة على جائزتك
                    </Typography>
                </div>
            </div>
            <Typography sx={{ pt: 5, pb: 5 }} variant="body2" color="" textAlign={'right'}>
                الجوائز
            </Typography>
            <div >
                <Table className='prize'>
                    <TableBody>
                        {/* Replace below with db data*/}
                        {rows.map(row => (
                            <TableRow key={row.id}>

                                <TableCell align="right">
                                    {
                                        clientPoints === row.requiredPoints ? <Button>  <Typography>احصلي على المكافاة</Typography></Button> : <Typography>باقي لك {row.requiredPoints - clientPoints} نقطة</Typography>
                                    }
                                </TableCell>
                                <TableCell align="right" dir='rtl'><Typography>{row.requiredPoints}نقطة</Typography></TableCell>
                                <TableCell align="right"> <Typography>{row.prize}</Typography></TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </ThemeProvider >
    )
}


export default TraineeLoyalty