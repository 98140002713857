import { useState, useContext, createContext, useEffect } from "react";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    let existingCartItem = localStorage.getItem('cart');
    if (existingCartItem) {
      setCart(JSON.parse(existingCartItem))
    } else {
      setCart([]);
    }


  }, [])

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);
  const clearCart = () => {
    setCart([]);
    localStorage.removeItem('cart');
  };
  return (
    <CartContext.Provider value={[cart, setCart]}>
      {children}
    </CartContext.Provider>
  );
};
// custom hook
const useCart = () => useContext(CartContext);

export { useCart, CartProvider };