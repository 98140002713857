import React from 'react'
import '../styles/Signin.css';
import { useState, useEffect } from 'react';
import Axios from 'axios'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import GlobalStyleOverrides from './GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import logo from '../assets/logo.png'
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../context/auth';

function Signin() {
    const API_URL = process.env.REACT_APP_API_URL;
    const [auth, setAuth] = useAuth()
    const [rememberMe, setRememberMe] = useState(false);
    const [emails, setEmail] = useState(localStorage.getItem("emails") || "");
    const navigate = useNavigate();
    const location = useLocation();
    const message = location.state?.message;


    const validationSchema = Yup.object({
        email: Yup.string().email('إيميل غير صحيح').required('زودينا بإيميلك من فضلك'),
        password: Yup.string()
            .required('اكتبي كلمة سر من فضلك')
    });

    const formik = useFormik({
        initialValues: {
            email: localStorage.getItem("emails") || "",
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (e) => {
            try {
                const formData = {
                    email: formik.values.email,
                    password: formik.values.password,
                };


                const response = await Axios.post(`${API_URL}/lukatme/login`, formData)
                    .then((res) => {
                        setAuth({
                            ...auth,
                            user: res.data.user,
                            token: res.data.token,
                            role: res.data.role
                        })
                        if (rememberMe) {
                            localStorage.setItem("emails", formik.values.email);
                        }

                        localStorage.setItem('auth', JSON.stringify(res.data))
                        console.log(res.data)
                        const token = res.data.token
                        const role = res.data.role

                        localStorage.setItem('token', token);
                        localStorage.setItem('role', role);

                        console.log(role)

                        if (role === 'trainee') {
                            navigate('/home');
                        }
                        else if (role === 'trainer') {
                            navigate('/trainer//*');
                        }
                        else if (role === 'admin') {
                            navigate('/admin//*');
                        }
                    })
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    console.log("الإيميل غير مسجل من قبل");
                    formik.setErrors({
                        email: "الإيميل غير مسجل من قبل",
                    });
                } else if (error.response && error.response.status === 401) {
                    console.error('الإيميل أو كلمة المرور غير صحيحين');
                    formik.setErrors({
                        password: 'الإيميل أو كلمة المرور غير صحيحين',
                    });
                } else {
                    console.error(error);
                }
            }
        }
    })

    useEffect(() => {
        const storedEmail = localStorage.getItem("emails");

        if (storedEmail) {
            setEmail(storedEmail);
            formik.setFieldValue('email', storedEmail);
        }
    }, []);

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <Grid container sx={{ height: '100vh' }}>
                <Grid item xs={false} sm={4} md={7}
                    sx={{
                        backgroundImage: 'url(/assets/120.jpg)',
                        backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover'
                    }}
                >
                </Grid>

                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className='signin--form--div'>
                        <section className='signin--section'>
                            <a className='signin--section' href={'/'}><img src={logo} alt={"Luk at me logo"} /></a>
                            <h2>تسجيل الدخول</h2>
                            <h6>اهلاً فيكِ، حطي بياناتك </h6>
                            {/* <h6>{message}</h6> */}
                            {message && <p style={{ color: 'red' }}>{message}</p>}
                        </section>

                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="البريد الالكتروني"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                sx={{ color: 'primary.main' }}
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="كلمة السر"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.pwd}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />

                            <div className='signin--forgot--pass'>
                                <Link className='signin--link' href="#" to={'/forgetpassword'} variant="body2">
                                    نسيتي كلمة المرور؟
                                </Link>
                                <FormControlLabel
                                    id="remember-me"
                                    name="remember-me"
                                    dir='rtl'
                                    control={<Checkbox disable value="remember" color="primary" onChange={(e) => setRememberMe(e.target.checked)} />}
                                    label="تذكرني"
                                    sx={{ mx: 0 }}
                                />
                            </div>

                            <Button
                                onClick={formik.handleSubmit}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}>
                                تسجيل الدخول
                            </Button>
                            <section className='signin--go--register'>
                                <Link className='signin--link' to={"/register"}> سجلي معانا  </Link>
                                <Typography variant="body3" color="secondary.main" mt={4} mb={4} textAlign='center'>
                                    ماعندك حساب؟
                                </Typography>
                            </section>
                        </Box>

                    </div>
                </Grid>
            </Grid>
        </ThemeProvider >
    );
}

export default Signin
