import React from 'react'
import Card from '@mui/material/Card';
import { Typography } from '@mui/material'
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { Grid, Box } from '@material-ui/core';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import CardContent from '@mui/material/CardContent';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Face3Icon from '@mui/icons-material/Face3';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTheme } from '@mui/material/styles';
import moment from 'moment-timezone';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ToastContainer } from 'react-toastify';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';


const useStyles = makeStyles({
    default: {
        display: 'flex',
        width: '100%',
        borderRight: '5px solid #9E9898',
        textAlign: 'right',
        justifyContent: 'right'
    },
    fullybooked: {
        display: 'flex',
        width: '100%',
        borderRight: '5px solid #ff9800',
    },
    content: {
        flex: '1 0 auto',
    },
    numberOfEnrolled: {
        display: 'flex',
        flexDirection: 'row'
    },
});


function AdminClassCard(props) {
    const theme = useTheme();
    const API_URL = process.env.REACT_APP_API_URL;
    const [showCard, setShowCard] = useState(true);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const [openConfirm, setOpenConfirm] = useState(false);

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleConfirmDelete = () => {
        handleCloseConfirm();
        props.handleDelete(props.class_id);
    };

    const getDate = (class_date) => {
        const dateObj = new Date(class_date);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const simpleDate = `${year}/${month}/${day}`;
        console.log(simpleDate);
        return simpleDate;
    };

    const handleDeleteClick = () => {
        handleOpenConfirm();
    };

    const class_card = useStyles();
    return (
        <ThemeProvider theme={GlobalStyleOverrides()}>
            <ToastContainer />
            {showCard && (
                <Grid container justify="center">
                    <Grid item xs={12} sm={6} md={12}>
                        <Box width={isSmallScreen ? '95%' : 'auto'}>

                            <Card className={class_card.default} sx={{ height: isSmallScreen ? 'auto' : 'fit-content', p: 2, mt: 2, background: '#FAFAFA' }} dir='rtl'>
                                <Box display="flex" flexDirection={isSmallScreen ? 'column-reverse' : 'column'} height="100%">
                                    <div className='card--title' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="body1" color="primary.main" style={{ flexGrow: 1 }}>
                                            {props.class_name}
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                            <EditIcon
                                                fontSize="large"
                                                color="disabled"
                                                opacity="70%"
                                                style={{ marginRight: '10px', cursor: 'pointer' }}
                                                onClick={() => navigate('/admin/update_class', { state: { classId: props.class_id } })}
                                            />
                                            <DeleteIcon
                                                fontSize="large"
                                                color="disabled"
                                                opacity="70%"
                                                style={{ marginRight: '10px', cursor: 'pointer' }}
                                                onClick={() => handleDeleteClick(props.class_id)}
                                            />
                                        </div>
                                    </div>

                                    <CardContent>

                                        <Typography color="secondary.other" variant='body1'>
                                            {
                                                <h><CalendarMonthIcon fontSize="medium" color="primary.main" opacity="30%" /> {getDate(props.date)} </h>
                                            }
                                        </Typography>


                                        <br />

                                        <Typography color="secondary.other" variant='body1' dir="ltr" >
                                            {moment.tz(props.date, "Asia/Riyadh").format('h:mm A')}  <AccessTimeIcon fontSize="medium" color="primary.main" opacity="30%" />
                                        </Typography>

                                        <br />

                                        <Typography color="secondary.other" variant='body1' dir="rtl" >
                                            <TimelapseIcon fontSize="medium" color="primary.main" opacity="30%" />&nbsp;
                                            مدة الكلاس: {props.class_duration} دقيقة
                                        </Typography>



                                        <br />
                                        <Typography color="secondary.other" variant='body1'>
                                            <EventSeatIcon fontSize="small" color="secondary" opacity="30%" /> &nbsp; متبقي {props.no_of_seats - props.number_of_trainees} مقعد من {props.no_of_seats} مقاعد
                                        </Typography>
                                        <br />
                                        <Typography color="secondary.other" variant='body1' dir="rtl" >
                                            <PeopleIcon fontSize="medium" color="primary.main" opacity="30%" />&nbsp;
                                            فئة الكلاس: {props.age_group}
                                        </Typography>
                                        <br />
                                        <Typography color="secondary.other" variant='body1'>
                                            <Face3Icon fontSize="medium" color="secondary" opacity="30%" /> &nbsp; المدربة: {props.trainer}
                                        </Typography>
                                        <br />
                                        <Typography color="secondary.other" variant='body1'>
                                            <LocationOnIcon fontSize="medium" color="primary.main" opacity="30%" /> &nbsp;ستوديو رقم   {props.studio_number}
                                        </Typography>

                                    </CardContent>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            )}
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
            >
                <DialogTitle style={{ textAlign: 'right' }}>تأكيد الحذف</DialogTitle>
                <DialogContent>
                    هل تريد حذف هذا الكلاس؟
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} color="primary">
                        إلغاء
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary">
                        تأكيد
                    </Button>
                </DialogActions>
            </Dialog>

        </ThemeProvider>
    )
}

export default AdminClassCard