
import '../styles/Ribbon.css';
import GlobalStyleOverrides from './GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Ribbon = ({ text }) => {

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div className="ribbon-wrapper">
                <Typography
                    className="ribbon" bgcolor={'primary.main'} color='primary.other' data-text={text}></Typography>
            </div>
        </ThemeProvider>
    );
}

export default Ribbon;
