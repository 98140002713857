import GlobalStyleOverrides from '../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import package_data from '../../data/package_data';
import PackageCard from './PackageCard';
import '../../styles/homeComponentsStyles/PackageList.css';
import { Link } from 'react-router-dom';

const packageArray = package_data;
function PackageList() {

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div className='package--list'>
                <section className='package--container l-12 m-12 s-12'>
                    {
                        packageArray.map(item => {
                            return (
                                <Link to={`/packages#${item.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <PackageCard package_name={item.title} package_details={item.types} />
                                </Link>
                            )
                        })
                    }
                </section>
            </div>
        </ThemeProvider>
    )
}

export default PackageList