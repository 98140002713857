import React from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import GlobalStyleOverrides from './GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

export default function Error() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    backgroundColor: 'white',
                }}
            >

                <Typography variant={isSmallScreen ? 'h4' : 'h1'} style={{ color: '#EA358C' }}>
                    404
                </Typography>
                <Typography variant="h6" style={{ color: '#EA358C' }}>
                    الصفحة التي تبحثين عنها غير موجودة
                </Typography>
                <Link to="/signin"><Button sx={{
                    mt: 3,
                    mb: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: '#EA358C',
                    color: 'white',
                    '& .MuiButton-label': {
                        color: 'white',

                    }
                }}
                    className='link' > الصفحة الرئيسية</Button></Link>

            </Box>
        </ThemeProvider>
    );
}
