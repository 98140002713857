import React, { useState, useEffect, useCallback } from 'react';
import { Button, TextField, ThemeProvider, Modal, Box, Typography } from '@mui/material';
import GlobalStyleOverrides from "./GlobalStyle";
import { useAuth } from '../context/auth';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function ChildrenForm({ isOpen, closeModal }) {
    const [auth, setAuth] = useAuth()
    const API_URL = process.env.REACT_APP_API_URL;
    function calculateAge(birthday) {
        const ageDifMs = Date.now() - birthday.getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    const validationSchema = Yup.object({
        childName: Yup.string()
            .required('زودينا بإسم طفلك الثنائي من فضلك'),
        child_date_of_birth: Yup.date()
            .required('زودينا بتاريخ ميلاد طفلتك')
            .max(new Date(), 'تاريخ ميلاد غير صحيح')
            .test('age', 'الكلاسات مخصصة للأطفال بين 5 و 12', function (value) {
                const age = calculateAge(new Date(value));
                return age >= 5 && age <= 12.5;
            }),
        child_health_issues: Yup.string().required('هل يعاني طفلك من مشاكل صحية؟'),
    });

    const formik = useFormik({
        initialValues: {
            childName: '',
            // childAge: '',
            child_date_of_birth: '',
            child_health_issues: '',
        },
        validationSchema: validationSchema,
        onSubmit: useCallback(
            async (e) => {
                try {
                    console.log('before axios')
                    const newFormData = {
                        childName: e.childName,
                        child_date_of_birth: e.child_date_of_birth,
                        child_health_issues: e.child_health_issues,
                        userId: auth?.user._id
                    };
                    console.log('Register kid success', newFormData);



                    closeModal(newFormData, true);

                    formik.resetForm();

                } catch (error) {

                }


            })

    });


    return (
        <ThemeProvider theme={GlobalStyleOverrides()}>
            <Modal
                open={isOpen}
                onClose={() => closeModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    bgcolor: 'background.paper',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    boxShadow: 24,
                    p: 4
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={'center'}>
                        زودينا بمعلومات طفلتك
                    </Typography>
                    <Box component="form" sx={{ mt: 2 }}>

                        <TextField
                            id="childName"
                            label="اسم طفلتك"
                            variant="outlined"
                            required
                            fullWidth
                            value={formik.values.childName}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.childName && Boolean(formik.errors.childName)}
                            helperText={formik.touched.childName && formik.errors.childName}
                            sx={{ mt: 2, outline: formik.touched.childName && formik.errors.childName ? 'none' : '' }}
                        />


                        <TextField
                            className="Signup--form"
                            margin="normal"
                            required
                            fullWidth
                            id="child_date_of_birth"
                            name="child_date_of_birth"
                            label="عيد ميلاد طفلتك"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={formik.values.child_date_of_birth}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange && ((event) => {
                                formik.setFieldValue("child_date_of_birth", event.target.value);
                            })}
                            error={formik.touched.child_date_of_birth && Boolean(formik.errors.child_date_of_birth)}
                            helperText={formik.touched.child_date_of_birth && formik.errors.child_date_of_birth}
                            inputProps={{
                                max: new Date().toISOString().split('T')[0],
                            }}
                        />

                        <TextField
                            id="child_health_issues"
                            label="عندها مشاكل صحية؟"
                            variant="outlined"
                            fullWidth
                            value={formik.values.child_health_issues}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.touched.child_health_issues && Boolean(formik.errors.child_health_issues)}
                            helperText={formik.touched.child_health_issues && formik.errors.child_health_issues}
                            sx={{ mt: 2, outline: formik.touched.child_health_issues && formik.errors.child_health_issues ? 'none' : '' }}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, width: '100%' }}
                            onClick={formik.handleSubmit}
                        >
                            تسجيل
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={closeModal}
                            sx={{ mt: 3, mb: 2, width: '100%' }}
                        >
                            رجوع
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </ThemeProvider>
    );
}
