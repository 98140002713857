
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import ClassCalender from './ClassCalender';
function TrainerClasses() {

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div className='trainer--classes'>
                <Typography sx={{ pt: 5 }} variant="body2" color="" textAlign={'right'}>
                    جدول الحصص
                </Typography>
            </div>
            <ClassCalender />
        </ThemeProvider>
    )
}

export default TrainerClasses