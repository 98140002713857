import React from 'react';
import ReactDOM from 'react-dom/client';
import { CartProvider } from "./context/cart"
import { AuthProvider } from "./context/auth"
import './index.css';
import App from './App';
import Home from './components/Home';
import AboutUs from './components/AboutUs'
import Packages from './components/Packages'
import Contact from './components/Contact';
import Register from './components/Register';
import reportWebVitals from './reportWebVitals';
import Cart from './components/Cart'
import Signin from './components/Signin';
import UnAuthorized from './components/Unauthorized';
import Admin from './components/userComponents/Admin/Admin'
import Trainer from './components/userComponents/Trainer/Trainer'
import Trainee from './components/userComponents/Trainee/Trainee'
import TermsAndConditions from './components/TermsAndConditions';
import ForgetPassword from './components/ForgetPassword';
import ResetPassword from './components/ResetPassword';
import ProtectedElement from './components/userComponents/ProtectedElement';
import Error from './components/Error'
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },

  {
    path: "home",
    element: <Home />,
  },

  {
    path: "aboutus",
    element: <AboutUs />,
  },

  {
    path: "contactus",
    element: <Contact />,
  },

  {
    path: "packages",
    element: <Packages />,
  },

  {
    path: "register",
    element: <Register />,
  },
  {
    path: "forgetpassword",
    element: <ForgetPassword />,
  },

  {
    path: "resetpassword/:token",
    element: <ResetPassword />,
  },

  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/unauthorized",
    element: <UnAuthorized />,
  },

  {
    path: "cart",
    element: <Cart />,
  },

  {
    path: "terms-and-conditions",
    element: <TermsAndConditions />,
  },

  {
    path: "admin//*",
    element: (
      <ProtectedElement
        element={<Admin />}
        allowedRoles={['admin']}
      />
    ),
  },
  {
    path: "trainer//*",
    element: (
      <ProtectedElement
        element={<Trainer />}
        allowedRoles={['trainer']}
      />
    ),
  },
  {
    path: "/home//*",
    element: <Trainee />,
  },
  {
    path: "/aboutus//*",
    element: <Trainee />,
  },
  {
    path: "/contactus//*",
    element: <Trainee />,
  },
  {
    path: "/cart//*",
    element: <Trainee />,
  },
  {
    path: "/packages//*",
    element: <Trainee />,
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/*",
    element: <Trainee />,
  },

]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <CartProvider>
      <React.StrictMode>
        <RouterProvider router={router}></RouterProvider>
      </React.StrictMode>
    </CartProvider>
  </AuthProvider>
);

reportWebVitals();
