import { React, useContext} from 'react';
import { Navigate } from 'react-router-dom';
import {useAuth} from '../../context/auth'; // Import your AuthContext here
import { isTokenExpired } from '../../context/helper';

const ProtectedElement = ({ element, allowedRoles }) => {
  const [auth] = useAuth()
  const isAuthenticated = auth.user && auth.token  && !isTokenExpired(auth.token);
  const isAuthorized = auth?.role && allowedRoles.includes(auth.role);
  //  && !!localStorage.getItem('auth');

  if(isAuthorized){
  if (isAuthenticated  ) {
    console.log(isAuthenticated);
    console.log("here in protected");
    return element;
  
  }else if (isTokenExpired(auth.token)) {  
    return <Navigate to="/signin" 
        // replace 
        state={{ 
          from: location.pathname, 
          message: ".انتهت جلستك، فضلًا أعيدي الدخول مرة آخرى",
        }} 
      />
  } else {
    console.log(isAuthenticated);
    return <Navigate to="/signin" replace />;
  }
}else{
  return <Navigate to="/unauthorized" replace />;
}
}
export default ProtectedElement;