import jwtDecode from 'jwt-decode';

export function isTokenExpired(token) {
  try {
    const decoded = jwtDecode(token);
    return decoded.exp < Date.now() / 1000;  // check if the token expiry time is before the current time
  } catch (e) {
    return false;
  }
}
