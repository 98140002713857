import React from "react";



const Spinner = () => {
 
  return(
  <div class="d-flex justify-content-center ">
  <div class="spinner-grow text-danger"  style={{width: '3rem', height: '3rem', margin:'22rem'}} role="status">
  <span class="sr-only">Loading...</span>
</div>
</div>
  )
};
export default Spinner;
