
import '../styles/AboutUs.css';
import Navbar from './Navbar';
import Footer from './Footer'
import { Link } from "react-router-dom";
import CustomButton from './CustomButton.js'
import GlobalStyleOverrides from './GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { BUTTON_TYPES } from "../data/Button_const";
import { Grid } from '@material-ui/core';
import { ScrollToTop } from './ScrollToTop';
function AboutUs() {
    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <ScrollToTop />
            <Navbar />
            <div className="about--section">
                <div className="container">
                    <Grid container direction="row-reverse">
                        <Grid item xs={12} sm={6} style={{ order: 0 }}>
                            <div className="inner-column">
                                <a href="#"><img title="Luk at me" src={"/assets/47.jpg"} alt="" style={{ width: '100%' }} /></a>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ order: 1 }}>
                            <div className="inner-column" style={{ marginRight: '20px' }}>
                                <div className="sec--title">
                                    <Typography variant="body8" align="right" color="secondary.other">مين احنا؟</Typography>

                                    <div className="text">
                                        معانا حتعيشي تجربة استثنائية من التواصل بين روحك وجسدك... حتقدري تعبري عن نفسك من خلال جسمك
                                        وأنوثتك... حتعرفي المعنى الحقيقي للياقة الممزوجة بالمتعة الحقيقية عن طريق كل أنواع التمارين والرقص
                                        اللي حتغير حالتك المزاجية. في لوك ات مي حنستقبلك وندلعك بعد يوم تعب طويل!
                                        اللي نضمنلك هو أنك حتخرجي من عندنا والابتسامة مرسومة على وجهك.
                                    </div>
                                </div>
                                <div className="btn-box">
                                    <Link to={"/contactus"}> <CustomButton type={BUTTON_TYPES.PRIMARY} BtnTxt='تواصلي معنا'> </CustomButton></Link>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Footer />
        </ThemeProvider >
    )
}

export default AboutUs
