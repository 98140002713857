
import logo from '../../../assets/logo.png'
import '../../../styles/userStyles/Trainer.css'
import { Image } from "antd";
import { MDBNavbar, MDBNavbarNav } from 'mdb-react-ui-kit';
import SideMenu from './Sidemenu';
export default function Navbar() {

    const logoStyle = {
        width: '80px',
        height: '80px',
    };

    return (
        <div>
            <MDBNavbar className='navbar'>

                <SideMenu />

                <MDBNavbarNav right className='w-auto' >
                    <Image
                        src={logo} alt="LukAtMeLogo" style={logoStyle}
                    ></Image>
                </MDBNavbarNav>
            </MDBNavbar>
        </div >

    );
}