import { Routes, Route } from 'react-router-dom';
import './styles/App.css';
import Signin from './components/Signin';
import Admin from './components/userComponents/Admin/Admin';
import Trainer from './components/userComponents/Trainer/Trainer';
import Trainee from './components/userComponents/Trainee/Trainee';
import ProtectedElement from './components/userComponents/ProtectedElement';
import Home from './components/Home';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path='trainer//*' element={<Trainer />} allowedRoles={'trainer'}> </Route>
        <Route path='/home//*' element={<Trainee />}></Route>
        <Route path='*' element={<Home />}></Route>
        <Route path='admin//*' element={<ProtectedElement element={<Admin />} allowedRoles={'admin'} />}></Route>
      </Routes>
    </div>

  )
}
// ReactDOM.render(<App />, document.getElementById('root'));

export default App;
