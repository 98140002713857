import { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import Ribbon from '../Ribbon';
import { Box } from '@mui/system';

function PackageCard(props) {
    const [currentView, setCurrentView] = useState(0);
    const views = props.package_details;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentView(currentView => (currentView + 1) % views.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [views.length]);


    const handleViewChange = (index) => {
        setCurrentView(index);
    }

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div dir="rtl" style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Box sx={{ flex: '1 1 200px', width: { xs: '300px', sm: '400px', md: '400px', lg: '400px', }, margin: '10px', '@media (max-width:600px)': { flex: '1 1 100%', } }}>
                    <div style={{ position: 'relative' }}>
                        {views[currentView].newPrice != "" && <Ribbon text="بمناسبة الافتتاح" />}
                    </div>

                    <Card sx={{ height: '400px', overflow: 'auto', textAlign: 'center', borderRadius: '16px', p: 2, background: '#fff', boxShadow: '2px 5px 15px -2px rgba(0, 0, 0, 0.16)', border: '0.5px solid #D9D9D9', height: { xs: '350px', sm: '400px', md: '400px', lg: '400px', }, }}>
                        <CardActionArea>
                            <div>
                                <Typography gutterBottom variant="body2" color="primary.main" textAlign={'center'} >
                                    {props.package_name}
                                </Typography>

                            </div>
                            <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                                <div style={{ height: '140px' }}>
                                    <Typography textAlign={'center'}>
                                        {
                                            <Typography variant="body3" color="secondary.other" >
                                                <div className="package-card" >
                                                    {
                                                        views[currentView].class_number == "" ? "" : <div className="class-number">
                                                            <Typography
                                                                dir='rtl'
                                                                variant='body1'
                                                                color='secondary.other'
                                                                style={{ position: 'absolute', top: '0', left: '0', padding: '5px', background: '#c42f62', color: 'white', borderRadius: '9px' }}
                                                            >
                                                                {views[currentView].class_number === '8' ? `${views[currentView].class_number} كلاسات` : `${views[currentView].class_number} كلاس`}                                                        </Typography>
                                                        </div>
                                                    }

                                                    <div className="description">
                                                        <p><span className="description"></span>
                                                            <ul>
                                                                {views[currentView].description.map((item) => (
                                                                    <Typography
                                                                        component="li"
                                                                        align="center"
                                                                        dir='rtl'
                                                                        sx={{ listStyleType: "disc", listStylePosition: "inside" }}
                                                                    >
                                                                        {item}
                                                                    </Typography>
                                                                ))}
                                                            </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Typography>
                                        }
                                    </Typography>
                                </div>

                                <div>
                                    <div className="price">
                                        <p>
                                            <span className={`old-price ${views[currentView].newPrice ? 'line-through' : ''}`}>
                                                <Typography dir='rtl' variant='body2' color='primary.main'>
                                                    {views[currentView].oldPrice}ريال
                                                </Typography>
                                            </span>

                                            {views[currentView].newPrice && (
                                                <span className="new-price">
                                                    <Typography dir='rtl' variant='body7' color='primary.main'>
                                                        {views[currentView].newPrice}
                                                    </Typography>

                                                    <Typography dir='rtl' variant='body1' color='primary.main'>
                                                        ريال
                                                    </Typography>
                                                </span>
                                            )}
                                        </p>
                                    </div>

                                    <style jsx>{`
.old-price {
    color: #000;
    opacity: 80%;
}

.line-through {
    text-decoration: line-through;
}

.new-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}`}</style>

                                    <div className="views">
                                        {views.map((view, index) => (
                                            <button
                                                key={index}
                                                className={currentView === index ? 'active' : ''}
                                                onClick={() => handleViewChange(index)}
                                                style={{
                                                    position: 'relative',
                                                    backgroundColor: currentView === index ? 'pink' : 'white',
                                                    color: currentView === index ? 'white' : 'black',
                                                    border: '1px solid #6F6F6F',
                                                    borderRadius: '10px',
                                                    marginRight: '5px',
                                                    height: '5px'
                                                }}
                                            >
                                                {view.class}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                            </CardContent>

                        </CardActionArea>

                    </Card >
                </Box>
            </div >
        </ThemeProvider >
    )
}

export default PackageCard