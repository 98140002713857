import React from 'react'
import TraineeRoutes from '../UserRoutes/TraineeRoutes'
import '../../../styles/userStyles/Trainer.css'

function Content() {
    return (
        <div className="trainee--content"><TraineeRoutes /></div>
    )
}

export default Content