import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@mui/material'
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { FormControl, FormHelperText } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {

    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/zh-cn';
import Axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";

function NewsLetterEmail() {

    const [showPopup, setShowPopup] = useState(false);
    //------------------------last one------------------------
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("");
    const API_URL = process.env.REACT_APP_API_URL;

    //-----------------------------------------------------

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            const response = Axios.post(`${process.env.REACT_APP_API_URL}/api/sendNewsLetter`, { subject, text })
                .then(res => alert(res.data.message))
                .catch(err => alert('Error sending emails'));

            setShowPopup(true);

            // Hide the popup after 3 seconds
            setTimeout(() => {
                setShowPopup(false);
            }, 3000);
            //console.log("S date  : : : ",classDate)
            //console.log(" image url  : : : ",imageUrl)
        }
        catch (error) {
            console.log("errrorrr from the same file (News..) . . . . .")
            console.error(error);
        }

    };



    return (

        <ThemeProvider theme={GlobalStyleOverrides()} >
            <Typography sx={{ pt: 2 }} variant="body2" textAlign={'right'}>
                إرسال نشرة الأخبار
            </Typography>
            <Box component="form" sx={{ pt: 4, textAlign: 'center' }} >
                <Grid container spacing={2} >
                    <Grid item xs={12} direction="column">
                        <InputLabel id="name">عنوان الايميل</InputLabel>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            label="عنوان الايميل"
                            name="name"
                            autoComplete="name"
                            dir="rtl"
                            onChange={(e) => setSubject(e.target.value)}
                        />

                        <InputLabel id="objectives-select">محتوى الايميل</InputLabel>
                        <FormControl fullWidth margin="normal" dir="rtl">
                            <textarea
                                id="classDes"
                                name="classDes"
                                onChange={(e) => setText(e.target.value)}
                                rows={4}
                                cols={5}
                                style={{
                                    marginBottom: "15px",
                                }}
                            />
                        </FormControl>
                    </Grid>

                </Grid>

                <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2, width: '100%' }}
                    xs={12} md={12}
                    onClick={handleSubmit}

                >
                    ارسال
                </Button>

                <Dialog open={showPopup} onClose={() => setShowPopup(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleIcon style={{ color: 'green', fontSize: '50px', marginRight: '10px', marginTop: '10px' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>تمت الإضافة بنجاح</span>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Box>
        </ThemeProvider >
    )
}

export default NewsLetterEmail
