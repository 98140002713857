import React, { useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../../../../context/auth';
interface ClassData {
    class_name: string;
    start_date: string;
    trainees: Trainee[];
    trainee_name: string;
    trainee_email: string;
    class_duration: number;
    emergency_name: string;
    emergency_phone: string;
    emergency_relation: string;
    is_health_issues: string;
    is_past_injuries: string;
    is_taking_meds: string;
    health_issues: string;
    past_injuries: string;
}
interface Trainee {
    id: number;
    trainee_name: string;
    trainee_email: string;
    start_date: string;
    class_name: string;
    emergency_name: string;
    emergency_phone: string;
    emergency_relation: string;
    is_health_issues: string;
    is_past_injuries: string;
    is_taking_meds: string;
    health_issues: string;
    past_injuries: string;
}

interface Data {
    name: string;
    email: string;
    class_name: string;
    class_date: string;
    class_start_time: string;
    class_duration: number;
    emergency_name: string;
    emergency_phone: string;
    emergency_relation: string;
    is_health_issues: string;
    is_past_injuries: string;
    is_taking_meds: string;
    health_issues: string;
    past_injuries: string;
}

function createData(
    name: string,
    email: string,
    class_name: string,
    class_date: string,
    class_start_time: string,
    class_duration: number,
    is_health_issues: string,
    is_past_injuries: string,
    past_injuries: string,
    is_taking_meds: string,
    health_issues: string,
    emergency_name: string,
    emergency_phone: string,
    emergency_relation: string,
): Data {
    return {
        name,
        email,
        class_name,
        class_date,
        class_start_time,
        class_duration,
        is_health_issues,
        is_past_injuries,
        past_injuries,
        is_taking_meds,
        health_issues,
        emergency_name,
        emergency_phone,
        emergency_relation,
    };
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'emergency_relation',
        numeric: false,
        disablePadding: false,
        label: 'صلة القرابة',
    },
    {
        id: 'emergency_phone',
        numeric: false,
        disablePadding: false,
        label: 'رقم للطوارئ',
    },
    {
        id: 'emergency_name',
        numeric: false,
        disablePadding: false,
        label: 'جهة للتواصل',
    },
    { id: 'health_issues', numeric: false, disablePadding: false, label: 'المشاكل الصحية' },
    { id: 'is_health_issues', numeric: false, disablePadding: false, label: 'هل يوجد مشاكل صحية' },
    { id: 'past_injuries', numeric: false, disablePadding: false, label: 'الإصابات السايقة' },
    { id: 'is_past_injuries', numeric: false, disablePadding: false, label: 'هل توجد اصابات سابقة' },
    { id: 'is_taking_meds', numeric: false, disablePadding: false, label: 'هل تأخذ أدوية' },
    {
        id: 'class_duration',
        numeric: true,
        disablePadding: false,
        label: 'مدة الكلاس',
    },
    {
        id: 'class_start_time',
        numeric: true,
        disablePadding: false,
        label: 'الوقت',
    },
    {
        id: 'class_date',
        numeric: true,
        disablePadding: false,
        label: 'التاريخ',
    },
    {
        id: 'class_name',
        numeric: true,
        disablePadding: false,
        label: 'الكلاس',
    },

    {
        id: 'email',
        numeric: true,
        disablePadding: false,
        label: 'البريد الالكتروني',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'اسم المتدربة',
    },
];

// descending & ascending 

type Order = 'asc' | 'desc';
const DEFAULT_ORDER = 'desc';
const DEFAULT_ORDER_BY = 'class_date';
const DEFAULT_ROWS_PER_PAGE = 5;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, newOrderBy);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'right'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                        align='right'
                    >
                        المتدربات
                    </Typography>
                )}
            </Toolbar>
        </ThemeProvider>
    );
}

export default function Trainees() {
    const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState<keyof Data>(DEFAULT_ORDER_BY);
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [visibleRows, setVisibleRows] = React.useState<Data[] | null>(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
    const [paddingHeight, setPaddingHeight] = React.useState(0);
    // const [trainees, setTrainees] = React.useState<Trainee[]>([]);
    const [trainees, setTrainees] = React.useState<ClassData[]>([]);
    const [auth, setAuth] = useAuth();
    const trainerID = auth.user.userId || auth.user._id;
    const API_URL = process.env.REACT_APP_API_URL;

    React.useEffect(() => {
        const fetchData = async () => {
            // const storedTrainees = localStorage.getItem('storedTrainees');
            try {
                const response = await axios.get(`${API_URL}/lukatme/classes/${trainerID}`);
                setTrainees(response.data);
                // localStorage.setItem('storedTrainees', JSON.stringify(response.data));
                // console.log(response);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    // useEffect(() => {
    //     console.log('trainees', trainees)
    //     localStorage.setItem("storedTrainees", JSON.stringify(trainees)); // <-- store the data in local storage
    // }, [trainees]);

    // //Retrieve the trainers data from local storage
    // const storedTrainees = localStorage.getItem("storedTrainees");
    // const parsedTrainees = storedTrainees ? JSON.parse(storedTrainees) : [];
    // trainees.length === 0 && parsedTrainees.length > 0 && setTrainees(parsedTrainees); // <-- check for stored data and set it in state

    const getClassDataRows = (classData: ClassData): Data[] => {
        if (!classData.trainees){
            return [];
        }

        return classData.trainees.map((trainee) => {
            const { class_name, start_date, class_duration } = classData;
            const { trainee_name, trainee_email, start_date: trainee_start_date, emergency_name, emergency_phone, emergency_relation, is_health_issues, is_past_injuries, is_taking_meds, health_issues, past_injuries} = trainee;

            return createData(
                trainee_name || '',
                trainee_email || '',
                class_name || '',
                moment(start_date).format('D MMMM YYYY'),
                moment.tz(start_date, "Asia/Riyadh").format('h:mm A'),
                class_duration,
                is_health_issues ? 'نعم' : 'لا',
                is_past_injuries ? 'نعم' : 'لا',
                past_injuries || "لا يوجد",
                is_taking_meds ? 'نعم' : 'لا',
                health_issues || "لا يوجد",
                emergency_name || '',
                emergency_phone || '',
                emergency_relation || '',
            );
        });
    };
    const [rows, setRows] = React.useState<Data[]>([]); // initialize rows with empty array

    useEffect(() => {
            const newRows: Data[] = Array.isArray(trainees)
            ? trainees.flatMap(getClassDataRows)  // use flatMap to flatten the nested arrays
            : [];
    
        // console.log(newRows);
        setRows(newRows)
    }, [trainees]); // Run whenever trainees changes



    // const rows: Data[] = Array.isArray(trainees)
    //     ? trainees.flatMap(getClassDataRows)  // use flatMap to flatten the nested arrays
    //     : [];

    // console.log(rows);

    const calculateVisibleRows = () => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return rows.slice(startIndex, endIndex);
    };

    React.useEffect(() => {
        setVisibleRows(calculateVisibleRows());
    }, [rows, page, rowsPerPage]);

    React.useEffect(() => {
        let rowsOnMount = stableSort(
            rows,
            getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),
        );
        rowsOnMount = rowsOnMount.slice(
            0 * DEFAULT_ROWS_PER_PAGE,
            0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
        );

        setVisibleRows(rowsOnMount);
    }, []);

    const handleRequestSort = React.useCallback(
        (event: React.MouseEvent<unknown>, newOrderBy: keyof Data) => {
            const isAsc = orderBy === newOrderBy && order === 'asc';
            const toggledOrder = isAsc ? 'desc' : 'asc';
            setOrder(toggledOrder);
            setOrderBy(newOrderBy);

            const sortedRows = stableSort(rows, getComparator(toggledOrder, newOrderBy));
            const updatedRows = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            );
            setVisibleRows(updatedRows);
        },
        [order, orderBy, page, rowsPerPage],
    );

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = React.useCallback(
        (event: unknown, newPage: number) => {
            setPage(newPage);

            const sortedRows = stableSort(rows, getComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                newPage * rowsPerPage,
                newPage * rowsPerPage + rowsPerPage,
            );
            setVisibleRows(updatedRows);

            // Avoid a layout jump when reaching the last page with empty rows.
            const numEmptyRows =
                newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - rows.length) : 0;

            const newPaddingHeight = (dense ? 33 : 53) * numEmptyRows;
            setPaddingHeight(newPaddingHeight);
        },
        [order, orderBy, dense, rowsPerPage, rows, calculateVisibleRows],
    );

    const handleChangeRowsPerPage = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const updatedRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(updatedRowsPerPage);
            setPage(0);

            const sortedRows = stableSort(rows, getComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                0 * updatedRowsPerPage,
                0 * updatedRowsPerPage + updatedRowsPerPage,
            );
            setVisibleRows(updatedRows);
            // There is no layout jump to handle on the first page.
            setPaddingHeight(0);
        },
        [order, orderBy, rows, calculateVisibleRows],
    );

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows
                                ? visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover

                                            tabIndex={-1}
                                            // key={row.name}
                                            key = {index}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <TableCell padding="checkbox"></TableCell>
                                            <TableCell align="right">{row.emergency_relation}</TableCell>
                                            <TableCell align="right">{row.emergency_phone}</TableCell>
                                            <TableCell align="right">{row.emergency_name}</TableCell>
                                            <TableCell align="right">{row.health_issues}</TableCell>
                                            <TableCell align="right">{row.is_health_issues}</TableCell>
                                            <TableCell align="right">{row.past_injuries}</TableCell>
                                            <TableCell align="right">{row.is_past_injuries}</TableCell>
                                            <TableCell align="right">{row.is_taking_meds}</TableCell>
                                            <TableCell align="right">{row.class_duration}</TableCell>
                                            <TableCell align="right">{row.class_start_time}</TableCell>
                                            <TableCell align="right">{row.class_date}</TableCell>
                                            <TableCell align="right">{row.class_name}</TableCell>
                                            <TableCell align="right">{row.email}</TableCell>
                                            <TableCell
                                                align="right"
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.name}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                                : null}
                            {paddingHeight > 0 && (
                                <TableRow
                                    style={{
                                        height: paddingHeight,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>
    );
}
