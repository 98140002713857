import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography, Container } from '@mui/material'
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { FormControl, FormHelperText } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Select from '@mui/material/Select';
import moment from 'moment-timezone';
import Button from "@mui/material/Button";
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/zh-cn';
import Axios from 'axios';
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';



function UpdateClass() {
    const location = useLocation();
    const [packages, setPackages] = useState([])
    const [classTrainer, setClassTrainer] = useState("")
    const [trainee, setTrainee] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [trainer_Info, setClassTrainerInfo] = useState([])
    const [selectedTrainerEmail, setSelectedTrainerEmail] = useState('');
    const [selectedTrainerId, setSelectedTrainerId] = useState('');
    const API_URL = process.env.REACT_APP_API_URL;
    const [Classes, setClasses] = useState([]);
    const { navigate } = useNavigate();

    const fetchClassData = async () => {
        const data = await Axios.get(`${API_URL}/lukatme/oneclass/${location.state?.classId}`).then((data) => {
            setClasses(data.data)
            console.log(data.data?.package_id);
            setClassTrainer(data.data.trainer_info?.trainer_name);
            formik.setValues({
                ...formik.values,
                studioNumber: data.data.details?.studio_number,
                classSeats: data.data.details?.no_of_seats,
                classDur: data.data.details?.class_duration,
                classType: data.data.class_name,
                packageType: data.data?.package_id || '',
                classDateTime: moment(data.data.date?.start_date).toDate(),
                GroupOfAge: data.data.ageGroup,
            });
            console.log(data.data);
        }).catch((err) => {
            console.log("the error is", err)
        })
    };

    useEffect(() => {
        fetchClassData();
        console.log(Classes);
        console.log(classTrainer);
        if (Classes) {
            console.log('classes are defined')
        }
    }, []);

    const validationSchema = Yup.object({
        studioNumber: Yup.string().required('يرجى تحديد رقم الاستديو'),
        classType: Yup.string().required('يرجى تحديد نوع الكلاس'),
        packageType: Yup.string().required('يرجى تحديد نوع الباكج'),
        classDur: Yup.string().required('يرجى تحديد مدة الكلاس'),
        classDateTime: Yup.date().required('يرجى تحديد تاريخ الكلاس'),
        classSeats: Yup.string().required('يرجى تحديد عدد المقاعد'),
        GroupOfAge: Yup.string().required('يرجى تحديد الفئة العمرية'),
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response;
                response = await Axios.get(`${API_URL}/lukatme/packages/getallpackages`);
                setPackages(response.data);
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    //this function is to check the user input on the text fields
    const handleKeyPress = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const regex = /^[0-9]*$/; // Regular expression to match numbers only
        if (!regex.test(keyValue)) {
            event.preventDefault();
        }
    };

    const handleDateTimeChange = (classDateTime) => {
        if (classDateTime instanceof Date && !isNaN(classDateTime)) {
            formik.setFieldValue("classDateTime", classDateTime);
        } else {
            // console.log('Invalid or null date: ' + classDateTime);
            formik.setFieldValue("classDateTime", null);
        }
    }

    const handleDateTimeBlur = () => {
        formik.setFieldTouched("classDateTime");
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await Axios.get(`${API_URL}/lukatme/trainersList`);
                setClassTrainerInfo(response.data)
            } catch (err) {
                console.log("The error is::", err)
            }
        };
        fetchData();
    }, []);

    const handleSelect = (e) => {
        setClassTrainer(e.target.value);
        formik.setFieldValue('classTrainer', e.target.value);
        const selectedUser = trainer_Info.find(user => user.name === e.target.value);
        if (selectedUser) {
            setSelectedTrainerEmail(selectedUser.email);
            setSelectedTrainerId(selectedUser._id);
        }
    };

    const formik = useFormik({
        initialValues: {
            studioNumber: Classes.details?.studio_number || '',
            classDur: '',
            classType: '',
            packageType: '',
            classDateTime: '',
            classSeats: '',
            GroupOfAge: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (e, { resetForm }) => {
            const classDateTimeRiyadh = moment.tz(formik.values.classDateTime, "Asia/Riyadh").format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            console.log('time is:', classDateTimeRiyadh)
            try {
                console.log('in submit')
                const response = Axios.put(`${API_URL}/lukatme/class/${location.state?.classId}`, {
                    package_id: e.packageType,
                    class_name: e.classType,
                    details: {
                        class_duration: e.classDur, no_of_seats: e.classSeats, studio_number: e.studioNumber
                    },

                    date: { start_date: classDateTimeRiyadh, timezone: "Asia/Riyadh" },
                    ageGroup: e.GroupOfAge,
                    trainer_info: { user_id: selectedTrainerId, trainer_name: classTrainer, trainer_email: selectedTrainerEmail },
                })
                toast.success("تم تعديل هذا الكلاس بنجاح");

                setTimeout(() => {
                    window.history.back();
                }, 2000);

            } catch (error) {
                console.log(error)
                if (error.response) {
                    let message = '';
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    // console.log(error.response.status, error.response)
                    switch (error.response.status) {
                        case 500:
                            message = 'خطأ في تحديث كلمة السر';
                            break;
                        case 201:
                            setShowPopup(true)
                            console.log('updated')
                        default:
                            message = 'خطأ في النظام ';
                    }
                    formik.setErrors({
                        errorLabel: message,
                    });
                }
            }
        },
        handleReset: () => {
        },
    });

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <ToastContainer position="top-center" hideProgressBar={true} autoClose={3000} closeOnClick />
            <Typography sx={{ pt: 2 }} variant="h5" id="tableTitle" textAlign={'right'}>
                تعديل كلاس
            </Typography>

            <Container>
                <Box component="form" sx={{ pt: 4 }}  >
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6} direction="column" >
                            <InputLabel id="objectives-select" > مدربة الكلاس</InputLabel>
                            <FormControl fullWidth margin="normal" >
                                <InputLabel id="objectives-select" > مدربة الكلاس</InputLabel>
                                <Select
                                    id="objectives-select"
                                    name="classTrainer"
                                    onChange={handleSelect}
                                    value={classTrainer}
                                >
                                    <MenuItem value="لايوجد مدربة">لايوجد مدربة</MenuItem>
                                    {trainer_Info.map((item) => (
                                        <MenuItem value={item.name} key={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.classTrainer && formik.errors.classTrainer && (
                                    <FormHelperText error>
                                        {formik.errors.classTrainer}
                                    </FormHelperText>)}
                            </FormControl>

                            <InputLabel id="objectives-select" >  رقم الاستديو</InputLabel>
                            <FormControl fullWidth margin="normal" >
                                <InputLabel id="objectives-select">رقم الاستديو</InputLabel>
                                <Select
                                    id="studioNumber"
                                    name="studioNumber"
                                    value={formik.values.studioNumber}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.studioNumber && Boolean(formik.errors.studioNumber)}
                                    required
                                >
                                    <MenuItem value={1}>  استديو 1  </MenuItem>
                                    <MenuItem value={2}>  استديو 2 </MenuItem>
                                </Select>
                                {formik.touched.studioNumber && formik.errors.studioNumber && (
                                    <FormHelperText error>
                                        {formik.errors.studioNumber}
                                    </FormHelperText>)}
                            </FormControl>



                            <InputLabel id="objectives-select" > مقاعد الكلاس</InputLabel>
                            <FormControl fullWidth margin="normal" >
                                <TextField id="objectives-select" label="مقاعد الكلاس" variant="outlined"
                                    name="classSeats"
                                    value={formik.values.classSeats}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.classSeats && Boolean(formik.errors.classSeats)}
                                    onKeyPress={handleKeyPress}
                                />
                                {formik.touched.classSeats && formik.errors.classSeats && (
                                    <FormHelperText error>
                                        {formik.errors.classSeats}
                                    </FormHelperText>)}
                            </FormControl>


                        </Grid>
                        <Grid item xs={12} md={6} direction="column" >
                            <InputLabel id="class-type" >نوع الكلاس</InputLabel>
                            <FormControl fullWidth margin="normal" >
                                <InputLabel id="class-type">نوع الكلاس</InputLabel>
                                <Select
                                    id="class-type"
                                    name="classType"
                                    value={formik.values.classType}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.classType && Boolean(formik.errors.classType)}
                                >
                                    <MenuItem value={'Self-Training'}>  تدريب شخصي - Self-Training </MenuItem>
                                    <MenuItem value={'Stretching'}>  تمارين الاستطالة - Stretching </MenuItem>
                                    <MenuItem value={'باوند فيت - PoundFit'}>  باوند فيت - PoundFit</MenuItem>
                                    <MenuItem value={'قلوتس - LUKATMY GLUTES!'}>  قلوتس - LUKATMY GLUTES! </MenuItem>
                                    <MenuItem value={'تمارين بطن - Fab Abs'} selected> تمارين بطن - Fab Abs </MenuItem>
                                    <MenuItem value={'كارديو - Burn it up'} selected> كارديو  - Burn it up</MenuItem>
                                    <MenuItem value={'رقص باتشاتا- Bailando Bachata'} selected> رقص باتشاتا- Bailando Bachata</MenuItem>
                                    <MenuItem value={'كامل الجسم - Full Body HIIT'}>  كامل الجسم - Full Body HIIT </MenuItem>
                                    <MenuItem value={'مرونة - Flexy'}>  مرونة - Flexy</MenuItem>
                                    <MenuItem value={' يوجا - Yoga'}>  يوجا - Yoga </MenuItem>
                                    <MenuItem value={'تاباتا - Tabata'} selected> تاباتا - Tabata </MenuItem>
                                    <MenuItem value={'ايريال هوب - Aerial Hoop'}>  ايريال هوب - Aerial Hoop </MenuItem>
                                    <MenuItem value={'الجزء السفلي -  Lower body'}>  الجزء السفلي -  Lower body</MenuItem>
                                    <MenuItem value={'الجزء العلوي -  Upper body'}>  الجزء العلوي -  Upper body </MenuItem>
                                    <MenuItem value={'لوكا فيوجن - Lukas Fusion'} selected> لوكا فيوجن - Luka's Fusion</MenuItem>
                                    <MenuItem value={'رقص الشرقي - BellyDance'} selected> رقص الشرقي - BellyDance</MenuItem>
                                    <MenuItem value={'تويرك فيت - TwerkFit'} selected> تويرك فيت - TwerkFit</MenuItem>
                                    <MenuItem value={'عامود- Pole'} selected> عامود- Pole</MenuItem>
                                    <MenuItem value={'رقص العامود - Pole dance'} selected> رقص العامود - Pole dance</MenuItem>
                                    <MenuItem value={'فلاي فيت - Fly Fit'} selected> فلاي فيت - Fly Fit</MenuItem>
                                    <MenuItem value={'زومبا- Zumba'} selected> زومبا- Zumba</MenuItem>
                                    <MenuItem value={'تصميم الرقص- Dance Choreography'} selected> تصميم الرقص- Dance Choreography</MenuItem>
                                    <MenuItem value={'مات بيلاتس- Mat Pilates'} selected> مات بيلاتس- Mat Pilates</MenuItem>
                                    <MenuItem value={'تمارين تقوية للعامود- Pole conditioning'} selected> تمارين تقوية للعامود- Pole conditioning</MenuItem>
                                    <MenuItem value={'ايريال يوغا- Aerial Yoga'} selected>  ايريال يوغا- Aerial Yoga</MenuItem>
                                    <MenuItem value={'رقص العامود بالكعب- Pole dance heels'} selected> رقص العامود بالكعب- Pole dance heels</MenuItem>
                                    <MenuItem value={'ايريال هاموك- Aerial Hammock'} selected> ايريال هاموك- Aerial Hammock</MenuItem>
                                    <MenuItem value={'ايريال مرونة- Aerial Flexibility'} selected> ايريال مرونة- Aerial Flexibility</MenuItem>
                                    <MenuItem value={'كارديو كيكبوكسنغ-  Cardio Kickboxing'} selected> كارديو كيكبوكسنغ-  Cardio Kickboxing</MenuItem>

                                </Select>
                                {formik.touched.classType && formik.errors.classType && (
                                    <FormHelperText error>
                                        {formik.errors.classType}
                                    </FormHelperText>)}
                            </FormControl>

                            <InputLabel id="package-type" >اسم الباكج </InputLabel>
                            <FormControl fullWidth margin="normal" >
                                <InputLabel id="package-type">اسم الباكج</InputLabel>
                                <Select
                                    id="package-type"
                                    name="packageType"
                                    value={formik.values.packageType}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.packageType && Boolean(formik.errors.packageType)}
                                >
                                    {packages.map((item) => (
                                        <MenuItem key={item._id} value={item._id}>
                                            {item.package_name}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {formik.touched.classType && formik.errors.classType && (
                                    <FormHelperText error>
                                        {formik.errors.classType}
                                    </FormHelperText>)}
                            </FormControl>

                            <InputLabel id="class-duration" > مدة الكلاس</InputLabel>
                            <FormControl fullWidth margin="normal"                              >
                                <InputLabel id="class-duration">مدة الكلاس</InputLabel>
                                <Select
                                    required
                                    id="classDur"
                                    name="classDur"
                                    value={formik.values.classDur || ''}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.classDur && Boolean(formik.errors.classDur) || formik.submitCount > 0 && !formik.values.classDur}
                                    style={{ marginBottom: "15px", borderColor: formik.touched.classDur && formik.errors.classDur ? "red" : undefined }}>
                                    <MenuItem value={20}>  عشرين دقيقة  </MenuItem>
                                    <MenuItem value={30}>  نصف ساعة  </MenuItem>
                                    <MenuItem value={40}>  أربعين دقيقة  </MenuItem>
                                    <MenuItem value={60}>  ساعة</MenuItem>
                                    <MenuItem value={90}>  ساعة ونصف</MenuItem>
                                    <MenuItem value={120} selected> ساعتان</MenuItem>
                                </Select>
                                {(formik.touched.classDur || formik.submitCount > 0) &&
                                    !formik.values.classDur && (
                                        <FormHelperText error>
                                            {formik.errors.classDur
                                                ? formik.errors.classDur
                                                : "يرجى تحديد مدة الكلاس"}
                                        </FormHelperText>)}
                            </FormControl>

                            <InputLabel id="objectives-select" > الفئة العمرية</InputLabel>
                            <FormControl fullWidth margin="normal" >
                                <InputLabel id="objectives-select">الفئة العمرية</InputLabel>
                                <Select
                                    id="GroupOfAge"
                                    name="GroupOfAge"
                                    value={formik.values.GroupOfAge}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    error={formik.touched.GroupOfAge && Boolean(formik.errors.GroupOfAge)}
                                    required
                                >
                                    <MenuItem value={"adults"}> بالغين  </MenuItem>
                                    <MenuItem value={"kids"}>  أطفال </MenuItem>
                                </Select>
                                {formik.touched.GroupOfAge && formik.errors.GroupOfAge && (
                                    <FormHelperText error>
                                        {formik.errors.GroupOfAge}
                                    </FormHelperText>)}
                            </FormControl>

                            <InputLabel id="objectives-select" style={{
                            }}> تاريخ الكلاس</InputLabel>
                            <FormControl fullWidth margin="normal"
                                error={formik.touched.classDateTime && Boolean(formik.errors.classDateTime)}
                            >
                                <div style={{
                                    display: "flex",
                                    borderColor: formik.touched.classDur && formik.errors.classDur ? "red" : undefined,
                                }}>
                                    <DateTimePicker
                                        filterDate={(d) => {
                                            const currentDate = new Date();
                                            currentDate.setHours(0, 0, 0, 0); // Set current date to midnight

                                            return currentDate.getTime() <= d.getTime();
                                        }}
                                        placeholderText="Select Date and Time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        selected={formik.values.classDateTime}
                                        name="classDateTime"
                                        value={formik.values.classDateTime}
                                        onChange={handleDateTimeChange}
                                        onBlur={handleDateTimeBlur}
                                        showTimeSelect
                                        timeIntervals={5}
                                    />
                                </div>
                                {formik.touched.classDateTime && formik.errors.classDateTime && (
                                    <FormHelperText error>
                                        {formik.errors.classDateTime}
                                    </FormHelperText>)}
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, width: '100%' }}
                        onClick={formik.handleSubmit}
                    >
                        تعديل الكلاس
                    </Button>
                </Box>
            </Container>
        </ThemeProvider >
    )
}

export default UpdateClass
