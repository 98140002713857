import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from '@material-ui/core'
import { Typography } from '@mui/material'
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState } from 'react';
import Axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal } from '@mui/material';
import '../../../../styles/userStyles/Admin.css'
function AddTrainer() {

    const [weight, setWeight] = useState();
    const [hight, setHeight] = useState();
    const [isRegistered, setIsRegistered] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('زودينا بالإسم الثنائي من فضلك'),
        email: Yup.string().email('إيميل غير صحيح').required('زودينا بالإيميل من فضلك'),
        pwd: Yup.string()
            .required('اكتبي كلمة سر من فضلك')
            .min(8, ' كلمة السر يجب أن تكون من 8 خانات على الأقل'),
        national_id: Yup.string().required('زودينا برقم الهوية من فضلك')
            .matches(/^[0-9]{10}$/, ' يرجى كتابة رقم الهوية بشكل صحيح '),
        date_of_birth: Yup.date()
            .required('زودينا بتاريخ الميلاد')
            .max(new Date(), 'تاريخ ميلاد غير صحيح')
            .min('1900-01-01', 'تاريخ ميلاد غير صحيح')
        // .format('YYYY-MM-DD')
        ,
        phone_number: Yup.string().required('زودينا برقم جوالك يبدأ ب05')
            .matches(/^05[0-9]{8}$/, 'رقم جوال غير صحيح'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            pwd: '',
            national_id: '',
            date_of_birth: '',
            phone_number: '',
            hight: '',
            wight: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (e, { resetForm }) => {
            try {
                console.log('before axios')
                const formData = {
                    national_id: e.national_id,
                    phone_number: e.phone_number,
                    email: e.email,
                    password: e.pwd,
                    name: e.name,
                    date_of_birth: e.date_of_birth,
                    weight: weight,
                    height: hight,
                };
                const response = await Axios.post(`${API_URL}/lukatme/addtrainer`, formData)
                    .then((response) => {
                        setIsRegistered(true);
                        toast.success("اهلا وسهلا");
                        console.log('Register success')
                    })
            } catch (error) {
                if (error.response) {
                    let message = '';
                    console.log(error.response.status, error.response)
                    switch (error.response.status) {
                        case 404:
                            message = 'رقم الجوال مسجل من قبل';
                            break;
                        case 401:
                            message = 'الإيميل مسجل من قبل';
                            break;
                        case 405:
                            message = 'الإيميل أو رقم الجوال أو رقم الهوية مسحل من قبل، المستخدم مسجل من قبل';
                            break;
                        case 500:
                        case 201:
                            setIsRegistered(true);
                            break;
                        default:
                            message = 'خطأ في النظام ';
                    }
                    formik.setErrors({
                        errorLabel: message,
                    });
                } else {
                    console.error(error);
                }
            }
            resetForm();
        }
    });

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div>
                <Typography sx={{ pt: 2 }} variant="body2" textAlign={'right'}>
                    إضافة مدربة جديدة
                </Typography>

                {formik.errors.errorLabel && <div style={{ color: 'red' }}>{formik.errors.errorLabel}</div>}


                <Box component="form" sx={{ pt: 4 }}   >
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6} direction="column" >
                            <InputLabel id="age">   تاريخ الميلاد</InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="age"
                                label="تاريخ الميلاد "
                                name="age"
                                type='date'
                                autoComplete="age"
                                dir='rtl'
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.date_of_birth}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange && ((event) => {
                                    formik.setFieldValue("date_of_birth", event.target.value);
                                })}
                                error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                                helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                            />


                            <InputLabel id="height">   الطول</InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="height"
                                label=" الطول"
                                name="height"
                                autoComplete="height"
                                dir='rtl'
                                onChange={(e) => setHeight(e.target.value)}
                            />

                            <InputLabel id="phone">   رقم الجوال</InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="phone"
                                label=" رقم الجوال"
                                name="phone_number"
                                autoComplete="phone"
                                dir='rtl'
                                value={formik.values.phone_number}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                helperText={formik.touched.phone_number && formik.errors.phone_number}
                            />

                            <InputLabel id="pwd">كلمة السر </InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="pwd"
                                type='password'
                                label=" كلمة السر"
                                name="pwd"
                                autoComplete="weight"
                                dir='rtl'
                                value={formik.values.pwd}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.pwd && Boolean(formik.errors.pwd)}
                                helperText={formik.touched.pwd && formik.errors.pwd}
                            />


                        </Grid>
                        <Grid item xs={12} md={6} direction="column" >
                            <InputLabel id="name">اسم المدربة </InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="اسم المدربة"
                                name="name"
                                autoComplete="name"
                                dir='rtl'
                                value={formik.values.name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <InputLabel id="email">البريد الالكتروني</InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="البريد الالكتروني"
                                name="email"
                                autoComplete="email"
                                dir='rtl'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <InputLabel id="weight">الوزن </InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="weight"
                                label=" الوزن"
                                name="weight"
                                autoComplete="weight"
                                dir='rtl'
                                onChange={(e) => setWeight(e.target.value)}
                            />

                            <InputLabel id="national_id">رقم الهوية </InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="national_id"
                                label=" رقم الهوية"
                                name="national_id"
                                autoComplete="national_id"
                                dir='rtl'
                                value={formik.values.national_id}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.national_id && Boolean(formik.errors.national_id)}
                                helperText={formik.touched.national_id && formik.errors.national_id}
                            />

                        </Grid>


                    </Grid>

                    <Button
                        type="submit"
                        onClick={formik.handleSubmit}
                        variant="contained"
                        sx={{ mt: 3, mb: 2, width: '100%' }}
                    >
                        إضافة
                    </Button>
                </Box>

            </div>

            <Modal open={isRegistered} onClose={() => setIsRegistered(false)} >
                <div className="modal-container" onClick={() => setIsRegistered(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button onClick={() => setIsRegistered(false)}>&times;</button>
                        <p>تم تسجيل  المدربة بنجاح</p>
                    </div>
                </div>
            </Modal>
        </ThemeProvider >
    )
}

export default AddTrainer
