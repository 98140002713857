import React, { useEffect } from 'react'
import '../styles/Navbar.css'
import '../styles/App.css'
import logo from '../assets/small_logo.png'
import CustomButton from './CustomButton.js'
import { BUTTON_TYPES } from "../data/Button_const";
import { useCart } from '../context/cart';
import { useAuth } from '../context/auth';
import SideMenu from './userComponents/Trainee/Sidemenu'
import { MDBBadge } from 'mdb-react-ui-kit';
import { ShoppingCartOutlined } from '@ant-design/icons';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Typography } from '@mui/material'

export default function Navbar() {
  const [showNavRight, setShowNavRight] = useState(false);
  const logoStyle = {
    width: '40px',
    height: '40px',
  };

  const [cart] = useCart()
  const [auth, setAuth] = useAuth()

  return (
    <div>
      <MDBNavbar expand='lg' light bgColor='light'>
        <MDBContainer fluid>

          {/*user is not signed in*/}
          {!auth?.token &&
            <MDBNavbarItem className='d-lg-none d-flex justify-content-end align-items-center'>
              <MDBNavbarLink href='#'>
                <img src={logo} alt="LukAtMeLogo" style={logoStyle} loading="lazy" />
              </MDBNavbarLink>
            </MDBNavbarItem>
          }

          {/*user is not signed in in desktop view*/}
          {!auth?.token ?
            <div className='app d-none d-lg-flex'>
              <Link to="/register" className='btnn me-2'><CustomButton type={BUTTON_TYPES.REGISTER} BtnTxt='انضمي لينا' className='link btn' /></Link>
              <Link to="/signin" className='btnn'><CustomButton type={BUTTON_TYPES.LOGIN} BtnTxt='تسجيل الدخول' className='link btn' /></Link>
            </div>
            :
            < div className='app'>
              <SideMenu />
            </div>
          }

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MDBNavbarLink>
              <MDBBadge color='danger' light pill className='position-absolute translate-middle m-8 ' style={{ width: 'auto' }}>
                {cart?.length}
              </MDBBadge>
              <Link className='link' to="/cart"><ShoppingCartOutlined style={{ fontSize: '24px' }} /></Link>
            </MDBNavbarLink>

            <MDBNavbarToggler
              data-target='#navbarRightAlignExample'
              aria-controls='navbarRightAlignExample'
              aria-expanded='false'
              aria-label='Toggle navigation'
              onClick={() => setShowNavRight(!showNavRight)}
            >
              <i className="fas fa-bars"></i>
            </MDBNavbarToggler>
          </div>

          <MDBCollapse navbar show={showNavRight}>
            <MDBContainer fluid className=' nav-menu'>
              <MDBNavbarNav right className='d-flex flex-sm-column flex-lg-row justify-content-end align-items-center text-center'>

                {!auth?.token ?
                  <div className='d-lg-none'>
                    <MDBNavbarItem>
                      <MDBNavbarLink className='text-center'>
                        <Link className='link' to="/register"><Typography>انضمي لينا</Typography></Link>
                      </MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                      <MDBNavbarLink className='text-center'>
                        <Link className='link' to="/signin"><Typography>تسجيل الدخول</Typography></Link>
                      </MDBNavbarLink>
                    </MDBNavbarItem>
                  </div>
                  :
                  null
                }

                <MDBNavbarItem>
                  <MDBNavbarLink className='text-center'> <Link className='link' to="/contactus">تواصلي معنا</Link></MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink className='text-center'> <Link className='link' to={"/aboutus"}>تحبي تعرفينا أكتر؟</Link></MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink className='text-center'> <Link className='link' to={"/packages"}> البكجات المتوفرة  </Link></MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBNavbarLink className='navbarLink' active aria-current='page'> <Link className='link' to={"/home"}>الصفحة الرئيسية</Link></MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem className='d-none d-lg-flex align-items-center'>
                  <MDBNavbarLink href='#'>
                    <img src={logo} alt="LukAtMeLogo" style={logoStyle} loading="lazy" />
                  </MDBNavbarLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            </MDBContainer>
          </MDBCollapse>

        </MDBContainer>
      </MDBNavbar>
    </div >);
}
