import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import GlobalStyleOverrides from '../../../GlobalStyle';
import moment from 'moment-timezone';

function createData(id, name, birthDay, healthIssues, mother, phoneNumber,) {
  return {
    id,
    name,
    birthDay,
    healthIssues,
    mother,
    phoneNumber,
  };
}

const headCells = [
  { id: 'phoneNumber', numeric: false, disablePadding: false, label: 'رقم التواصل', },
  { id: 'mother', numeric: false, disablePadding: false, label: 'ولي الأمر', },
  { id: 'healthIssues', numeric: false, disablePadding: false, label: 'المشاكل الصحية', },
  { id: 'birthDay', numeric: false, disablePadding: false, label: 'تاريخ الميلاد', },
  { id: 'name', numeric: false, disablePadding: false, label: 'الإسم', },
];

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <ThemeProvider theme={GlobalStyleOverrides()} >
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
            align='right'
          >
            Minis
          </Typography>
        )}
      </Toolbar>
    </ThemeProvider>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [payment, setPayment] = useState([]);
  const [done, setDone] = useState(false)
  const API_URL = process.env.REACT_APP_API_URL;
  // We'll first add a new state to store the sorted data
  const [sortedPayment, setSortedPayment] = React.useState([]);

  React.useEffect(() => {
    // Implement a sort function
    const comparator = (a, b) => {
      // Check if the properties exist on both objects
      if (a[orderBy] === undefined || b[orderBy] === undefined) return 0;

      if (orderBy === 'amount') {
        // Numeric sorting
        return order === 'asc'
          ? a[orderBy] - b[orderBy]
          : b[orderBy] - a[orderBy];
      } else {
        // String sorting
        return order === 'asc'
          ? a[orderBy].localeCompare(b[orderBy])
          : b[orderBy].localeCompare(a[orderBy]);
      }
    };

    // Sort the payment data and store it in sortedPayment state
    setSortedPayment([...payment].sort(comparator));
  }, [payment, order, orderBy]);  // Add order and orderBy as dependencies


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(`${API_URL}/lukatme/kidsinfo`);
        const data = response.data;
        console.log('res', response);
        const formattedData = data.flatMap((item) => {
          if (Array.isArray(item.child_info)) {
            return item.child_info.map((child) => {
              return createData(
                child._id || '',
                child.child_name || '',
                moment(child.child_date_of_birth).format('D MMMM YYYY') || '',
                child.child_health_issues || '',
                item.user.name || '',
                item.user.phone_number || '',
              );
            });
          }
          return [];
        });

        console.log(formattedData);
        setPayment(formattedData);
      } catch (err) {
        console.log("the error is", err);
      }
    };
    fetchData();
  }, [done]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payment.length) : 0;


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={payment.length}
            />
            <TableBody>
              {sortedPayment
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      // key={row.id}
                      // key={index}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell align="right">{row.phoneNumber}</TableCell>
                      <TableCell align="right">{row.mother}</TableCell>
                      <TableCell align="right">{row.healthIssues}</TableCell>
                      <TableCell align="right">{row.birthDay}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        direction="right"
                      >
                        {row.name}
                      </TableCell>
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={payment.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
