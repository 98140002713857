import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { TextField } from '@material-ui/core'
import { Typography } from '@mui/material'
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal } from '@mui/material';
import '../../../../styles/userStyles/Trainee.css'
import { useNavigate, useParams } from "react-router-dom";

function TraineeSetting() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);
    const navigate = useNavigate()
    const goBack = () => navigate(-1);
    const API_URL = process.env.REACT_APP_API_URL;
    const { userID } = useParams();

    const validationSchema = Yup.object({
        oldPassword: Yup.string()
            .required('اكتبي كلمة سر من فضلك')
            .min(8, ' كلمة السر يجب أن تكون من 8 خانات على الأقل'),
        newPassword: Yup.string()
            .required('اكتبي كلمة سر من فضلك')
            .min(8, ' كلمة السر يجب أن تكون من 8 خانات على الأقل'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'يجب أن تكون كلمة السر متطابقة')
            .required('الرجاء كتابة كلمة السر مرة آخرى'),
    });


    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',

        },
        validationSchema: validationSchema,
        onSubmit: async (e, { resetForm }) => {
            const { oldPassword, newPassword } = e;
            try {
                const response = await Axios.post(`${API_URL}/lukatme/changepassword`,
                    {
                        oldPassword,
                        newPassword,
                        userID
                    },
                )

                console.log('Password updated successfully!')
                formik.resetForm();
                setIsRegistered(true);
            } catch (error) {
                console.log(error)
                if (error.response) {
                    let message = '';
                    console.log(error.response.status, error.response)
                    switch (error.response.status) {
                        case 400:
                            // formik.setErrors({
                            //     oldPassword: "كلمة المرور القديمة غير صحيحة",
                            // });
                            message = 'كلمة المرور القديمة غير صحيحة';
                            break;
                        case 404:
                            message = 'خطأ في النظام، حاولي مرة آخرى لاحقًا';
                            break;
                        case 500:
                            message = 'خطأ في تحديث كلمة السر';
                            break;
                        case 200:
                            setIsRegistered(true);
                            console.log('updated')
                            setOldPassword('');
                            setNewPassword('');
                            break;
                        default:
                            message = 'خطأ في النظام ';
                    }
                    formik.setErrors({
                        errorLabel: message,
                    });
                }
            }
        }
    });


    let token = localStorage.getItem('token')
    const handleSubmit = async (e) => {

        e.preventDefault();

        try {
            const response = await Axios.post(`${API_URL}/lukatme/changepassword`,
                {
                    oldPassword,
                    newPassword,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',

                    },
                })

            console.log('Password updated successfully!')


            setOldPassword('');
            setNewPassword('');
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} float={'right'}>
            {formik.errors.errorLabel && <div style={{ color: 'red' }}>{formik.errors.errorLabel}</div>}

            <div  >
                <Typography sx={{ pt: 2 }} variant="body2" textAlign={'right'}>
                    تغيير كلمة المرور
                </Typography>
                <Box component="form" sx={{ pt: 4 }}   >
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={12} direction="column" >
                            <InputLabel id="oldPassword">   كلمة المرور القديمة</InputLabel>
                            <TextField
                                type='password'
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="oldPassword"
                                label="كلمة المرور القديمة"
                                name="oldPassword"
                                autoComplete="oldPassword"
                                dir='rtl'
                                value={formik.values.oldPassword}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                            />

                            <InputLabel id="newPassword"> كلمة المرور الجديدة</InputLabel>
                            <TextField
                                type='password'
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="newPassword"
                                label="كلمة المرور الجديدة"
                                name="newPassword"
                                autoComplete="newPassword"
                                dir='rtl'
                                value={formik.values.newPassword}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                            />

                            <InputLabel id="confirmPassword"> تأكيد كلمة المرور الجديدة</InputLabel>
                            <TextField
                                type='password'
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="confirmPassword"
                                label="تأكيد كلمة المرور الجديدة"
                                name="confirmPassword"
                                autoComplete="confirmPassword"
                                dir='rtl'
                                value={formik.values.confirmPassword}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />

                        </Grid>


                    </Grid>

                    <Button

                        type="submit"
                        onClick={formik.handleSubmit}
                        variant="contained"
                        sx={{ mt: 3, mb: 2, width: '100%' }}
                    >
                        حفظ
                    </Button>
                    <Button
                        type="submit"
                        onClick={goBack}
                        variant="outlined"
                        sx={{ mt: 3, mb: 2, width: '100%' }}
                        style={{ marginLeft: "5px" }}
                    >
                        رجوع
                    </Button>
                </Box>

            </div>
            <Modal open={isRegistered} onClose={() => setIsRegistered(false)}>
                <div className="modal-container" onClick={() => setIsRegistered(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button onClick={() => setIsRegistered(false)}>&times;</button>
                        <p>تم تغيير كلمة السر بنجاح</p>

                    </div>
                </div>
            </Modal>
        </ThemeProvider >
    )
}

export default TraineeSetting
