import React from 'react'
import Content from './Content';
import '../../../styles/userStyles/Trainee.css'
import Navbar from './TraineeNavbar';
import Footer from '../../Footer.js'
export default function TrainerDashboard() {
    return (

        <div className='Trainee'>
            <Navbar />
            <Content />
            <Footer />
        </div>

    )
}

