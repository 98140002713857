import { useParams } from 'react-router-dom';
import { useState } from 'react';
import Axios from 'axios'
import '../styles/ForgotPassword.css'
import { Link } from "react-router-dom";
import { Button, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from './GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal } from '@mui/material';

const ResetPassword = ({ match }) => {
    const { token } = useParams();
    const [isRegistered, setIsRegistered] = useState(false);

    const validationSchema = Yup.object({
        password: Yup.string()
            .required('اكتبي كلمة سر من فضلك')
            .min(8, ' كلمة السر يجب أن تكون من 8 خانات على الأقل'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'يجب أن تكون كلمة السر متطابقة')
            .required('الرجاء كتابة كلمة السر مرة آخرى'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',

        },
        validationSchema: validationSchema,
        onSubmit: async (e, { resetForm }) => {
            const password = e.password;
            const API_URL = process.env.REACT_APP_API_URL;
            try {
                const response = await Axios.post(`${API_URL}/lukatme/resetPassword`, { token, password });
                console.log('Password updated successfully!')
                formik.resetForm();
                setIsRegistered(true);
            } catch (error) {
                console.log(error)
                if (error.response) {
                    let message = '';
                    console.log(error.response.status, error.response)
                    switch (error.response.status) {
                        case 400:
                            message = 'خطأ في النظام، حاولي مرة آخرى لاحقًا';
                            break;
                        case 500:
                            message = 'خطأ في تحديث كلمة السر';
                            break;
                        default:
                            message = 'خطأ في النظام ';
                    }
                    formik.setErrors({
                        errorLabel: message,
                    });
                }
            }
        }
    });


    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <Grid container sx={{ height: '100vh' }}>
                <Grid item xs={false} sm={4} md={7}
                    sx={{
                        backgroundImage: 'url(/assets/120.jpg)',
                        backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover'
                    }}
                >
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className='resetpass--form--div'>
                        <h6>استعادة كلمة المرور</h6>
                        {formik.errors.errorLabel && <div style={{ color: 'red' }}>{formik.errors.errorLabel}</div>}
                        <Box component="form" method="POST" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                type="password"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                label="كلمة السر"
                                name="password"
                                autoComplete="password"
                                autoFocus
                                sx={{ color: 'primary.main' }}
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.password}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />

                            <TextField
                                type="password"
                                margin="normal"
                                required
                                fullWidth
                                id="confirmPassword"
                                label="تأكيد كلمة السر"
                                name="confirmPassword"
                                autoComplete="confirmPassword"
                                autoFocus
                                sx={{ color: 'primary.main' }}
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.confirmPassword}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />
                            <Button
                                fullWidth
                                type='submit'
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }} className="btn btn-primary w-100 theme-btn mx-auto">
                                تغيير كلمة المرور
                            </Button>
                        </Box>
                    </div>

                    <section className='resetpass--go--signin'>
                        <Link className="resetpass--link" to="/signin" >   <Typography variant="body3" color="primary.main" mt={4} mb={4} textAlign='center'> العودة لتسجيل الدخول   </Typography> </Link>
                    </section>
                </Grid>
            </Grid>
            <Modal open={isRegistered} onClose={() => setIsRegistered(false)}>
                <div className="modal-container" onClick={() => setIsRegistered(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button onClick={() => setIsRegistered(false)}>&times;</button>
                        <p>تم تغيير كلمة السر بنجاح</p>
                    </div>
                </div>
            </Modal>
        </ThemeProvider >



    );
};
export default ResetPassword;
