import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import '../styles/Packages.css';
import GlobalStyleOverrides from './GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import Navbar from './Navbar';
import Footer from './Footer'
import { useCart } from '../context/cart';
import { ToastContainer, toast } from 'react-toastify';
import Axios from 'axios';
import { useState, useEffect } from 'react'
import Spinner from './Spinner'
import { useAuth } from '../context/auth';
import ChildrenForm from "./ChildrenForm";



function Packages(props) {
  const [auth, setAuth] = useAuth()
  const [cart, setCart] = useCart()
  console.log("caaart", useCart())

  const API_URL = process.env.REACT_APP_API_URL;



  const [packages, setPackages] = useState([])
  const [loading, setLoading] = useState(false)
  const [age, setAge] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = (item, formData, onFormSubmit, i) => {
    setModalIsOpen(false);
    console.log('formCompleted', onFormSubmit);

    if (onFormSubmit) {

      const itemWithFormData = {
        ...item,
        formData: formData,
        type_id: i.type_id,
      };
      setCart([...cart, itemWithFormData]); // Add the item with formData to the cart
      localStorage.setItem('cart', JSON.stringify([...cart, itemWithFormData]));
      toast.success('تم الاضافة بنجاح');
    }

  };

  function calculateAge(dayOfBirth) {
    var diff_ms = Date.now() - new Date(dayOfBirth).getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);

  }



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let response;
        response = await Axios.get(`${API_URL}/lukatme/packages/getallpackages`);
        setPackages(response.data);
        setLoading(false);
        //  }


      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };

    fetchData();
  }, [auth]);


  console.log("ppp", packages)

  if (loading) {
    return <div> <Spinner /> </div>
  }


  return (
    <div>
      <ToastContainer position="top-center" hideProgressBar={true} autoClose={3000} />
      <Navbar />
      <ThemeProvider theme={GlobalStyleOverrides()}  >
        <React.Fragment>

          <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,

              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[200]
                  : theme.palette.grey[200],
            }}
          >
          </AppBar>

          {/* Hero unit */}
          <div className="title">
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }} >
              <Typography
                component="h1"
                variant="body8"
                align="center"
                color="secondary.other"
                gutterBottom
              >
                اختاري خطة الأسعار التي تناسبك
              </Typography>
            </Container>
          </div>
          {/* End hero unit */}


          <Container maxWidth="xl">
            <Grid id='grid-1' container spacing={5}
              minWidth={300}
              justifyContent='center'
            >

              {/*                 
              {tiers.map((tier) => ( */}
              {packages && packages?.map((item, index) => (
                // Enterprise card is full width at sm breakpoint
                <Grid id='grid-2'
                  item
                  key={item.index}
                  xs={12}
                  sm={item.package_name === 'Enterprise' ? 12 : 6}
                  md={5}
                >
                  <Card className='card-container'
                    sx={{
                      backgroundColor: 'packages.other',
                      display: ' flex',
                      boxShadow: 3,
                      mb: 4
                    }}
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  >
                    <CardHeader
                      title={item.package_name}
                      subheader={item.pkg_description}
                      titleTypographyProps={{ align: 'center', color: 'primary.main', variant: 'body2', mt: 2 }}
                      action={item.package_name === 'Pro' ? <StarIcon /> : null}
                      subheaderTypographyProps={{
                        align: 'center',
                        mt: 2
                      }}

                    />
                    {item?.types?.map((i, index) =>
                      <CardContent>


                        <Box
                          sx={{
                            display: 'block',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                          }}
                        >

                          <div dir='rtl' key={index} style={{ display: "inline-block" }}>
                            {i.class_number > 0 ?
                              <div className=" flex-row-reverse" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>

                                {i.class_number > 10 ? <Typography variant="body2" color="secondary.other">
                                  &nbsp; كلاس
                                </Typography> : <Typography variant="body2" color="secondary.other">
                                  &nbsp;  كلاسات
                                </Typography>
                                }

                                <Typography component="h2" variant="body8" color="text.primary" >
                                  {i.class_number}
                                </Typography>

                              </div>

                              : <Typography variant="h6" color="secondary.other">

                              </Typography>
                            }

                            <ul>

                              {i.description.map((line) => (
                                <Typography
                                  component="li"
                                  variant="body1"
                                  align="center"
                                  key={line}
                                >
                                  {line}
                                </Typography>
                              ))}
                            </ul>

                            <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '10px' }}>
                              {
                                i.newPrice ?
                                  (
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                      <Typography variant="body2" color="secondary.other" style={{ textDecoration: "line-through" }}>
                                        SAR{i.oldPrice}

                                      </Typography>
                                      <Typography variant="body8" color="primary.main" >
                                        SAR{i.newPrice}
                                      </Typography>

                                    </div>
                                  ) :
                                  (
                                    <Typography variant="body2" color="secondary.other">
                                      SAR{i.oldPrice}
                                    </Typography>
                                  )
                              }

                              <Typography variant="h6" color="text.secondary">
                                /شهريًا
                              </Typography>
                            </div>
                            <ul>


                              <Typography
                                component="li"
                                variant="body1"
                                align="center"
                                color="text.secondary"
                              >
                                شامل الضريبة المضافة
                              </Typography>
                            </ul>
                          </div>
                          <CardActions id='button' style={{ marginTop: 'auto', id: 'button' }}>



                            {cart && cart?.some((c) => (c._id === item._id && c.type_id === i.type_id)) ? (
                              <>
                                {item.ageGroup === "kids" ? (

                                  <Button fullWidth variant='outlined'
                                    onClick={openModal}
                                  >
                                    إضافة طفلة
                                  </Button>

                                ) : (
                                  <Button fullWidth variant='outlined'

                                    // disabled={!cart.some((c) => c._id == item._id)}
                                    onClick={() => {

                                      setCart(cart.filter((c) => !(c._id === item._id && c.type_id === i.type_id)));

                                      localStorage.setItem('cart', JSON.stringify([...cart, item]))
                                      const selectedNumberOfClassesJSON = localStorage.getItem('selectedNumberOfClasses');
                                      let selectedNumberOfClasses = [];

                                      if (selectedNumberOfClassesJSON) {
                                        selectedNumberOfClasses = JSON.parse(selectedNumberOfClassesJSON);
                                        // Remove the corresponding item from the selectedNumberOfClasses array
                                        selectedNumberOfClasses.splice(item[index], 1);
                                        // Store the updated selectedNumberOfClasses array in localStorage
                                        localStorage.setItem('selectedNumberOfClasses', JSON.stringify(selectedNumberOfClasses));

                                      }
                                      toast.success('تم الحذف بنجاح');
                                    }}

                                  >
                                    حذف من السلة
                                  </Button>
                                )


                                }
                              </>
                            ) : (


                              <Button fullWidth variant='outlined'
                                disabled={(cart || []).some((c) => c._id == item._id && c.type_id != i._id)}
                                onClick={() => {
                                  if (item.ageGroup == "adults") {
                                    const itemWithAttributes = {
                                      ...item, // This creates a copy of item
                                      type_id: i.type_id,

                                    };

                                    setCart([...cart, itemWithAttributes]);
                                    localStorage.setItem('cart', JSON.stringify([...cart, itemWithAttributes]))
                                    toast.success('تمت الاضافة إلى السلة بنجاح');
                                  } else {
                                    // <button onClick={openModal}>Open Modal</button>
                                    openModal()

                                  }
                                }}

                              >
                                إشتراك
                              </Button>


                            )}



                            <ChildrenForm
                              isOpen={modalIsOpen}
                              closeModal={(formData, onFormSubmit) => closeModal(item, formData, onFormSubmit, i)}// Pass the item to closeModal
                            />
                          </CardActions>

                        </Box>
                      </CardContent>
                    )}

                  </Card>

                </Grid>
              ))}

            </Grid>
          </Container>
        </React.Fragment>
      </ThemeProvider>
      <Footer />
    </div >
  );
}

export default Packages;
