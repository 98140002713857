
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { CardActions } from '@mui/material';
import { Box } from '@mui/system';
import '../../styles/homeComponentsStyles/ClassSummary.css';

function ClassSummary(props) {
    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <Box sx={{
                width: {
                    xs: '300px',
                    sm: '400px',
                    md: '400px',
                    lg: '400px',
                },
                height: {
                    xs: '550px',
                    sm: '500px',
                    md: '600px',
                    lg: '600px',
                },
                background: 'transparent',
                border: 'none',
                boxShadow: 'none'
            }}>
                <Card textAlign={'right'} sx={{ background: 'transparent', border: 'none', boxShadow: 'none' }}>
                    <CardMedia>
                        <div>
                            <img src={
                                props.class_image
                            } className='class--img' />
                        </div>
                    </CardMedia>

                    <CardContent>
                        <Typography color="classes.other" variant="body2" >
                            {
                                <h dir="rtl">{props.class_name}</h>
                            }
                        </Typography>

                        <Typography variant="body10" className='class-description'>
                            {
                                <p dir="rtl"  >{props.class_description}</p>
                            }
                        </Typography>
                    </CardContent>

                    <CardActions>

                    </CardActions>
                </Card >
            </Box>

        </ThemeProvider >
    )
}

export default ClassSummary