
import { Route, Routes } from "react-router-dom";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import TraineeDashboard from '../Trainee/PageContent/TraineeDashboard.js';
import TraineeLoyalty from '../Trainee/PageContent/TraineeLoyalty.js'
import TraineeSubscription from '../Trainee/PageContent/TraineeSubscription.js'
import TraineeClasses from '../Trainee/PageContent/TraineeClasses.js'
import TraineeSetting from '../Trainee/PageContent/TraineeSetting.js'
import PaymentHistory from '../Trainee/PageContent/PaymentHistory.js'
import TermsAndConditions from '../.././TermsAndConditions.js'
import ChangePassword from '../Trainee/PageContent/ChangePassword.js'
import ProtectedElement from '../ProtectedElement.js';

export default function TraineeRoutes() {
    return (
        <Routes>
            <Route path="dashboard" element={<ProtectedElement element={<TraineeDashboard />} allowedRoles={'trainee'} />} />
            <Route path="loyalty" element={<ProtectedElement element={<TraineeLoyalty />} allowedRoles={'trainee'} />} />
            <Route path="subscription" element={<ProtectedElement element={<TraineeSubscription />} allowedRoles={'trainee'} />} />
            <Route path="classes" element={<ProtectedElement element={<TraineeClasses />} allowedRoles={'trainee'} />} />
            <Route path="settings" element={<ProtectedElement element={<TraineeSetting />} allowedRoles={'trainee'} />} />
            <Route path="settings/changepassword/:userID" element={<ProtectedElement element={<ChangePassword />} allowedRoles={'trainee'} />} />
            <Route path="paymentHistory" element={<ProtectedElement element={<PaymentHistory />} allowedRoles={'trainee'} />} />
            <Route path="TermsAndConditions" element={<ProtectedElement element={<TermsAndConditions />} allowedRoles={'trainee'} />} />
        </Routes>
    )
}
