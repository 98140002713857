
import { useNavigate } from "react-router-dom";
import GlobalStyleOverrides from './GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import '../styles/Unauthorized.css';

const Unauthorized = () => {
    const navigate = useNavigate()
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const goBack = () => navigate(-1);
    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    backgroundColor: 'white',
                }}
            >

                <Typography variant={isSmallScreen ? 'body7' : 'body7'} color='primary.main' >
                    غير مسموح
                </Typography>
                <Typography variant={isSmallScreen ? 'body3' : 'body9'} color='primary.main'>
                    ماعندك صلاحية الوصول للصفحة المطلوبة
                </Typography>
                <Button sx={{
                    mt: 3,
                    mb: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '& .MuiButton-label': {
                        color: 'white',

                    }
                }}
                    onClick={goBack}
                    className='link' > الصفحة السابقة</Button>

            </Box>
        </ThemeProvider>
    );
};
export default Unauthorized;
