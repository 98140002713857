import React from 'react'
import Card from '@mui/material/Card';
import { Typography } from '@mui/material'
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import { Grid, Box } from '@material-ui/core';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import CardContent from '@mui/material/CardContent';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import PeopleIcon from '@mui/icons-material/People';
import { useTheme } from '@mui/material/styles';
import moment from 'moment-timezone';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles({
    default: {
        display: 'flex',
        width: '100%',
        borderRight: '5px solid #9E9898',
        textAlign: 'right',
        justifyContent: 'right'
    },
    fullybooked: {
        display: 'flex',
        width: '100%',
        borderRight: '5px solid #ff9800',
    },
    content: {
        flex: '1 0 auto',
    },
    numberOfEnrolled: {
        display: 'flex',
        flexDirection: 'row'
    },
});


function TrainerClassCard(props) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const getDate = (class_date) => {
        const dateObj = new Date(class_date);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const simpleDate = `${year}/${month}/${day}`;
        console.log(simpleDate);
        return simpleDate;
    };
    const class_card = useStyles();
    return (
        <ThemeProvider theme={GlobalStyleOverrides()}>
            <Grid container justify="center">
                <Grid item xs={12} sm={6} md={12}>
                    <Box width={isSmallScreen ? '95%' : 'auto'}>
                        <Card className={class_card.default} sx={{ height: isSmallScreen ? 'auto' : 'fit-content', p: 2, mt: 2, background: '#FAFAFA' }} dir='rtl'>
                            <Box display="flex" flexDirection={isSmallScreen ? 'column-reverse' : 'column'} height="100%">
                                <div className='card--title'>
                                    <Typography variant="body1" color="primary.main">
                                        {
                                            props.class_name
                                        }
                                    </Typography>
                                </div>

                                <CardContent>

                                    <Typography color="secondary.other" variant='body1'>
                                        {
                                            <h><CalendarMonthIcon color="primary.main" opacity="30%" /> {getDate(props.date)} </h>
                                        }
                                    </Typography>
                                    <br />


                                    <Typography color="secondary.other" variant='body1' dir="ltr" >
                                        {moment.tz(props.date, "Asia/Riyadh").format('h:mm A')}  <AccessTimeIcon fontSize="small" color="primary.main" opacity="30%" />
                                    </Typography>
                                    <br />

                                    <Typography color="secondary.other" variant='body1' dir="rtl" >
                                        <TimelapseIcon fontSize="medium" color="primary.main" opacity="30%" />&nbsp;
                                        مدة الكلاس: {props.class_duration} دقيقة
                                    </Typography>

                                    <br />

                                    <Typography color="secondary.other" variant='body1'>
                                        <EventSeatIcon fontSize="small" color="secondary" opacity="30%" /> &nbsp; متبقي {props.no_of_seats - props.number_of_trainees} مقعد من {props.no_of_seats} مقاعد
                                    </Typography>

                                    <br />
                                    <Typography color="secondary.other" variant='body1' dir="rtl" >
                                        <PeopleIcon fontSize="medium" color="primary.main" opacity="30%" />&nbsp;
                                        فئة الكلاس: {props.age_group}
                                    </Typography>
                                    <br />

                                    <Typography color="secondary.other" variant='body1'>
                                        <LocationOnIcon fontSize="small" color="primary.main" opacity="30%" /> &nbsp;ستوديو رقم   {props.studio_number}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

export default TrainerClassCard