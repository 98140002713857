
import "../styles/Button.css";
import "../data/Button_const";
import { BUTTON_TYPES } from "../data/Button_const";

const Button = (props) => {
  const { type, BtnTxt, submit } = props;
  const getButtonClass = () => {
    switch (type) {
      case BUTTON_TYPES.PRIMARY:
        return "primaryBtn button";
      case BUTTON_TYPES.SECONDARY:
        return "secondaryBtn button";
      case BUTTON_TYPES.REGISTER:
        return "register  primaryBtn";
      case BUTTON_TYPES.LOGIN:
        return "login  primaryBtn";
      default:
        return "tertiaryBtn button";
    }
  };
  return <button
    className={getButtonClass()}
    type={submit ? "submit" : "button"}
  >
    {BtnTxt}
  </button>
};
export default Button;
