
import { Route, Routes } from "react-router-dom";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import AdminAttendance from '../Admin/PageContent/AdminAttendance';
import AdminTrainees from '../Admin/PageContent/AdminTrainees.tsx';
import AdminClassess from '../Admin/PageContent/AdminClassess.js';
import AdminTrainersList from '../Admin/PageContent/AdminTrainersList.tsx';
import ManageOrders from '../Admin/PageContent/ManageOrders.tsx';
import AddClass from '../Admin/PageContent/AddClass';
import UpdateClass from '../Admin/PageContent/UpdateClass';
import NewsLetterEmail from '../Admin/PageContent/NewsLetterEmail';
import AddTrainer from '../Admin/PageContent/AddTrainer';
import PaymentRequests from '../Admin/PageContent/PaymentRequests';
import SubscribedKids from '../Admin/PageContent/SubscribedKids';
import ProtectedElement from '../ProtectedElement';
export default function AdminRoutes() {
    return (
        <Routes>
            <Route exact path="classes" element={<ProtectedElement element={<AdminAttendance />} allowedRoles={'admin'} />} />
            <Route exact path="trainees" element={<ProtectedElement element={<AdminTrainees />} allowedRoles={'admin'} />} />
            <Route exact path="trainers" element={<ProtectedElement element={<AdminClassess />} allowedRoles={'admin'} />} />
            <Route exact path="trainerslist" element={<ProtectedElement element={<AdminTrainersList />} allowedRoles={'admin'} />} />
            <Route exact path="orders" element={<ProtectedElement element={<ManageOrders />} allowedRoles={'admin'} />} />
            <Route exact path="add_trainer" element={<ProtectedElement element={<AddTrainer />} allowedRoles={'admin'} />} />
            <Route exact path="add_class" element={<ProtectedElement element={<AddClass />} allowedRoles={'admin'} />} />
            <Route exact path="update_class" element={<ProtectedElement element={<UpdateClass />} allowedRoles={'admin'} />} />
            <Route exact path="payment_requests" element={<ProtectedElement element={<PaymentRequests />} allowedRoles={'admin'} />} />
            <Route exact path="news_letter" element={<ProtectedElement element={<NewsLetterEmail />} allowedRoles={'admin'} />} />
            <Route exact path="kidsinfo" element={<ProtectedElement element={<SubscribedKids />} allowedRoles={'admin'} />} />
        </Routes>
    )
}
