const package_data =
    [
        {
            id: "64575a907216e740533ef34e",
            title: "اشتراك شامل ",
            types: [
                {
                    class_number: "8", oldPrice: "920", newPrice: "", description: [
                        'صالحة لمدة شهر',
                    ],
                },
                {
                    class_number: "12", oldPrice: "1352", newPrice: "", description: [
                        'صالحة لمدة شهر ونص',
                        'زيارة مجانية لصاحبتك',
                    ],
                },
                {
                    class_number: "20", oldPrice: "2070", newPrice: "", description: [
                        'صالحة لمدة شهرين',
                        'كلاس مجاناً',
                        'مشروبين قهوة مجاناً',
                        'زيارة مجانية لصاحبتك',
                    ],
                },
            ],
            buttonText: 'إشتراك',
            buttonVariant: 'outlined',
        },

        {
            id: "645787067216e740533ef352",
            title: "Self-Training ",
            types: [
                {
                    class_number: "", oldPrice: "690", newPrice: "", description: [
                        'حابة تطوري من نفسك وتزيدي لياقتك؟',
                        ' محتاجة مكان تتمرني فيه لحالك بدون مدربة؟',
                        '20 زيارة بالشهر',
                    ],
                },
            ],
            buttonText: 'إشتراك',
            buttonVariant: 'outlined',
        },

        {
            id: "6457841a7216e740533ef351",
            title: "تدريب شخصي",
            types: [
                {
                    class_number: "8", oldPrice: "1656", newPrice: "", description: [
                        'تصميم برنامج رياضي يساعدك توصلي لهدفك بإشراف المدربة',
                    ],
                },
                {
                    class_number: "12", oldPrice: "2070", newPrice: "", description: [
                        'تصميم برنامج رياضي يساعدك توصلي لهدفك بإشراف المدربة',
                    ],
                },
            ],
            buttonText: 'إشتراك',
            buttonVariant: 'outlined',
        },

        {
            id: "6459f2b8b9889f3462c42270",
            title: "LUKATME Minis ",
            types: [
                {
                    class_number: "", oldPrice: "575", newPrice: "", description: [
                        'باكج الأطفال من 5 إلى 12 سنة',
                        'كلاسات زومبا وباوند فيت',
                    ],
                },

            ],
            buttonText: 'إشتراك',
            buttonVariant: 'outlined',
        },


    ]

export default package_data
