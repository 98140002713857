import React, { useEffect, useState } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import Axios from 'axios';
import { useAuth } from '../../../../context/auth'
import { TableContainer } from '@mui/material';
import moment from 'moment-timezone';

function PaymentHistory() {

    const [paymentHistory, setPaymentHistory] = useState([])
    const [auth, setAuth] = useAuth()
    const userId = auth?.user._id
    let token = localStorage.getItem('token')
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const paymentHistory = async () => {

            console.log("auth", userId)
            try {
                const response = await Axios.get(`${API_URL}/lukatme/payment/history?id=${userId}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Assuming token is stored in localStorage
                            'Content-Type': 'application/json',
                            Accept: 'application/json',

                        },
                    });
                console.log("data", response.data);
                setPaymentHistory(response.data)
            } catch (err) {
                console.error('Error:', err);

            };
        }
        paymentHistory()
    }, [auth])

    console.log(paymentHistory);

    let id = 0;
    function createData(total, paymentMethod, description, paymentDate) {
        id += 1;
        return { id, total, paymentMethod, description, paymentDate };
    }


    /* Hardcoded just to test*/
    const rows = [
        createData('500 SAR', 'Credit Card', 'بكج البريميوم', '10 فبراير, 2023'),
        createData('1500 SAR', 'Credit Card', 'بكج البريميوم', '20 فبراير, 2023')
    ];

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >

            {/* Page title*/}

            <div className='payment--history'>
                <Typography sx={{ pt: 5 }} variant="body2" color="" textAlign={'right'}>
                    سجل الدفع
                </Typography>

                <Typography sx={{ pt: 5, pb: 5 }} variant="body4" color="" textAlign={'right'}>
                    تقدري تشوفي فواتيرك السابقة لإشتراكات العضوية المميزة والحصص المنفردة
                </Typography>
            </div>

            <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
                <Table aria-label="responsive table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">حالة الفاتورة</TableCell>
                            <TableCell align="right">اجمالي المبلغ</TableCell>
                            <TableCell align="right">طريقة الدفع</TableCell>
                            <TableCell align="right">اسم الباكج</TableCell>
                            <TableCell align="right">تاريخ الدفع</TableCell>
                            <TableCell align="right">تاريخ الطلب</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paymentHistory.map(payment => (
                            <TableRow key={payment._id}>
                                <TableCell align="right">{payment.status}</TableCell>
                                <TableCell align="right">{payment.amount} {payment.currency}</TableCell>
                                <TableCell align="right">{payment.method_of_payment}</TableCell>
                                {payment.subscriptions.map(sub =>
                                    <TableCell className='d-flex' align="right">{sub.package_id.package_name}</TableCell>
                                )}
                                <TableCell align="right">{moment(payment.paymentDate).format('YYYY-MM-DD')}</TableCell>
                                <TableCell align="right">{moment(payment.date).format('YYYY-MM-DD')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </ThemeProvider >
    )
}

export default PaymentHistory