const class_data = [
    [
        {
            id: "1",
            class_name: " رقص العامود",
            class_image: "/assets/class_7.png",
            alt_image: "/assets/class6.jpg",
            details: {
                class_description: "رقص العامود هو فن يمزج بين الرقص والجمباز, ويعتبر احسن وسيلة انك تمرني كل عضلة بجسمك! مو بس حيقوي وينسق جسمك بالكامل هو راح يطور قدرة قلبك وأوعيتك الدموية، و راح يزيد من مرونتك ويبسطك",
            },
        },
    ],
    [

        {
            id: "2",
            class_name: "زومبا",
            class_image: "/assets/class_4.png",
            alt_image: "/assets/class5.jpg",
            details: {
                class_description: "الزومبا هو نوع من الرقص اللي بيخلط بين الرياضة والحركات السريعة مع الإيقاعات المختلفة. البرنامج بيتضمن تمارين لتقوية العضلات وتحسين المرونة، وكمان بيساعد على تحسين صحة القلب والرئتين",
            },
        },
    ],

    [
        {
            id: "3",
            class_name: "يوقا",
            class_image: "/assets/class_1.png",
            alt_image: "/assets/class9.jpg",
            details: {
                class_description: "حسّني من تناسق جسدك، توازنك، وهيئة جسمك مع حصة اليوقا اللي تجمع بين أنماط مختلفة من تقنيات التنفس والتأمل.  في هذا الكلاس رح نجمع بين أوضاع اليوقا وتمارين التنفس عشان تقوي جسمك وتقللي توترك وتهدئي عقلك",
            },
        },],
    [
        {
            id: "4",
            class_name: " رقص شرقي",
            class_image: "/assets/class_5.png",
            alt_image: "/assets/class2.jpg",
            details: {
                class_description: "الرقص الشرقي هو فن راقٍ يجمع بين الحركات الإيقاعية والإيماءات الجسدية الرشيقة، إذا كنتي تدوري على تجربة مميزة وممتعة للتعبير عن نفسك والترويح عن روحك فالرقص الشرقي هو اقتراحنا لكي. ",
            },
        },],

    [{
        id: "5",
        class_name: " باوند فيت",
        class_image: "/assets/class_2.png",
        alt_image: "/assets/class1.jpg",
        details: {
            class_description: "يوقا الحلقة الهوائية هو تمرين تنفسي يستعمل في اليوقا ويساعد في تنظيف الجهاز التنفسي وتحسين الصحة العامة، وكمان يساعد على تهدئة الجسم والعقل والتخلص من التوتر والإجهاد.",
        },
    },],

    [{
        id: "5",
        class_name: " لوكا فيوجن",
        class_image: "/assets/class_2.png",
        alt_image: "/assets/class3.jpg",
        details: {
            class_description: "يوقا الحلقة الهوائية هو تمرين تنفسي يستعمل في اليوقا ويساعد في تنظيف الجهاز التنفسي وتحسين الصحة العامة، وكمان يساعد على تهدئة الجسم والعقل والتخلص من التوتر والإجهاد.",
        },
    },],

    [{
        id: "5",
        class_name: " تويرك فيت",
        class_image: "/assets/class_2.png",
        alt_image: "/assets/class4.jpg",
        details: {
            class_description: "يوقا الحلقة الهوائية هو تمرين تنفسي يستعمل في اليوقا ويساعد في تنظيف الجهاز التنفسي وتحسين الصحة العامة، وكمان يساعد على تهدئة الجسم والعقل والتخلص من التوتر والإجهاد.",
        },
    },],

    [{
        id: "5",
        class_name: " فلاي فيت",
        class_image: "/assets/class_2.png",
        alt_image: "/assets/class7.jpg",
        details: {
            class_description: "يوقا الحلقة الهوائية هو تمرين تنفسي يستعمل في اليوقا ويساعد في تنظيف الجهاز التنفسي وتحسين الصحة العامة، وكمان يساعد على تهدئة الجسم والعقل والتخلص من التوتر والإجهاد.",
        },
    },],

    [{
        id: "5",
        class_name: "مرونة",
        class_image: "/assets/class_2.png",
        alt_image: "/assets/class8.jpg",
        details: {
            class_description: "يوقا الحلقة الهوائية هو تمرين تنفسي يستعمل في اليوقا ويساعد في تنظيف الجهاز التنفسي وتحسين الصحة العامة، وكمان يساعد على تهدئة الجسم والعقل والتخلص من التوتر والإجهاد.",
        },
    },]
]
export default class_data
