import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AdminClassCard from './AdminClassCard';
import { Button, Typography, Grid, IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import Axios from 'axios';
import { useAuth } from '../../../../context/auth'
import '../../../../styles/userStyles/Trainee.css';
import { HashLink as Link } from 'react-router-hash-link';
import { BUTTON_TYPES } from "../../../../data/Button_const";
import CustomButton from '../../../CustomButton.js'
import { toast, ToastContainer } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(2)
    },
    month: {
        marginRight: theme.spacing(1)
    },
    dayButton: {
        width: '70%',
        height: '70%',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        boxShadow: 'none',
        '&:focus': {
            outline: 'none', // Add this line to remove focus outline
        },
    },
    selectedDayButton: {
        color: '#F09FD2',
        border: 'none',
        boxShadow: 'none',
        '&:focus': {
            outline: 'none', // Add this line to remove focus outline
        },
    },
    dayButtonContent: {
        fontFamily: "'IBM Plex Sans', sans-serif",
        fontWeight: '100',
        fontSize: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    classInfo: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    previousButton: {
        marginRight: theme.spacing(1)
    },
    nextButton: {
        marginLeft: theme.spacing(1)
    },
    classIndicator: {
        fontSize: '1.5em',
        color: '#B10D33',
        textShadow: '0 0 10px #F09FD2',
    },
    dayIndicator: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));




function AdminClassess() {
    const classes = useStyles();
    const days = ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'];
    const [Classes, setClasses] = useState([]);
    const [selectedDay, setSelectedDay] = useState('');
    const [startOfWeek, setStartOfWeek] = useState(null);
    const [endOfWeek, setEndOfWeek] = useState(null);
    let class_date = '';
    const [auth, setAuth] = useAuth()
    const API_URL = process.env.REACT_APP_API_URL;

    const fetchData = async () => {
        const data = await Axios.get(`${API_URL}/lukatme/class`).then((data) => {
            setClasses(data.data)
        }).catch((err) => {
            console.log("the error is", err)
        })
    };

    useEffect(() => {

        fetchData();

        const now = new Date();
        const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
        const end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);

        setStartOfWeek(start);
        setEndOfWeek(end);
    }, []);

    const handleDayClick = (day) => {
        if (day === selectedDay) {
            setSelectedDay(null);
        } else {
            setSelectedDay(day);
        }
    };

    const handleDelete = async (classId) => {
        try {
            // console.log('class ID (enrollSub):', classId)
            const enrollResponse = await Axios.delete(`${API_URL}/lukatme/class/${classId}`,)
            // console.log(enrollResponse);
            toast.success('تم حذف الكلاس');
            fetchData();
        }
        catch (err) {
            toast.error("خطأ في حذف الكلاس");
            console.error(err);
        }
    };

    const handleUpdate = async (classId) => {
        try {
            // console.log('class ID (enrollSub):', classId)
            const enrollResponse = await Axios.put(`${API_URL}/lukatme/class/${classId}`,)
            // console.log(enrollResponse);
            toast.success('تم تعديل الكلاس');
            fetchData();
        }
        catch (err) {
            toast.error("خطأ في حذف الكلاس");
            console.error(err);
        }
    };

    const handlePrevClick = () => {
        const start = new Date(startOfWeek);
        start.setDate(start.getDate() - 7);
        const end = new Date(startOfWeek);
        end.setDate(end.getDate() - 1);
        setStartOfWeek(start);
        setEndOfWeek(end);
        setSelectedDay(null);
    };

    const handleNextClick = () => {
        const start = new Date(endOfWeek);
        start.setDate(start.getDate() + 1);
        const end = new Date(start);
        end.setDate(end.getDate() + 6);
        setStartOfWeek(start);
        setEndOfWeek(end);
        setSelectedDay(null);
    };

    const hasClassesOnDate = (date) => {
        return Classes.some(item => {
            const class_date = new Date(item.date.start_date).toLocaleDateString();
            return class_date === date.toLocaleDateString();
        });
    }

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <ToastContainer />
            <Link to="/admin/add_class"><CustomButton type={BUTTON_TYPES.SECONDARY} BtnTxt='اضافة كلاس + ' className='link'> </CustomButton></Link>
            <div className={classes.root}>
                <div className={classes.header}>
                    <IconButton
                        className={classes.previousButton}
                        onClick={handlePrevClick}
                        disabled={startOfWeek <= new Date()}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="body6" className={classes.month}>
                        {startOfWeek && endOfWeek &&
                            startOfWeek.toLocaleString('default', { month: 'long', year: 'numeric' })}
                    </Typography>
                    <IconButton
                        className={classes.nextButton}
                        onClick={handleNextClick}
                    >
                        <ArrowForward />
                    </IconButton>
                </div>

                <Grid container spacing={2}>
                    {days.map((day, index) => {
                        const date = new Date(startOfWeek);
                        date.setDate(date.getDate() + index);
                        const hasClasses = hasClassesOnDate(date);
                        const isSelected = selectedDay && date.toDateString() === selectedDay.toDateString();
                        return (
                            <Grid item xs key={day} className={classes.dayIndicator}>
                                <Button
                                    className={`${classes.dayButton} ${isSelected ? classes.selectedDayButton : ''}`}
                                    variant={isSelected ? 'contained' : 'outlined'}
                                    color={isSelected ? 'default' : 'default'}
                                    onClick={() => handleDayClick(date)}
                                >
                                    <div className={classes.dayButtonContent} >
                                        <Typography >{day}</Typography>
                                        <Typography variant="h5">{date.getDate()}</Typography>

                                    </div>

                                </Button>
                                {hasClasses && <span className={classes.classIndicator} onClick={() => handleDayClick(date)}>...</span>}
                            </Grid>
                        );
                    })}
                </Grid>

                {selectedDay &&
                    <div className={classes.classInfo}>
                        {Classes.map(item => {
                            class_date = new Date(item.date.start_date).toLocaleDateString()
                            if (class_date == selectedDay.toLocaleDateString())
                                return <Typography variant="subtitle1">
                                    <div className='traineeclass--classes'>
                                        <AdminClassCard key={item._id} class_id={item._id} class_name={item.class_name} age_group={item.ageGroup} no_of_seats={item.details.no_of_seats} studio_number={item.details.studio_number} trainer={item.trainer_info.trainer_name}
                                            class_duration={item.details.class_duration} date={item.date.start_date} time={item.date.time} number_of_trainees={item.enrolled_trainees.length} package_name={item.package_id.package_name} handleDelete={handleDelete}
                                        >
                                        </AdminClassCard>
                                    </div>
                                </Typography>
                        })}
                    </div>
                }
            </div>
        </ThemeProvider >
    );
}
export default AdminClassess;