import { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import '../../../../styles/userStyles/Trainer.css'
import TrainerClassCard from './TrainerClassCard';
import { useAuth } from '../../../../context/auth'
import Axios from 'axios';
function TrainerDashboard() {
    const [classes, setClasses] = useState()
    const [auth, setAuth] = useAuth();
    const trainerId = auth?.user?._id

    useEffect(() => {
        const fetchData = async () => {
            console.log('trainer: ', trainerId);
            try {
                const response = await Axios.get(`${process.env.REACT_APP_API_URL}/lukatme/class/${trainerId}`);
                const data = response.data;
                console.log('Response:', data);
                setClasses(data.classes); // Update this line
            } catch (err) {
                console.log("The error is", err);
            }
        };
        fetchData();
    }, []);

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div className='trainer--dashboard'>
                <Typography sx={{ pt: 5 }} variant="body2" color="" textAlign={'right'}>
                    كلاساتك القادمة
                </Typography>
                <div dir="rtl" className='trainerclass--classes '>

                    {

                        classes && classes.map((item) => {

                            return <TrainerClassCard class_name={item.class_name} no_of_seats={item.details.no_of_seats}
                                class_duration={item.details.class_duration} date={item.date.start_date} time={item.date.time} number_of_trainees={item.enrolled_trainees.length}
                                studio_number={item.details.studio_number}
                            />
                        })
                    }


                </div >
            </div>
        </ThemeProvider >
    )
}

export default TrainerDashboard