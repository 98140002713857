import React, { useEffect, useState } from 'react'
import { UserOutlined } from '@ant-design/icons';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ChairIcon from '@mui/icons-material/Chair';
import { Layout, Menu, theme, Drawer } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import GlobalStyleOverrides from '../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import HistoryIcon from '@mui/icons-material/History';
import DescriptionIcon from '@mui/icons-material/Description';
import '../../../styles/userStyles/Trainer.css'
import { HashLink as Link } from 'react-router-hash-link';
import PackageBadge from './PageContent/PackageBadge';
import { useAuth } from '../../../context/auth';
const { Header, Sider } = Layout;

export default function SideMenu() {
    const [visible, setVisible] = useState(false);
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState("/");
    const [collapsed, setCollapsed] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;

    const [auth, setAuth] = useAuth()

    useEffect(() => {
        const pathName = location.pathname;
        setSelectedKeys(pathName);
    }, [location.pathname]);

    const {
        token: { colorBgContainer }
    } = theme.useToken();

    const navigate = useNavigate();

    function handleLogout() {

        setAuth({
            ...auth, user: null, token: ''
        })
        localStorage.removeItem('auth');
        const token = localStorage.getItem('token');
        const emails = localStorage.getItem('emails') || "";
        if (!token) {
            console.error('Token not found in local storage');
            return;
        }
        // Send a POST request to the server
        fetch(`${API_URL}/lukatme/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': token
            },
            body: JSON.stringify({})
        })
            .then((response) => {
                console.log(response);
                if (response.ok) {
                    console.log('Logout successful');
                    localStorage.removeItem('token');
                    localStorage.clear();
                    localStorage.setItem('emails', emails);
                    window.location.href = '/home';
                } else {
                    console.error('Logout failed');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }


    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >

            <Button
                className="menu--button"
                type="primary"
                icon={<UserOutlined style={{ color: 'white' }} />}
                onClick={() => setVisible(true)}
            />

            <main className='sidemenu'>

                <Layout >
                    <Drawer
                        width={'250px'}
                        placement="left"
                        onClick={() => setVisible(false)}
                        onClose={() => setVisible(false)}
                        visible={visible}
                    >
                        <Sider trigger={null} collapsible collapsed={collapsed}>
                            <PackageBadge />
                            <Menu
                                className="SideMenuVertical"
                                mode="vertical"
                                onClick={(item) => {
                                    navigate(item.key);
                                }}
                                selectedKeys={[selectedKeys]}
                                items={[

                                    {

                                        label: " الملف الشخصي ",
                                        icon: <AutoGraphIcon />,
                                        key: `dashboard`
                                    },

                                    {

                                        label: "   الاشتراك ",
                                        icon: <LocalActivityIcon />,
                                        key: "subscription",
                                    },
                                    {
                                        label: "الحصص",
                                        key: "classes",
                                        icon: <ChairIcon />,
                                    },

                                    {
                                        icon: <SettingsIcon />,
                                        label: "الإعدادات",
                                        key: "settings",

                                    },

                                    {
                                        label: "سجل الدفع",
                                        key: "paymentHistory",
                                        icon: <HistoryIcon />,
                                    },


                                    {
                                        label: "السياسة والخصوصية ",
                                        key: "TermsAndConditions",
                                        icon: <DescriptionIcon />,
                                    },

                                ]}
                            >

                            </Menu>
                            <Link className='register--link' onClick={handleLogout}>  تسجيل الخروج  </Link>
                        </Sider>
                    </Drawer>
                </Layout>

            </main>
        </ThemeProvider >
    );
}

