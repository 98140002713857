import { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import ClassCalender from './ClassCalender';
import Axios from 'axios';
function TraineeClasses() {
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            const data = await Axios.get(`${API_URL}/lukatme/trainersList`).then((data) => {
                console.log(data)
                setClassTrainer(data.data)
            }).catch((err) => {
                console.log("the error is", err)
            })
        };
        fetchData();

    }, []);

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div className='trainee--classes'>
                <Typography sx={{ pt: 5 }} variant="body2" color="" textAlign={'right'}>
                    الكلاسات المتوفره
                </Typography>
                <ClassCalender />
            </div>
        </ThemeProvider >
    )
}

export default TraineeClasses