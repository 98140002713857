import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import moment from 'moment-timezone';
import CustomButton from '../../../CustomButton.js'
import { BUTTON_TYPES } from "../../../../data/Button_const";
import { HashLink as Link } from 'react-router-hash-link';
import axios from 'axios';
import { useEffect, useState } from 'react';

interface Data {
    name: string;
    email: string;
    date_of_birth: string;
    phone_number: string;
    national_id: string;
    weight: string;
    height: string

}

function createData(
    name: string,
    email: string,
    date_of_birth: string,
    phone_number: string,
    national_id: string,
    weight: string,
    height: string,
): Data {
    return {
        name,
        email,
        date_of_birth,
        phone_number,
        national_id,
        weight,
        height
    };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';
function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: string },
    b: { [key in Key]: string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    { id: 'weight', numeric: true, disablePadding: false, label: 'الوزن' },
    { id: 'height', numeric: true, disablePadding: false, label: 'الطول' },
    { id: 'national_id', numeric: true, disablePadding: false, label: 'الهوية' },
    { id: 'date_of_birth', numeric: false, disablePadding: false, label: 'تاريخ الميلاد' },
    { id: 'phone_number', numeric: true, disablePadding: false, label: 'رقم الجوال' },
    { id: 'email', numeric: false, disablePadding: false, label: 'البريد الإلكتروني' },
    { id: 'name', numeric: false, disablePadding: false, label: 'اسم المدربة' },
];


const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY: keyof Data = 'name';
const DEFAULT_ROWS_PER_PAGE = 5;

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof Data) => void;
    // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: keyof Data;
    // orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, newOrderBy);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'right'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {/* {headCell.id} */}
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                ) : (
                    <div className='addtrainer'>


                        <Link to="/admin/add_trainer"><CustomButton type={BUTTON_TYPES.SECONDARY} BtnTxt='اضافة مدربة + ' className='link' to="/register"> </CustomButton></Link>
                        <Typography

                            variant="h6"
                            id="tableTitle"
                            component="div"
                            align='right'
                        >
                            المدربات

                        </Typography>
                    </div>
                )}
            </Toolbar>
        </ThemeProvider>
    );
}

export default function AdminTrainersList() {
    const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = React.useState<keyof Data>(DEFAULT_ORDER_BY);
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [visibleRows, setVisibleRows] = React.useState<Data[] | null>(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
    const [paddingHeight, setPaddingHeight] = React.useState(0);
    const [trainers, setTrainers] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false); // <-- new state variable
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchTrainers = async () => {
            try {
                const response = await axios.get(`${API_URL}/lukatme/trainerslist`);
                // Map response data to the format expected by your table
                const formattedData = response.data.map((item: Data) => {
                    return createData(
                        item.name,
                        item.email,
                        moment(item.date_of_birth).format('D MMMM YYYY'),
                        item.phone_number,
                        item.national_id,
                        item.weight,
                        item.height
                    );
                });

                // After mapping all items, filter out null items
                setTrainers(formattedData);
                const nonNullFormattedData = formattedData.filter((item: Data | null): item is Data => item !== null);
                setTrainers(nonNullFormattedData);
            } catch (err) {
                console.error('Error:', err);
            };
        }
        fetchTrainers();
    }, []);

    const calculateVisibleRows = () => {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        return trainers.slice(startIndex, endIndex);
    };

    React.useEffect(() => {
        let rowsOnMount = stableSort(
            trainers,
            getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),
        );
        rowsOnMount = rowsOnMount.slice(
            0 * DEFAULT_ROWS_PER_PAGE,
            0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
        );

        setVisibleRows(rowsOnMount);
    }, [trainers]);

    const handleRequestSort = React.useCallback(
        (event: React.MouseEvent<unknown>, newOrderBy: keyof Data) => {
            const isAsc = orderBy === newOrderBy && order === 'asc';
            const toggledOrder = isAsc ? 'desc' : 'asc';
            setOrder(toggledOrder);
            setOrderBy(newOrderBy);

            const sortedRows = stableSort(trainers, getComparator(toggledOrder, newOrderBy));
            const updatedRows = sortedRows.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            );
            setVisibleRows(updatedRows);
        },
        [trainers, order, orderBy, page, rowsPerPage],
    );

    // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.target.checked) {
    //         const newSelected = trainers.map((n) => n.name);
    //         setSelected(newSelected);
    //         return;
    //     }
    //     setSelected([]);
    // };

    const handleChangePage = React.useCallback(
        (event: unknown, newPage: number) => {
            setPage(newPage);

            const sortedRows = stableSort(trainers, getComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                newPage * rowsPerPage,
                newPage * rowsPerPage + rowsPerPage,
            );
            // setVisibleRows(calculateVisibleRows());
            setVisibleRows(updatedRows);

            // Avoid a layout jump when reaching the last page with empty rows.
            const numEmptyRows =
                newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - trainers.length) : 0;

            const newPaddingHeight = (dense ? 33 : 53) * numEmptyRows;
            setPaddingHeight(newPaddingHeight);
        },
        [trainers, order, orderBy, dense, rowsPerPage, calculateVisibleRows],
    );

    const handleChangeRowsPerPage = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const updatedRowsPerPage = parseInt(event.target.value, 10);
            setRowsPerPage(updatedRowsPerPage);
            setPage(0);
            setVisibleRows(calculateVisibleRows());

            const sortedRows = stableSort(trainers, getComparator(order, orderBy));
            const updatedRows = sortedRows.slice(
                0 * updatedRowsPerPage,
                0 * updatedRowsPerPage + updatedRowsPerPage,
            );
            setVisibleRows(updatedRows);

            // There is no layout jump to handle on the first page.
            setPaddingHeight(0);
        },
        [trainers, order, orderBy, calculateVisibleRows],
    );

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={trainers.length}
                        />
                        <TableBody>
                            {visibleRows
                                ? visibleRows.map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            // key={trainers}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                        >


                                            <TableCell padding="checkbox"></TableCell>
                                            <TableCell align="right">{row.weight}</TableCell>
                                            <TableCell align="right">{row.height}</TableCell>
                                            <TableCell align="right">{row.national_id}</TableCell>
                                            <TableCell align="right">{row.date_of_birth}</TableCell>
                                            <TableCell align="right">{row.phone_number}</TableCell>
                                            <TableCell align="right">{row.email}</TableCell>
                                            <TableCell
                                                align="right"
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.name}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                                : null}
                            {paddingHeight > 0 && (
                                <TableRow
                                    style={{
                                        height: paddingHeight,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={trainers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>
    );
}