import React from 'react'
import Content from './Content';
import '../../../styles/userStyles/Trainer.css'
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
export default function TrainerDashboard() {

    const navigate = useNavigate();

    useEffect(() => {
        navigate('classes');
    }, []);

    return (
        <div>
            <Navbar />
            <Content />
        </div>

    )
}

