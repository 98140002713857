import { useState, useEffect } from "react";
import "../styles/Home.css";
import Footer from "../components/Footer";
import Navbar from "./Navbar";
import class_data from "../data/class_data";
import class_data2 from "../data/class_data2";
import ClassSummary from "./homeComponents/ClassSummary";
import EmailNewsLetter from "./homeComponents/EmailNewsLetter";
import OurClasses from "./homeComponents/OurClasses";
import PackageList from "./homeComponents/PackageList";
import Typography from "@mui/material/Typography";
import GlobalStyleOverrides from "./GlobalStyle";
import { ThemeProvider } from "@mui/material/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';
import { ScrollToTop } from "./ScrollToTop";
function Home() {


    const [class_Info, setClass] = useState([])
    const [fadeOut, setFadeOut] = useState(false);


    function fetchCardsFromAPI() {
        let indices = new Set();
        while (indices.size < 3) {
            indices.add(Math.floor(Math.random() * class_data.length));
        }

        const newCards = [...indices].map(index => class_data[index][0]);
        return newCards;
    }
    useEffect(() => {
        setClass(class_data)
        const getCards = async () => {
            const newCards = fetchCardsFromAPI();
            setClass(newCards);
        };
        getCards();
        const interval = setInterval(() => {
            setFadeOut(true);
            setTimeout(() => {
                getCards();
                setFadeOut(false);
            }, 1000);
        }, 5000);
        return () => clearInterval(interval);
    }, []);



    const RectangleButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border-radius: 0;
    width: 5%; /* Use responsive units like % */
    height: 5%; /* Use responsive units like % */
    min-width: 20px;
    min-height: 20px;
    z-index: 1;
  `;

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <RectangleButton
                className={className}
                style={{ ...style, color: '#B10D33', left: 20 }}

                onClick={onClick}
            >
                Previous
            </RectangleButton>
        );
    }

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <RectangleButton
                className={className}
                style={{ ...style, color: '#B10D33', right: 20 }}
                onClick={onClick}
            >
                Next
            </RectangleButton>
        );
    }


    const settings = {
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            }
        ],
    };



    return (
        <ThemeProvider theme={GlobalStyleOverrides()}>
            <ScrollToTop />
            <div className="home">
                <Navbar />
                <section>
                    <img src='../assets/landing6.jpg' className="home--hero"></img>
                </section>
                <section className="home--classes">
                    <div>
                        {class_Info.flat().slice(0, 4).map((card, i) => (
                            <div key={i} className={`card-container ${fadeOut ? 'fade-out' : ''}`} style={{ transitionDuration: '1s' }}>
                                <ClassSummary
                                    key={card.id}
                                    class_name={card.class_name}
                                    class_image={card.class_image}
                                    class_description={card.details.class_description}
                                />
                            </div>
                        ))}
                    </div>

                </section>
                <Typography
                    sx={{
                        pt: {
                            xs: 7,
                            sm: 8,
                            md: 5,
                        },


                        pb: {
                            xs: 2,
                            sm: 2,
                            md: 2,
                        },
                        fontSize: {
                            xs: '1.2rem',
                            sm: '1.5rem',
                            md: '1.7rem',
                        }

                    }}
                    color="primary.main"
                    textAlign={"center"}
                >
                    ايش هي كلاساتنا
                </Typography>
                <section className="our--classes" id="ourclasses">
                    <Slider {...settings} className="our--classes--cards">
                        {class_data2.flat().map((card, i) => (
                            <OurClasses
                                key={card.id}
                                class_name={card.class_name}
                                class_image={card.alt_image}
                            />
                        ))}
                    </Slider>
                </section>

                <section className="home--packages">
                    <Typography
                        sx={{
                            pt: {
                                xs: 7,
                                sm: 5,
                                md: 5,
                            },


                            pb: {
                                xs: 2,
                                sm: 2,
                                md: 2,
                            },
                            fontSize: {
                                xs: '1.2rem',
                                sm: '1.5rem',
                                md: '1.7rem',
                            }

                        }}
                        color="primary.main"
                        textAlign={"center"}
                    >
                        قائمة الاشتراك
                    </Typography>
                    <PackageList />
                </section>

                <section className="home--newsletter">
                    <EmailNewsLetter />
                </section>

                <Footer />
            </div>
        </ThemeProvider >

    );
}

export default Home;
