import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import '../../styles/homeComponentsStyles/OurClasses.css';

function OurClasses(props) {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >

            <div
                className="class--card"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <img src={props.class_image} className="card-image" />
                {isHovered && <div className="card-name"><Typography variant="body2" color="primary.other" textAlign={'center'} >
                    {
                        <h>{props.class_name}</h>
                    }
                </Typography></div>}
            </div>



        </ThemeProvider >
    )
}

export default OurClasses