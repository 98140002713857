
import { Route, Routes } from "react-router-dom";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import Trainees from '../Trainer/PageContent/Trainees.tsx';
import TrainerClasses from '../Trainer/PageContent/TrainerClasses'
import TrainerSetting from '../Trainer/PageContent/TrainerSetting'
import TrainerDashboard from '../Trainer/PageContent/TrainerDashboard';
import ProtectedElement from '../ProtectedElement.js';
import ChangePassword from '../Trainee/PageContent/ChangePassword.js'

export default function TrainerRoutes() {
    return (
        <Routes>
            <Route path="dashboard" element={<ProtectedElement element={<TrainerDashboard />} allowedRoles={'trainer'} />} />
            <Route path="classes" element={<ProtectedElement element={<TrainerClasses />} allowedRoles={'trainer'} />} />
            <Route path="trainees" element={<ProtectedElement element={<Trainees />} allowedRoles={'trainer'} />} />
            <Route path="settings" element={<ProtectedElement element={<TrainerSetting />} allowedRoles={'trainer'} />} />
            <Route path="settings/changepassword-trainer/:userID" element={<ProtectedElement element={<ChangePassword />} allowedRoles={'trainer'} />} />

        </Routes>
    )
}
