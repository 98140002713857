
import '../styles/Footer.css'
import { MDBFooter } from 'mdb-react-ui-kit'
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import logo from '../assets/logo.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';
import { HashLink as Link } from 'react-router-hash-link';
function Footer() {


    //Styles for logo + copyright 

    const logoStyle = {
        width: '180px',
        height: '160px',
    };

    const copyRightStyle = {
        backgroundColor: '#B10D33',
    }

    return (
        <MDBFooter bgColor='F0EEEE' className='text-center text-lg-start text-muted upperShadow'>

            <div className="container p-4 centerContent">
                <div className="row my-4">

                    {/*Contact us + social media column*/}
                    <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                        <h5 className="mb-4"><Link className='link' to="/contactus">تواصلي معنا</Link></h5>
                        <ul className="list-unstyled">
                            <li>
                                <a href="https://maps.app.goo.gl/MZu9Gof9RSpvx2tL6" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}><i className="fas fa-map-marker-alt pe-2 "></i>حي أبحر الشمالية، شمال مدينة جدة، ٢٣٠٠١٢</a>
                            </li>
                            <li>
                                <p>
                                    <a href="tel:+966537260396" style={{ color: 'inherit', textDecoration: 'none' }}>
                                        <i className="fas fa-phone pe-2"></i>&lrm;+966-53-726-0396
                                    </a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <i className="fas fa-envelope pe-2 mb-0"></i>
                                    <a href="mailto:Lukatmestudio@outlook.com">Lukatmestudio@outlook.com</a>
                                </p>
                            </li>
                        </ul>
                        {/* <IconButton href="#!" ><TwitterIcon /></IconButton> */}
                        <IconButton href="https://www.instagram.com/lukatmestudio/" ><InstagramIcon /></IconButton>
                    </div>

                    {/*Subscription column*/}
                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <a href="#!" className="text-grey text-reset"><h5 className="mb-4 ">  <Link className='link' to={"/packages"}> البكجات المتوفرة  </Link></h5></a>
                    </div>

                    {/*Main-page column*/}
                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <a href="#!" className="text-grey text-reset"><h5 className="mb-4"> <Link className='link' to={"/home"}>الصفحة الرئيسية</Link></h5></a>
                        <ul className="list-unstyled">
                            <li className="mb-2">
                                <a href="#!" className="text-grey text-reset">  <Link className='link' to="/home#ourclasses"> كلاساتنا </Link></a>
                            </li>

                            <li className="mb-2">
                                <a href="#!" className="text-grey text-reset">  <Link className='link' to={"/aboutus"}>تحبي تعرفينا أكتر؟</Link> </a>
                            </li>
                        </ul>
                    </div>

                    {/*Logo column*/}
                    <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                        <div className=" d-flex align-items-center justify-content-center mb-4 mx-auto">
                            <a href="./home" style={{ color: 'inherit', textDecoration: 'none' }}>
                                <img src={logo} alt="" style={logoStyle}
                                    loading="lazy" /></a>
                        </div>


                    </div>

                </div>
            </div>

            <div className="text-center p-3 text-white" style={copyRightStyle}>
                Powered and Designed by {<a className="text-white" href="https://Brmjha.com/">Brmjha.com</a>} © 2023
            </div>

        </MDBFooter >
    );
}

export default Footer