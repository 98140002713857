import React, { useState, useEffect } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import Card from '@mui/material/Card';
import { Typography } from '@mui/material'
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import CardContent from '@mui/material/CardContent';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { makeStyles } from '@material-ui/core/styles';
import Face3Icon from '@mui/icons-material/Face3';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useAuth } from '../../../../context/auth'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Axios from 'axios';
import { Button } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment-timezone';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles({
    default: {
        display: 'flex',
        width: '100%',
        borderRight: '5px solid #9E9898',
        textAlign: 'right',
        justifyContent: 'right'
    },
    fullybooked: {
        display: 'flex',
        width: '100%',
        borderRight: '5px solid #ff9800',
    },
    content: {
        flex: '1 0 auto',
    },
});

function TraineeClassCard(props) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [auth, setAuth] = useAuth();
    const traineeId = auth?.user?._id
    const class_card = useStyles();
    const [enrolled, setEnrolled] = useState(false);
    const [enrollmentStatus, setEnrollmentStatus] = useState(false);
    const currentDate = new Date()
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchEnrollmentStatus = async () => {
            try {
                const res = await Axios.post(`${API_URL}/lukatme/class/check_enroll/?id=${props.class_id}`, { traineeId: traineeId });
                const enrolledStatus = res.data;
                // console.log("isEnrolled", enrolledStatus);

                if (enrolledStatus && enrolledStatus.isEnrolled) {
                    setEnrolled(true);
                }
            } catch (err) {
                console.error(err);
            }
        };


        fetchEnrollmentStatus();
    }, [enrolled]);

    // console.log("enroled", enrolled)
    const handleEnrollClick = async (classId, traineeId) => {

        //add enrolled class to subscrition table
        try {
            // console.log('class ID (enrollSub):', classId)
            const enrollResponse = await Axios.post(`${API_URL}/lukatme/enrollclass`, { classId, traineeId })
            // console.log('statuss: ', enrollResponse.status)
            setEnrolled(true);
            toast.success("تم التسجيل في الكلاس");
        }
        catch (err) {

            if (err.response && err.response.status === 400) {
                toast.error("ماتقدري تضيفي كلاسين بنفس الوقت")
                // console.log("You cannot add two classes at the same time")
                // } else if (enrollResponse.status == 200) {
                //     setEnrolled(true)

            }
            else { toast.error("حالة اشتراكك لاتسمح لك بإضافة كلاس   ") }

            console.error(err);

        }
    };

    const handleUnenrollClick = async (classId, traineeId) => {

        // try {
        //     console.log('class ID (unenroll):', classId)

        //     const res = await Axios.post(`http://localhost:8001/lukatme/class/unenroll`, { classId, traineeId })
        //     setEnrolled(false)
        //     localStorage.setItem(`class-${classId}-trainee-${traineeId}-enrolled`, false);

        // }
        // catch (err) {
        //     console.error(err);
        // }

        //remove enrolled class from subscrition table

        try {
            // console.log('class ID (unenrollSub):', classId)

            const res = await Axios.post(`${API_URL}/lukatme/unenrollclass`, { classId, traineeId })
            setEnrolled(false);
            toast.success("تم إلغاء التسجيل في الكلاس");
        }
        catch (err) {
            console.error(err);
        }
    };


    const getDay = (day) => {

        switch (day) {
            case 'Sunday':
                return 'الأحد';
            case 'Monday':
                return 'الأثنين';
            case 'Tuesday':
                return 'الثلاثاء';
            case 'Wednesday':
                return 'الأربعاء';
            case 'Thursday':
                return 'الخميس';
            case 'Friday':
                return 'الجمعة';
            case 'Saturday':
                return 'السبت';
            default:
                return null;
        }
    }
    // const isToday = (classDateTime) => {
    //     const today = new Date();
    //     const classDateTimeObj = new Date(classDateTime);

    //     // Compare the timestamps of classDateTime and today
    //     return classDateTimeObj.getTime() <= today.getTime();
    //   };
    const isDateTimePast = (classDateTime) => {
        const currentTime = new Date();
        const classDateTimeObj = new Date(classDateTime);

        return currentTime > classDateTimeObj;
    };

    const isDateTimePastHour = (classDateTime) => {
        const currentTime = new Date();
        const classDateTimeObj = new Date(classDateTime);
        
        // Subtract 1.5 hours (90 minutes) from the class time
        classDateTimeObj.setMinutes(classDateTimeObj.getMinutes() - 90);
        
        return currentTime > classDateTimeObj;
    };
    

    const getDate = (class_date) => {
        if (!class_date) {
            console.log('Invalid or no date provided');
            return;
        }

        class_date = new Date(class_date);

        if (isNaN(class_date)) {
            console.log('Invalid date provided');
            return;
        }

        const year = class_date.getFullYear();
        const month = (class_date.getMonth() + 1).toString().padStart(2, '0');
        const day = class_date.getDate().toString().padStart(2, '0');
        const simpleDate = `${year}/${month}/${day}`;
        // console.log(simpleDate);
        return simpleDate;
    };

    return (
        <ThemeProvider theme={GlobalStyleOverrides()}>
            <ToastContainer />


            <div dir="rtl" className='trainee--class--card'>
                <Card textAlign='right' justifyContent='right' className={class_card.default} sx={{ p: 2, mt: 2, background: '#FAFAFA', display: 'flex', flexDirection: isSmallScreen ? 'column' : 'column' }}>

                    <div className='card--title'>
                        <Typography variant="body1" color="primary.main" >
                            {
                                props.class_name
                            }
                        </Typography>
                    </div>
                    {props.type === "dashboard" && (
                    <Typography>
                        {
                            props.attendance == true && isDateTimePast(props.date) ? (<Typography color="secondary.other" variant='body4'>
                               تم حضور الكلاس</Typography>) :
                                (
                                    props.attendance == false && isDateTimePast(props.date) ? (<Typography color="secondary.other" variant='body4'>
                               لم يتم حضور الكلاس</Typography>) :
                                (
                                    // (localStorage.getItem(`class-${props.class_id}-trainee-${traineeId}-enrolled`)) == "true" ?
                                   
                                         <Button onClick={() => handleUnenrollClick(props.class_id, traineeId)} type='button'>  <Typography color="primary.main" variant='body4'>
                                          الغاء التسجيل  </Typography></Button>
                                       
                                 )
                                )
                        }
                    </Typography>
                    )}
                       {props.type === "classCalender" && (
                    <Typography>
                       
                        {
                            props.no_of_seats == props.number_of_trainees || isDateTimePastHour(props.date) ? (<Typography color="secondary.other" variant='body4'>
                                انتهى التسجيل </Typography>) :
                                (
                                    // (localStorage.getItem(`class-${props.class_id}-trainee-${traineeId}-enrolled`)) == "true" ?
                                    enrolled ?
                                        (
                                            <Button onClick={() => handleUnenrollClick(props.class_id, traineeId)} type='button'>  <Typography color="primary.main" variant='body4'>
                                                الغاء التسجيل  </Typography></Button>
                                        ) :
                                        (<Button onClick={() => handleEnrollClick(props.class_id, traineeId)} type='button'>

                                            <Typography color="secondary.other" variant='body4'>
                                                سجلي الآن  </Typography>
                                        </Button>)
                                )
                        }
                    </Typography>
                    )}
                    <CardContent className={class_card.content}>
                        <Typography color="secondary.other" variant='body1' dir="ltr" >
                            {moment.tz(props.date, "Asia/Riyadh").format('h:mm A')} <AccessTimeIcon fontSize="medium" color="secondary" opacity="30%" />
                        </Typography>


                        <br />

                        <Typography color="secondary.other" variant='body1' dir="rtl" >
                            <TimelapseIcon fontSize="medium" color="primary.main" opacity="30%" />&nbsp;
                            مدة الكلاس: {props.class_duration} دقيقة
                        </Typography>


                        <br />

                        <Typography color="secondary.other" variant='body1'>
                            {
                                <h><CalendarMonthIcon fontSize="medium" color="secondary" opacity="30%" /> {getDate(props.date)} </h>
                            }
                        </Typography>

                        <br />

                        <Typography color="secondary.other" variant='body1'>
                            <EventSeatIcon fontSize="medium" color="secondary" opacity="30%" /> &nbsp; متبقي {props.no_of_seats - props.number_of_trainees} مقعد من {props.no_of_seats} مقاعد
                        </Typography>

                        <br />
                        <Typography color="secondary.other" variant='body1'>
                            <Face3Icon fontSize="medium" color="secondary" opacity="30%" /> &nbsp; المدربة: {props.trainer}
                        </Typography>

                        <br />
                        <Typography color="secondary.other" variant='body1'>
                            <LocationOnIcon fontSize="medium" color="secondary" opacity="30%" /> &nbsp;ستوديو رقم   {props.studio_number}
                        </Typography>
                        <br />
                        <Typography color="secondary.other" variant='body1'>
                            <LocalOfferIcon fontSize="medium" color="secondary" opacity="30%" /> &nbsp;اسم الباكج  {props.package_name}
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </ThemeProvider>

    )
}

export default TraineeClassCard