import React, { useState, useEffect } from "react";
import '../styles/cart.css'
import WalletIcon from '@mui/icons-material/Wallet';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Navbar from './Navbar'
import Footer from './Footer'
import { useCart } from '../context/cart';
import { useAuth } from '../context/auth';
import { Grid } from '@material-ui/core';
import Axios from 'axios'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import GlobalStyleOverrides from "./GlobalStyle";
import { ThemeProvider } from "@mui/material/styles";
import { styled } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Divider from '@material-ui/core/Divider';
import { BUTTON_TYPES } from "../data/Button_const";
import { ToastContainer, toast } from 'react-toastify';

import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background-color: ${({ theme }) => theme.palette.common.white};
    color: #EA358C;
    font-size: 25px;
    padding: 15px;

    @media (max-width: 767px) {
      font-size: 16px;
      padding: 5px;
    }
  }

  &.${tableCellClasses.body} {
    font-size: 10px;

    @media (max-width: 767px) {
      font-size: 8px;
    }
  }
`;

const StyledTableCellBlack = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: 25,

    '@media (max-width: 767px)': {
      // Styles for mobile screens
      fontSize: 16
    }

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,

  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    fontSize: 25,
  },
  '@media (max-width: 767px)': {
    fontSize: 10,
    padding: '8px',
  },
}));


const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  margin: '20px',

  '@media (max-width: 767px)': {
    // Styles for mobile screens
    margin: '10px',

  }
}
));

export default function Cart() {
  const [cart, setCart] = useCart()
  const [auth, setAuth] = useAuth()
  const [numberOfClasses, setNmberOfClasses] = useState([])
  const [numberOfMonthes, setNmberOfMonthes] = useState([])
  const [oldPrice, setOldPrice] = useState([]);
  const [price, setPrice] = useState([]);

  const [newPrice, setNewPrice] = useState([])
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const [vat , setVat] = useState()
  // console.log("cart", cart)
  const API_URL = process.env.REACT_APP_API_URL;

  const handleChange = (event, index) => {
    const newNumberOfClassesArray = [...numberOfClasses];
    newNumberOfClassesArray[index] = event.target.value;
    setNmberOfClasses(newNumberOfClassesArray);

    // Store the selected value in localStorage
    localStorage.setItem('selectedNumberOfClasses', JSON.stringify(newNumberOfClassesArray));
  };
  useEffect(() => {
    // Retrieve the selected number of classes from localStorage when the component mounts
    const storedNumberOfClasses = localStorage.getItem("selectedNumberOfClasses");
    if (storedNumberOfClasses) {
      const parsedNumberOfClasses = JSON.parse(storedNumberOfClasses);
      setNmberOfClasses(parsedNumberOfClasses);
    }
  }, []);

  useEffect(() => {
    console.log("numberOfClasses updated:", numberOfClasses);
  }, [numberOfClasses]);

  useEffect(() => {
    if (cart) {

      const updatedNumberOfClasses = cart.map((item) => {


        const classNumberSingle = item.types?.length == 1


        const selectedType = item.types?.find((type) => item.type_id === type.type_id);
        const numberOfClasseChoosed = selectedType ? selectedType.class_number : 0;
        console.log("number of classsss", numberOfClasseChoosed);

        return classNumberSingle ? item.types[0]?.class_number : numberOfClasseChoosed;



      });
      setNmberOfClasses(updatedNumberOfClasses);


    }
  }, [cart]);
  console.log("numberOfClasses", numberOfClasses)


  //print price

  useEffect(() => {
    const updatedPrices = numberOfClasses.map((classNumber, index) => {
      if (cart[index]?.types) {
        const selectedType = cart[index].types?.find(
          (type) => type?.class_number == classNumber
        );
        if (selectedType && selectedType.newPrice !== 0) {
          // setNewPrice(selectedType.newPrice);
          return selectedType.newPrice;
        } else {
          // setOldPrice(selectedType.oldPrice);
          return selectedType ? selectedType.oldPrice : 0;
        }
      }
    });

    setPrice(updatedPrices);
  }, [numberOfClasses, cart]);
  console.log("price", price)


  //print new price

  useEffect(() => {
    const updatedPrices = numberOfClasses.map((classNumber, index) => {
      if (cart[index]?.types) {
        const selectedType = cart[index].types?.find(
          (type) => type?.class_number == classNumber
        );
        return selectedType ? selectedType.newPrice : 0;
      }
    });

    setNewPrice(updatedPrices);
  }, [numberOfClasses, cart]);
  console.log("new", newPrice)

  //print old price

  useEffect(() => {
    const updatedPrices = numberOfClasses.map((classNumber, index) => {
      if (cart[index]?.types) {
        const selectedType = cart[index]?.types?.find(
          (type) => type?.class_number == classNumber
        );
        return selectedType ? selectedType.oldPrice : 0;
      }
    });

    setOldPrice(updatedPrices);
  }, [numberOfClasses, cart]);



  console.log("numberOfClasses", numberOfClasses)
  console.log("price:", price);

  console.log("new price:", newPrice);
  console.log("old price:", oldPrice);

  useEffect(() => {
    const updatedNumberOfMonthes = numberOfClasses.map((classNumber, index) => {
      if (cart[index]?.types) {
        const selectedType = cart[index]?.types?.find(
          (type) => type.class_number == classNumber
        );
        return selectedType ? selectedType.number_of_monthes : 1;
        // } else if (classNumber == '00') {
        //   const noClasses = cart[index]?.types?.find(
        //     (type) => type.class_number == "0"
        //   );
        //   // If class_number is '0', print the number_of_monthes
        //   // return noClasses.number_of_monthes;
        //   return 1;

      } else {
        // If there are no types for the item and class_number is not '0', use the default number_of_monthes as 1
        return cart[index]?.number_of_monthes || 1;
      }
    });

    setNmberOfMonthes(updatedNumberOfMonthes);
  }, [numberOfClasses, cart]);

  console.log("number of months:", numberOfMonthes);



  const CalculateVAT = () => {
    let vat = 0;
    let total = 0;


    total = price.reduce((accumulator, item) => accumulator + item, 0);




    vat = total * 0.15;
    return vat;
  };

  const TotalWithVAT = () => {
    let totalPrice = 0;
    let totalPriceWithVat = 0;


    price?.map((item) => {
      totalPrice = totalPrice + item;
    });



    totalPriceWithVat = totalPrice + CalculateVAT();
    return totalPriceWithVat;
  };
  const TotalWithOutVAT = () => {
    let totalPrice = 0;


    price?.map((item) => {
      totalPrice = totalPrice + item;
    });


    return totalPrice;
  };


  const handlePayment = async () => {
    const userId = auth?.user._id;
    const subscriptionData = [];

    // Access the individual properties of childInfo


    for (let i = 0; i < cart.length; i++) {
      const cartItem = cart[i];
      if (cartItem.ageGroup == 'adults') {
        const subscriptionDetails = {
          user: userId,
          package_id: cartItem._id,
          class_limit: numberOfClasses[i],
          subscription_duration: numberOfMonthes[i],
        };
        subscriptionData.push(subscriptionDetails);

      } else if (cartItem.ageGroup == 'kids') {
        const subscriptionDetails = {
          user: userId,
          package_id: cartItem._id,
          class_limit: numberOfClasses[i],
          subscription_duration: numberOfMonthes[i],

          child_name: cartItem.formData.childName,
          child_date_of_birth: cartItem.formData.child_date_of_birth,
          child_health_issues: cartItem.formData.child_health_issues,

        }
        subscriptionData.push(subscriptionDetails);

      };

    }

    console.log("subscriptionData", subscriptionData);

    // Assign the value to a separate variable

    const paymentData = {
      user: userId,
      amount: TotalWithOutVAT(),
      currency: 'SR',
      subscriptions: []
    };

    try {
      // Save the subscription
      const savedSubscription = await Axios.post(
        `${API_URL}/lukatme/subscription/add`,
        { subscriptionData: subscriptionData },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            user: userId,
          },
        }
      );

      console.log("savedSubscription", savedSubscription.data);
      paymentData.subscriptions = savedSubscription.data.map(subscription => subscription._id);

      console.log("paymentData", paymentData);

      // Save the payment with the associated subscriptions
      const savedPayment = await Axios.post(
        `${API_URL}/lukatme/payment/add`,
        paymentData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log(savedPayment.data);
      return savedPayment; // Return the entire response object
    } catch (err) {
      console.error('Error:', err);
      if (err.response && err.response.status === 400) {

        toast.error("ماتقدري تشتركي ولسا اشتراكك السابق ماخلص ");
      }


      throw err;
    }

  };


  const handleButtonClick = async () => {
    try {
      if (numberOfClasses.includes("") || numberOfClasses.includes("0")) {
        // If the user hasn't selected the number of classes, show an alert
        toast.error("اختاري كم كلاس تبغي");
        return;
      }

      const response = await handlePayment();

      if (response.status == 200) {
        setCart('');
        toast.success('تم ارسال الطلب بنجاح، تقدري تدفعي في الاستوديو');
        localStorage.removeItem('cart');
      } else if (response.status === 400) {
        alert("ماتقدري تشتركي ولسا اشتراكك السابق ماخلص ");
      } else {
        console.log('Payment was not successful');
      }
    } catch (err) {
      console.error('Error handling payment:', err);

    }
  };


  const totalPrice = () => {
    try {
      let total = 0;

      cart?.map((item) => {
        if (item.class_price) {
          total = total + item.class_price;
        }
        else {
          total = total + item.package_price;
        }

      });
      return total
    } catch (error) {
      console.log(error)
    }
  }
  //delete items
  const removeCartItem = (pid) => {
    try {
      let myCart = [...cart];
      let index = myCart.findIndex((item) => item._id == pid);
      myCart.splice(index, 1);
      setCart(myCart);

      // Convert the updated array back to a JSON string
      const updatedCartJSON = JSON.stringify(myCart);

      // Store the updated JSON string in localStorage
      localStorage.setItem('cart', updatedCartJSON);
      // Get the selectedNumberOfClasses array from localStorage
      const selectedNumberOfClassesJSON = localStorage.getItem('selectedNumberOfClasses');
      let selectedNumberOfClasses = [];

      if (selectedNumberOfClassesJSON) {
        selectedNumberOfClasses = JSON.parse(selectedNumberOfClassesJSON);
        // Remove the corresponding item from the selectedNumberOfClasses array
        selectedNumberOfClasses.splice(index, 1);
        // Store the updated selectedNumberOfClasses array in localStorage
        localStorage.setItem('selectedNumberOfClasses', JSON.stringify(selectedNumberOfClasses));
      }
    } catch (error) {
      console.log(error);
    }

  }
  return (

    <ThemeProvider theme={GlobalStyleOverrides()}>
      <ToastContainer position="top-center" hideProgressBar={true} autoClose={3000} closeOnClick />
      <Navbar />
      {cart.length > 0 ?
        <MDBContainer className="py-5 justify-content-end align-items-center" >
          <MDBRow className="flex-row-reverse " style={{ marginBottom: "15px" }}>
            <MDBCol sm="12" md="12" lg="12" xl="12" className="text-end">
              <div className="text-black">
                <Typography
                  component="h1"
                  variant="body8"
                  align="right"
                  color="secondary.other"
                  gutterBottom
                >
                  سلة تسجيل الباكج
                </Typography>

              </div>
              <div className="grey-text">
                <Typography variant="body3" align="right" color="secondary.other">
                  اكملي التسجيل للباكج المحدد بتعبئة البيانات المخصصة
                </Typography>
              </div>
            </MDBCol>

          </MDBRow>

          <MDBRow className="container p-4 flex-row-reverse" >

            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <Grid item xs={12} sm={10}>
                <Divider style={{ width: isSmallScreen ? '100%' : '850px', backgroundColor: 'grey' }} />
              </Grid>
              <Grid item xs={12} sm={2} style={{ textAlign: 'right' }}>
                <Typography variant="subtitle1" align="right" color="secondary.other" style={{ marginRight: isSmallScreen ? '10px' : '0' }}>
                  سلة الإشتراكات <WalletIcon fontSize={isSmallScreen ? 'small' : "medium"} className="wallet" />
                </Typography>
              </Grid>
            </Grid>
          </MDBRow>

          <MDBRow className="container p-4 flex-row-reverse" >

            <StyledTableContainer component={Paper} className="custom-table-container">
              <Table className="custom-table">
                <TableHead>
                  <TableRow className="custom-table">
                    <StyledTableCell align="right"> <Typography variant="subtitle2" align="right" color="primary.main">
                      حذف
                    </Typography></StyledTableCell>
                    <StyledTableCellBlack align="right"><Typography variant="subtitle2" align="right" color="secondary.main">
                      السعر
                    </Typography></StyledTableCellBlack>
                    <StyledTableCell align="right"><Typography variant="subtitle2" align="right" color="primary.main">
                      عدد الكلاسات
                    </Typography></StyledTableCell>
                    <StyledTableCell align="right"><Typography variant="subtitle2" align="right" color="primary.main">
                      اسم الباكج
                    </Typography></StyledTableCell>

                  </TableRow>
                </TableHead>

                <TableBody>
                  {cart &&
                    cart.map((item, index) => (
                      <StyledTableRow key={index} >
                        <TableCell align="right">
                          <a href="#!" className="text-danger" onClick={() => removeCartItem(item._id)}>
                            <MDBIcon fas icon="trash text-danger" size="sm" />
                          </a>
                        </TableCell>
                        {newPrice[index] == 0 ? (
                          <TableCell align="right">
                            <Typography variant="subtitle2" align="right" color="secondary.other">
                              {price[index] || 0} ريال
                            </Typography>
                          </TableCell>
                        ) :
                          <TableCell align="right">
                            <Typography variant="subtitle2" align="right" color="secondary.other">
                              <Typography variant="body2" color="secondary.other" style={{ textDecoration: "line-through" }}>
                                {oldPrice[index] || 0} ريال
                              </Typography>
                              {newPrice[index] || 0} ريال
                            </Typography>
                          </TableCell>}

                        <TableCell align="right">
                          {item.types?.some((i) => i.class_number) ? (

                            <select value={numberOfClasses[index] || '0'} onChange={(event) => handleChange(event, index)}>
                              <option value="0">اختاري كم عدد الكلاسات</option>

                              {item.types?.map((i, optionIndex) => (
                                <option key={optionIndex} value={i.class_number}>
                                  {i.class_number} كلاس
                                </option>
                              ))}
                            </select>

                          ) : (
                            <p className="lead fw-normal mb-2">شهر واحد</p>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="subtitle2" align="right" color="secondary.other">
                            {item.package_name}
                          </Typography>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </MDBRow>

          <MDBRow className="flex-row-reverse p-4 container " >
            <MDBCol className="col" md="6" lg="6" xl="6">
              {/* <p className="lead mb-2 subject-price">: القيمة المضافة</p> */}
              <p className="lead mb-2 subject-price">: المجموع شامل القيمة المضافة</p>
              {/* <p className="lead mb-2 subject-price text-right">: المجموع </p> */}

            </MDBCol>
            <MDBCol className="col" md="6" lg="6" xl="6">
              {/* <p className="lead mb-2 subject-price">{CalculateVAT()} ريال</p> */}
              <p className="lead mb-2 subject-price text-right">{TotalWithOutVAT()} ريال</p>
            </MDBCol>
          </MDBRow>

          <MDBRow className="container btn-row" style={{ marginBottom: "15px" }}>
            <FormGroup dir='rtl'>
              <FormControlLabel control={<Checkbox defaultChecked />} label="الدفع في الاستديو" />
              <FormControlLabel disabled control={<Checkbox />} label="الدفع عبر البطاقة (قريبًا)" />
            </FormGroup>
            {auth?.user ?
              <MDBBtn type={BUTTON_TYPES.PRIMARY} className="btn" onClick={handleButtonClick}>
                إكمال الدفع
              </MDBBtn>
              :
              <MDBBtn type={BUTTON_TYPES.PRIMARY} className="btn" onClick={() => window.location.href = "/signin"}>
                إكمال الدفع
              </MDBBtn>
            }
          </MDBRow>

        </MDBContainer> :

        <div className="grey-text emptyCart" style={{ textAlign: 'center' }}>
          <MDBTypography tag="p" className="fw-normal mb-0">
            سلة الاشتراكات فارغة
            <br />
            <Link className='link' to={"/packages"}>
              <Typography variant="body10" color={'primary.main'} textAlign={'center'}>الذهاب لقائمة الإشتراك</Typography>
            </Link>
          </MDBTypography>
        </div>


      }

      <Footer />
    </ThemeProvider>


  );
}