import { useState, useEffect } from 'react'
import '../../../../styles/userStyles/Trainee.css'
import Axios from 'axios';
import { useAuth } from '../../../../context/auth'
import GlobalStyleOverrides from '../../../GlobalStyle';
import Typography from "@mui/material/Typography";
import { ThemeProvider } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { useMediaQuery } from "@mui/material";
function PackageBadge() {


    const [subscriptionInfo, setSubscriptionInfo] = useState([])
    const [classCount, setClassCount] = useState(null)
    const [auth, setAuth] = useAuth()
    const API_URL = process.env.REACT_APP_API_URL;
    const traineeId = auth?.user?._id
    let token = localStorage.getItem('token')
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {

        const fetchClassCount = async () => {
            try {
                const response = await Axios.get(`${API_URL}/lukatme/attendance/${traineeId}`);
                setClassCount(response.data.count);
            } catch (err) {
                console.log("the error is", err);
            }
        };
        if (traineeId) {
            fetchClassCount();
        }
    }, [classCount, traineeId]);

    //Get subscription info for logged in user
    useEffect(() => {
        const fetchSubscriptionInfo = async () => {
            try {
                const response = await Axios.get(`${API_URL}/lukatme/subscription/${traineeId}`
                );
                const data = response.data;
                setSubscriptionInfo(data);
                console.log("Data", data);
            } catch (err) {
                console.log("the error is", err)
            }
        };

        if (traineeId) {
            fetchSubscriptionInfo();
        }
    }, [traineeId]);

    //Update class attended based on the ClassCount for logged in user
    useEffect(() => {
        const updateClassAttended = async () => {
            const data = await Axios.put(`${API_URL}/lukatme/subscription`, { trainee_id: traineeId, class_attended: classCount })
                .then((data) => {
                }
                ).catch((err) => {
                    console.log("the error is ", err)
                })
        };
        if (traineeId && classCount !== null) {
            updateClassAttended();
        }
    }, [traineeId, classCount]);


    useEffect(() => {
        const updateIsActive = async () => {
            const data = await Axios.put(`${API_URL}/lukatme/updateisactive`, { trainee_id: traineeId })
                .then((data) => {
                    console.log("update is active", data)
                }
                ).catch((err) => {
                    console.log("the error is ", err)
                })
        };
        if (traineeId) {
            updateIsActive();
        }
    }, [traineeId]);

    const isToday = (subscriptionDate) => {
        subscriptionDate = new Date(subscriptionDate);
        const today = new Date();
        return today > subscriptionDate
    }

    return (
        <>
            {isSmallScreen ? (
                <div>
                    <IconButton aria-describedby={id} onClick={handleClick}>
                        <InfoIcon style={{ color: '#c42f62' }} />
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >

                        {subscriptionInfo && subscriptionInfo.length > 0 ? (
                            subscriptionInfo.map((item) => (
                                <ThemeProvider theme={GlobalStyleOverrides()}>
                                    <div className="package-badge">
                                        <Typography key={item.package_id} className="package-name">
                                            {item.enrolledClasses && item.enrolledClasses.length > 0 ? (
                                                item.enrolledClasses.map((classs, index) => (
                                                    index === 0 && (
                                                        (classs.attendance && classs.attendance.length >= item.class_limit) || isToday(item.end_date)
                                                            ? "انتهى الاشتراك"
                                                            : item.package_name
                                                    )
                                                ))
                                            ) : (
                                                `${item.package_name}${item.child_info?.[0]?.child_name ? ' - ' + item.child_info?.[0]?.child_name : ''}`
                                            )}

                                        </Typography>
                                    </div>
                                </ThemeProvider>
                            ))
                        ) : (
                            <div className="package-badge">
                                <Typography className="package-name">غير مشترك</Typography>
                            </div>
                        )}
                    </Popover>
                </div>
            ) : (
                // Large screen content
                subscriptionInfo && subscriptionInfo.length > 0 ? (
                    subscriptionInfo.map((item) => (
                        <ThemeProvider theme={GlobalStyleOverrides()}>
                            <div className="package-badge">
                                <Typography key={item.package_id} className="package-name">
                                    {item.enrolledClasses && item.enrolledClasses.length > 0 ? (
                                        item.enrolledClasses.map((classs, index) => (
                                            index === 0 && (
                                                (classs.attendance && classs.attendance.length >= item.class_limit) || isToday(item.end_date)
                                                    ? "انتهى الاشتراك"
                                                    : item.package_name
                                            )
                                        ))
                                    ) : (
                                        `${item.package_name}${item.child_info?.[0]?.child_name ? ' - ' + item.child_info?.[0]?.child_name : ''}`
                                    )}
                                </Typography>
                            </div>
                        </ThemeProvider>
                    ))
                ) : (
                    <div className="package-badge">
                        <Typography className="package-name">غير مشترك</Typography>
                    </div>
                )
            )}
        </>
    );
}
export default PackageBadge