import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import GlobalStyleOverrides from '../../../GlobalStyle';
import moment from 'moment-timezone';
function createData(id, name, amount, payment_date) {
  return {
    id,
    name,
    amount,
    payment_date,
  };
}

const headCells = [
  { id: 'done', numeric: true, disablePadding: false, label: 'تم الاستلام', },
  { id: 'payment_date', numeric: true, disablePadding: false, label: 'التاريخ', },
  { id: 'amount', numeric: true, disablePadding: false, label: 'المبلغ', },
  { id: 'name', numeric: false, disablePadding: false, label: 'الإسم', },
];
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <ThemeProvider theme={GlobalStyleOverrides()} >
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
            align='right'
          >
            تأكيد الدفع كاش
          </Typography>
        )}
      </Toolbar>
    </ThemeProvider>
  );
}

function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [payment, setPayment] = useState([]);
  const [done, setDone] = useState(false)
  const API_URL = process.env.REACT_APP_API_URL;
  // We'll first add a new state to store the sorted data
  const [sortedPayment, setSortedPayment] = React.useState([]);

  React.useEffect(() => {
    // Implement a sort function
    const comparator = (a, b) => {
      // Check if the properties exist on both objects
      if (a[orderBy] === undefined || b[orderBy] === undefined) return 0;

      if (orderBy === 'amount') {
        // Numeric sorting
        return order === 'asc'
          ? a[orderBy] - b[orderBy]
          : b[orderBy] - a[orderBy];
      } else {
        // String sorting
        return order === 'asc'
          ? a[orderBy].localeCompare(b[orderBy])
          : b[orderBy].localeCompare(a[orderBy]);
      }
    };

    // Sort the payment data and store it in sortedPayment state
    setSortedPayment([...payment].sort(comparator));
  }, [payment, order, orderBy]);  // Add order and orderBy as dependencies


  const handleClick = async (userId) => {
    const status = 'completed'

    console.log('in button');
    try {
      const pay = await Axios.put(`${API_URL}/lukatme/payment/updatestatus`, { userId, status });
      const response = pay.data
      console.log(status, userId);
      console.log('response', response);
      console.log('payment', payment);
      // Get the subscription IDs from the response and store them in an array
      const subscriptionIds = response.subscriptions.map((subscription) => subscription);
      console.log("subscriptionIds", subscriptionIds)
      setDone(true)

      const data = await Axios.put(`${API_URL}/lukatme/update_informations`, { userId: userId, subscriptions: subscriptionIds, status: response.status, paymentId: response._id });
      console.log("userid", data);
    } catch (err) {
      console.log("The error is", err);
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(`${API_URL}/lukatme/payment/all`);
        const data = response.data;
        console.log('res', response);
        // const formattedData = data.map((item) => {
        //   return createData(item.user._id, item.user.name, item.amount);
        // });
        const formattedData = data
          .filter(item => item.user !== null && item.user !== undefined)
          .map((item) => {
            return createData(item.user._id, item.user.name, item.amount, moment(item.paymentDate).format('D MMMM YYYY'));
          });
        console.log(formattedData);
        setPayment(formattedData)
        //   setPayment(data);
      } catch (err) {
        console.log("the error is", err)
      }
    };
    fetchData();
  }, [done]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - payment.length) : 0;


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={payment.length}
            />
            <TableBody>
              {sortedPayment
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      // key={row.id}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell align="right">
                        {/* <Button 
                             type={BUTTON_TYPES.PRIMARY} 
                              // className="payment-button" 
                              // onClick={handleClick(row.id)}
                              onClick={() => {handleClick(row.id)}} 
                              BtnTxt='تم الاستلام ' 
                              className='link btn'
                            >
                              تم الاستلام
                            </Button> */}
                        <button
                          className="payment-button"
                          onClick={() => handleClick(row.id)}

                          style={{
                            color: 'white',
                            backgroundColor: '#EA358C',
                            border: 'none',
                            padding: '8px 16px',
                            textAlign: 'center',
                            textDecoration: 'none',
                            display: 'inline-block',
                            fontSize: '16px',
                            margin: '4px 2px',
                            cursor: 'pointer',
                            transitionDuration: '0.4s',
                            borderRadius: '3px',
                          }}
                        >
                          تم الاستلام
                        </button>
                      </TableCell>
                      <TableCell align="right">{row.payment_date}</TableCell>
                      <TableCell align="right">{row.amount}</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={payment.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
