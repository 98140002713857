import { useState, useContext, createContext, useEffect } from "react";

const AuthContext = createContext();


const AuthProvider = ({ children }) => {

  const [auth, setAuth] = useState(() => {
    const storedAuth = localStorage.getItem("auth");
    return storedAuth ? JSON.parse(storedAuth) : { user: null, token: "", role: "" };
  });



  useEffect(() => {
    // Save the auth state to localStorage whenever it changes
    localStorage.setItem('auth', JSON.stringify(auth));
  }, [auth]);



  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};
// custom hook
const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider, AuthContext };
