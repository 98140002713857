import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TrainerClassCard from './TrainerClassCard';
import { Button, Typography, Grid, IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import Axios from 'axios';
import { useAuth } from '../../../../context/auth'
import '../../../../styles/userStyles/Trainee.css';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(2)
    },
    month: {
        marginRight: theme.spacing(1)
    },
    dayButton: {
        width: '70%',
        height: '70%',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        boxShadow: 'none',
        '&:focus': {
            outline: 'none', // Add this line to remove focus outline
        },
    },
    selectedDayButton: {
        color: '#F09FD2',
        border: 'none',
        boxShadow: 'none',
        '&:focus': {
            outline: 'none', // Add this line to remove focus outline
        },
    },
    dayButtonContent: {
        fontFamily: "'IBM Plex Sans', sans-serif",
        fontWeight: '100',
        fontSize: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    classInfo: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    previousButton: {
        marginRight: theme.spacing(1)
    },
    nextButton: {
        marginLeft: theme.spacing(1)
    },
    classIndicator: {
        fontSize: '1.5em',
        color: '#B10D33',
        textShadow: '0 0 10px #F09FD2',
    },
    dayIndicator: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));


function ClassCalender() {
    const classes = useStyles();
    const days = ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'];
    const [Classes, setClasses] = useState([]);
    const [selectedDay, setSelectedDay] = useState('');
    const [startOfWeek, setStartOfWeek] = useState(null);
    const [endOfWeek, setEndOfWeek] = useState(null);
    const [auth, setAuth] = useAuth();
    const trainerId = auth?.user?._id
    let class_date = '';

    useEffect(() => {

        const fetchData = async () => {
            console.log('trainer: ', trainerId);
            try {
                const response = await Axios.get(`${process.env.REACT_APP_API_URL}/lukatme/class/${trainerId}`);
                const data = response.data;
                console.log('Response:', data);
                setClasses(data.classes);
            } catch (err) {
                console.log("The error is", err);
            }
        };
        fetchData();

        // Get the current date and time
        const now = new Date();

        // Get the start of the current week (Sunday)
        const start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());

        // Get the end of the current week (Saturday)
        const end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 6);

        setStartOfWeek(start);
        setEndOfWeek(end);
    }, []);

    const handleDayClick = (day) => {
        if (day === selectedDay) {
            setSelectedDay(null);
        } else {
            setSelectedDay(day);
        }
    };

    //Handle next and prev days display 

    const handlePrevClick = () => {
        const start = new Date(startOfWeek);
        start.setDate(start.getDate() - 7);
        const end = new Date(startOfWeek);
        end.setDate(end.getDate() - 1);
        setStartOfWeek(start);
        setEndOfWeek(end);
        setSelectedDay(null);
    };

    const handleNextClick = () => {
        const start = new Date(endOfWeek);
        start.setDate(start.getDate() + 1);
        const end = new Date(start);
        end.setDate(end.getDate() + 6);
        setStartOfWeek(start);
        setEndOfWeek(end);
        setSelectedDay(null);
    };

    // Check if a given date has any classes
    const hasClassesOnDate = (date) => {
        return Classes.some(item => {
            const class_date = new Date(item.date.start_date).toLocaleDateString();
            return class_date === date.toLocaleDateString();
        });
    }


    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div className={classes.root}>
                <div className={classes.header}>
                    <IconButton
                        className={classes.previousButton}
                        onClick={handlePrevClick}
                        disabled={startOfWeek <= new Date()}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="body6" className={classes.month}>
                        {startOfWeek && endOfWeek &&
                            startOfWeek.toLocaleString('default', { month: 'long', year: 'numeric' })}
                    </Typography>
                    <IconButton
                        className={classes.nextButton}
                        onClick={handleNextClick}
                    >
                        <ArrowForward />
                    </IconButton>
                </div>

                <Grid container spacing={2}>
                    {days.map((day, index) => {

                        // Calculate the date for this day of the week
                        const date = new Date(startOfWeek);
                        date.setDate(date.getDate() + index);


                        // Check if this day has any classes
                        const hasClasses = hasClassesOnDate(date);


                        // Check if this day is selected
                        const isSelected = selectedDay && date.toDateString() === selectedDay.toDateString();
                        return (
                            <Grid item xs key={day} className={classes.dayIndicator}>
                                <Button
                                    className={`${classes.dayButton} ${isSelected ? classes.selectedDayButton : ''}`}
                                    variant={isSelected ? 'contained' : 'outlined'}
                                    color={isSelected ? 'default' : 'default'}
                                    onClick={() => handleDayClick(date)}
                                >
                                    <div className={classes.dayButtonContent} >
                                        <Typography >{day}</Typography>
                                        <Typography variant="h5">{date.getDate()}</Typography>

                                    </div>

                                </Button>
                                {hasClasses && <span className={classes.classIndicator} onClick={() => handleDayClick(date)}>...</span>}
                            </Grid>
                        );
                    })}
                </Grid>

                {selectedDay &&
                    <div className={classes.classInfo}>
                        {Classes.map(item => {
                            class_date = new Date(item.date.start_date).toLocaleDateString()
                            if (class_date == selectedDay.toLocaleDateString())
                                return <Typography variant="subtitle1">
                                    <div className='traineeclass--classes'>
                                        <TrainerClassCard key={item._id} class_id={item._id} class_name={item.class_name} no_of_seats={item.details.no_of_seats} studio_number={item.details.studio_number} trainer={item.trainer_info.trainer_name}
                                            class_duration={item.details.class_duration} age_group={item.ageGroup} days={item.date.days} date={item.date.start_date} time={item.date.time} number_of_trainees={item.enrolled_trainees.length}>
                                        </TrainerClassCard>
                                    </div>
                                </Typography>
                        })}
                    </div>
                }
            </div>
        </ThemeProvider >
    );
}

export default ClassCalender;