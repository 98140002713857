
import Grid from '@material-ui/core/Grid'
import { TextField } from '@material-ui/core'
import { Typography } from '@mui/material'
import GlobalStyleOverrides from '../../../GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { HashLink as Link } from 'react-router-hash-link';
import { useAuth } from '../../../../context/auth';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';

function TrainerSetting() {
    const [auth, setAuth] = useAuth()
    const [address, setAddress] = useState('');
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // console.log("hhhhhhh", auth)
        const { _id, email, name, address, phone_number, weight, height = '' } = auth?.user;

        setAddress(address)
    }, [auth?.user])


    const validationSchema = Yup.object({
        name: Yup.string(),
        email: Yup.string().email('إيميل غير صحيح'),
        phone_number: Yup.string().matches(/^05[0-9]{8}$/, 'ادخلي رقم جوال صحيح يبدأ ب05'),
        height: Yup.number().max(300, 'يرجى إدخال طولك بالسنتيمتر بشكل صحيح').min(23, 'يرجى إدخال طولك بالسنتيمتر بشكل صحيح').typeError(' يرجى إدخال طولك بالسنتيمتر بشكل صحيح'),
        weight: Yup.number().max(900, 'يرجى إدخال الوزن بالكيلو جرام بشكل صحيح').typeError('يرجى إدخال الوزن بالكيلو جرام بشكل صحيح'),
    });

    const formik = useFormik(
        {
            initialValues: {
                name: auth?.user?.name,
                email: auth?.user?.email,
                phone_number: auth?.user?.phone_number,
                height: auth?.user?.height || '',
                weight: auth?.user?.weight || '',
            },

            validationSchema: validationSchema,
            onSubmit: async (values) => {
                try {
                    const { email, name, phone_number, height, weight } = values;
                    const { data } = await Axios.put(`${API_URL}/lukatme/update-trainer`, {
                        email,
                        name,
                        address: address || '',
                        height: height || '',
                        phone_number,
                        weight,
                        userID: auth.user._id,
                    }, {
                        headers: {
                            'Authorization': `Bearer ${auth.token}`, // Assuming token is stored in localStorage
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                        }
                    }
                    )
                    if (data?.error) {
                        console.log(data?.error)
                    } else {
                        setAuth({ ...auth, user: data?.updateUser })
                        // let ls = localStorage.getItem("auth");
                        // ls = JSON.parse(ls);
                        // ls.user = data.updateUser;
                        // localStorage.setItem("auth", JSON.stringify(ls))
                        toast.success('تم تعديل الاعدادات بنجاح');
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        }
    );

    let token = localStorage.getItem('token')

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <ToastContainer position="top-center" hideProgressBar={true} autoClose={3000} closeOnClick />
            <div>
                <Typography sx={{ pt: 2 }} variant="body2" textAlign={'right'}>
                    الإعدادات
                </Typography>
                <Box component="form" sx={{ pt: 4 }}   >
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6} direction="column" >
                            <InputLabel id="weight">kg الوزن </InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="weight"
                                label=" الوزن"
                                name="weight"
                                autoComplete="weight"
                                dir='rtl'
                                type='text'
                                onBlur={formik.handleBlur}
                                value={formik.values.weight}
                                onChange={formik.handleChange}
                                error={formik.touched.weight && Boolean(formik.errors.weight)}
                                helperText={formik.touched.weight && formik.errors.weight}
                                inputProps={{
                                    maxLength: 3,
                                    pattern: "[0-9]*", // Only allow numbers
                                }}
                            />

                            <InputLabel id="height">  cm الطول</InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="height"
                                label=" الطول"
                                name="height"
                                type='text'
                                autoComplete="height"
                                dir='rtl'
                                onBlur={formik.handleBlur}
                                value={formik.values.height}
                                onChange={formik.handleChange}
                                error={formik.touched.height && Boolean(formik.errors.height)}
                                helperText={formik.touched.height && formik.errors.height}
                                inputProps={{
                                    maxLength: 3,
                                    pattern: "[0-9]*", // Only allow numbers
                                }}
                            />
                            <InputLabel id="address"> العنوان</InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="height"
                                label=" العنوان"
                                name="address"
                                autoComplete="address"
                                dir='rtl'
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                            <Link to={`./changepassword-trainer/${auth.user._id || auth.user.userId}`}>تغيير كلمة المرور</Link>

                        </Grid>
                        <Grid item xs={12} md={6} direction="column" >
                            <InputLabel id="name">اسم المدربة </InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="اسم المدربة"
                                name="name"
                                autoComplete="name"
                                dir='rtl'
                                value={formik.values.name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <InputLabel id="email">البريد الالكتروني</InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="البريد الالكتروني"
                                name="email"
                                autoComplete="email"
                                dir='rtl'
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />

                            <InputLabel id="phone_number"> رقم الجوال</InputLabel>
                            <TextField
                                margin="normal"
                                variant="outlined"
                                required
                                fullWidth
                                id="phone_number"
                                label="رقم الجوال "
                                name="phone_number"
                                autoComplete="phone_number"
                                dir='rtl'
                                value={formik.values.phone_number}
                                type='text'
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                helperText={formik.touched.phone_number && formik.errors.phone_number}
                                inputProps={{
                                    maxLength: 10,
                                    pattern: "^05[0-9]{8}$", // Only allow numbers
                                }}
                            />

                        </Grid>
                    </Grid>

                    <Button
                        type="submit"
                        onClick={formik.handleSubmit}
                        variant="contained"
                        sx={{ mt: 3, mb: 2, width: '100%' }} // Added sx={{ width: '100%' }}
                    >
                        حفظ
                    </Button>
                </Box>

            </div>
        </ThemeProvider >
    )
}

export default TrainerSetting