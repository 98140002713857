import { useState } from 'react'
import Navbar from './Navbar'
import { ScrollToTop } from './ScrollToTop';
import Footer from './Footer'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import Container from '@material-ui/core/Container';
import '../styles/Contact.css';
import GlobalStyleOverrides from './GlobalStyle';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [alert, setAlert] = useState({ open: false, message: '', severity: '' });



    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/lukatme/contact`, { name, email, message })
            .then(res => {
                console.log(res);
                setAlert({ open: true, message: 'تم الإرسال بنجاح', severity: 'success' });
                setName('');
                setEmail('');
                setMessage('');
            })
            .catch(err => {
                console.log(err);
                setAlert({ open: true, message: 'مانقدر نرسل الرسالة دحين. حاولي مرة ثانية', severity: 'info' });
            });
    };

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <ScrollToTop />
            <Navbar />
            <section className='contact'>
                <div className='contact--description contact--description--background'>
                    <Container dir="rtl">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>

                                <Typography variant="h5" align="right">تواصلي معنا</Typography>
                                <Typography variant="h2" align="right">كيف ممكن نخدمك ؟</Typography>
                                <Typography align="right">
                                    احنا نحاول قد مانقدر نرد عليك أسرع شي من 6 صباحًا - 3 مساءً من الأحد إلى الجمعة
                                </Typography>
                                <Typography align="right">
                                    يمكن نتأخر بالرد في غير ساعات العمل وعطلة نهاية الأسبوع
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Paper style={{ backgroundColor: "white" }} elevation={3} className={"contact--form"}>
                                    <form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
                                        {alert.open &&
                                            <Stack sx={{ width: '100%' }} spacing={2}>
                                                <Alert variant="outlined" severity={alert.severity} color='primary'>
                                                    {alert.message}
                                                </Alert>
                                            </Stack>
                                        }
                                        <TextField label="اسمك الكامل *" fullWidth required value={name} onChange={(e) => setName(e.target.value)} margin="normal" />
                                        <TextField label="بريدك الالكتروني *" type="email" fullWidth required value={email} onChange={(e) => setEmail(e.target.value)} margin="normal" />
                                        <TextField label="كيف نقدر نساعدك ؟" multiline rows={4} fullWidth value={message} onChange={(e) => setMessage(e.target.value)} margin="normal" />
                                        <Button type="submit" variant="contained" color="primary" margin="normal">ارسال</Button>
                                    </form>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <div className="contact--location" style={{ textAlign: 'right' }}>

                                    <Paper >
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3705.518658537035!2d39.0807433!3d21.7601788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c16387a8d5fa25%3A0x162a795fd9b86cc5!2sLUKATME%20STUDIO!5e0!3m2!1sar!2ssa!4v1687265653827!5m2!1sar!2ssa" frameborder="0" aria-hidden="false" allowfullscreen="" loading="lazy" tabindex="0"></iframe>
                                    </Paper>


                                    <ul className="list-unstyled">
                                        <li>
                                            <Typography variant="body1">
                                                <a href="https://maps.app.goo.gl/MZu9Gof9RSpvx2tL6" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                    <i className="fas fa-map-marker-alt pe-2"></i> &nbsp;  حي أبحر الشمالية، شمال مدينة جدة، ٢٣٠٠١٢
                                                </a>
                                            </Typography>
                                        </li>
                                        <li>

                                            <Typography variant="body1">
                                                <a href="tel:+966537260396" style={{ color: 'inherit', textDecoration: 'none' }}>
                                                    <i className="fas fa-phone pe-2"></i>&nbsp; &lrm; +966-53-726-0396
                                                </a>
                                            </Typography>

                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </section>
            <Footer />
        </ThemeProvider>
    )
}

export default Contact
