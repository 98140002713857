
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from './GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import '../styles/userStyles/Trainee.css'

function TermsAndConditions() {

    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div className='terms-conditions' dir='rtl'>
                <Typography sx={{ pt: 5 }} variant="body2" color="" textAlign={'center'}>
                    السياسية والخصوصية
                </Typography>

                <Typography sx={{ pt: 5 }} variant="body1" color="primary.main" textAlign={'right'}>
                    القوانين و الأنظمة
                </Typography>

                <Typography variant="body1" color="secondary.other" textAlign={'right'}>
                    <ul className='privacy--policy--list'>
                        <li>الإلتزام بموعد الحصص</li>
                        <li>الاستديو غير مسؤول عن فقدان الأغراض الشحصية</li>
                        <li>دفع الإشتراك يكون قبل بداية اول حصة و تبدأ مدة الإشتراك من بداية أول حصة</li>
                        <li>ممنوع التصوير وقت الحصص و يحق لإدارة فتح الجوال في حال إشتباه بتصوير احد المشتركين</li>
                        <li>يجب ارتداء اللبس المناسب لنوع الحصة و الأحذية المناسبة</li>
                        <li>لا ينبغي استخدام الخرانة أكثر من يوم واحد</li>
                        <li>المبلغ المدفوع لا يسترد ولا يستبدل</li>

                        <li>يمنع اصطحاب الأطفال إذا لم يكن لديهم حصص تدريبية</li>
                        <li>فى حال اصطحاب الأطفال للحصص التدريبية
                            الخاصة بهم فإن مسؤولية الطفل وسلامته وراحته
                            بشكل تام تكون على عاتق المرافقة المشتركة.
                        </li>
                        <li>يجب على المشتركات إيقاف السيارات في الأماكن المخصصة فقط</li>
                        <li>
                            لا يتحمل


                            <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                                &nbsp; استديو لوكات مي&nbsp;
                            </Typography>

                            أي مسؤولية عن أي
                            ضرر أو سرقة لسيارات المشتركات</li>

                        <li>يحق لإدارة استديو لوكات مي إلغاء اشتراك أي
                            مشتركة دون إخطار أو على الفور إذا قامت بخرق الانظمة و القوانين</li>
                        <li>يجب المحافظة على نظام ونظافة صالات ومرافق ومعدات     <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                            &nbsp; استديو لوكات مي&nbsp;
                        </Typography></li>
                        <li>
                            لا يسمح بأي حال من الأحوال التعدي لفظيا أو
                            جسديا على مشتركات     <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                                &nbsp; استديو لوكات مي&nbsp;
                            </Typography> أو طاقم
                            العمل من موظفات ومدربات وعاملات.</li>

                        <li>
                            لا يسمح بأخذ أي معدات من     <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                                &nbsp; استديو لوكات مي&nbsp;
                            </Typography></li>
                        <li>
                            يحق لإدارة     <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                                &nbsp; استديو لوكات مي&nbsp;
                            </Typography> تعديل أوقات العمل.
                        </li>
                        <li>
                            يحق لإدارة     <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                                &nbsp; استديو لوكات مي&nbsp;
                            </Typography> تأجيل او إلغاء الحصص التدريبية.
                        </li>
                        <li>
                            يحق لإدارة     <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                                &nbsp; استديو لوكات مي&nbsp;
                            </Typography> تغيير رسوم الاشتراك بدون سابق انذار.
                        </li>
                        <li>
                            الاشتراك شخصي وغير قابل للاستبدال أو
                            الاسترجاع.
                        </li>
                        <li>
                            لايحق للمشتركة نقل الاشتراك أو منحه أو بيعه
                            لشخص آخر إلا بعد موافقة إدارة النادي رسميا
                            وفي حال الموافقة يتم دفع رسوم نقل اشتراك
                        </li>
                        <li>
                            لن يتم تعويض المشتركة في حال عدم الحضور
                        </li>
                        <li>
                            في حال عدم الرغبة أو القدرة على الحضور يجب إلغاء حجز الحصة التدريبية قبل الحصة بساعتين
                            على الأقل وإلا سوف تحسب من اشتراك المشتركة
                        </li>
                        <li>
                            بجب على المشتركة دخول الحصة التدريبية في
                            أول عشرة دقائق من الحصة و في حال عدم دخولها
                            في أول عشرة دقائق
                            سوف  تعتبر المشتركة (غير حاضرة)
                            و سوف يخصم من عدد حصصها
                        </li>
                        <li>
                            يجب اتباع قوانين السلامة عند استخدام المعدات
                            الرباضية و استخدام المساند الأرضية في رياضات
                            السيرك سواءً في الحصة الجماعية أو التدريب
                            الشخصي أو التدريب من غير مدرب
                        </li>
                        <li>
                            <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                                &nbsp; استديو لوكات مي&nbsp;
                            </Typography> لا يتحمل أي مسؤولية أو مسائلة
                            قانونية ناتجة عن إصابة المشتركين والمشتركات
                            بسبب سوء استخدام المعدات الرياضية أو عدم
                            اتباع التعليمات أو عدم اتباع قوانين السلامة أو التدريب من غير مدرب
                        </li>
                        <li>
                            يجب اتباع إجراءات الطوارئ في حالات انقطاع
                            الكهرباء أو الحريق أو السيول وغير ذلك مما يتطلب التعامل معه بمسؤولية وحسن التصرف مع ضرورة
                            مراعاة تعليمات وأوامر إدارة     <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                                &nbsp; استديو لوكات مي&nbsp;
                            </Typography>
                        </li>

                    </ul>
                </Typography>


                <Typography sx={{ pt: 5 }} variant="body1" color="primary.main" textAlign={'right'}>
                    الخصوصية وبيان سريّة المعلومات
                </Typography>
                <Typography variant="body1" color="secondary.other" textAlign={'right'}>
                    <p>نأخذ على محمل الجد خصوصية زوار موقعنا الإلكتروني وأمن أية معلومات شخصية قد يقدمها زوار الموقع. ونؤمن بحقك في معرفة نوع المعلومات التي يجمعها الموقع، وكيف يتم حمايتها واستخدامها، وتحت أية ظروف يتم الكشف عنها. ولهذا أعددنا سياسة الخصوصية هذه ونشرناها على موقعنا لكي تساعدكم في فهم طبيعة البيانات التي نقوم بتجميعها عنكم عند زيارتكم لموقعنا على شبكة الإنترنت وكيفية تعاملنا مع هذه البيانات.</p>
                </Typography>
                <Typography sx={{ pt: 5 }} variant="body1" color="primary.main" textAlign={'right'}>
                    تصفح الموقع
                </Typography>
                <Typography variant="body1" color="secondary.other" textAlign={'right'}>
                    <p>لا يهدف هذا الموقع إلى جمع بياناتك الشخصية أثناء تصفحك للموقع، وإنما سيتم فقط استخدام البيانات المقدمة من قبلك بمعرفتك ومحض إرادتك.</p>
                </Typography>
                <Typography sx={{ pt: 5 }} variant="body1" color="primary.main" textAlign={'right'}>
                    المعلومات التي يتم جمعها وكيفية الحصول عليها واستخدامها
                </Typography>

                <Typography sx={{ pt: 5 }} variant="body1" color="#595959" textAlign={'right'}>
                    المعلومات الشخصية
                </Typography>

                <Typography variant="body1" color="secondary.other" textAlign={'right'}>
                    <p>تنطبق هذه السياسة على جميع المعلومات التي يتم جمعها أو المقدمة على موقعنا الإلكتروني. يمكن لزوار الموقع تصفح والحصول على المعلومات التي يبحثون عنها والاشتراك في مختلف الأدوات والبرامج التي يقدمها الموقع وتلقي الرسائل الإلكترونية وتحميل المواد بشكل مجاني. خلال هذه العمليات، لا نقوم بجمع سوى المعلومات الشخصية المقدمة طوعاً من قبل المتصفح لهذا الموقع، وقد يشمل ذلك، ولكن ليس على سبيل الحصر، الاسم وعنوان البريد الإلكتروني، ومعلومات الاتصال، وأية بيانات أو تفاصيل ديموغرافية أخرى. نحن لا نشارك المعلومات الشخصية التي تقدمها من خلال موقعنا على شبكة الإنترنت مع أية مؤسسة أو شركة أو حكومة أو وكالة حكومية أو أي نوع من المنظمات الأخرى. ونلتزم في الكشف عن المعلومات الشخصية في حالات استثنائية حسب قانون البلد الذي نعمل فيه. نستخدم المعلومات التي يقدمها متصفح الموقع طوعاً لتقديم عدة خدمات مثل النشرات الإخبارية عن طريق البريد الإلكتروني، ودعوات للندوات والمؤتمرات، أو تنبيهات حول برامج أو أنشطة أو مواد ننشرها على موقعنا.
                        لن يتم استخدام معلوماتك لأية أغراض تجارية أبداً، ولن نقوم ببيع، المتاجرة، تأجير، أو إفشاء أية من معلوماتك لمصلحة أي طرف ثالث خارج هذا الموقع، أو المواقع التابعة له، وسيتم الكشف عن المعلومات فقط في حالة صدور أمر بذلك من قبل سلطة قضائية أو تنظيمية في البلد الذي نعمل فيه.</p>
                </Typography>
                <Typography sx={{ pt: 5 }} variant="body1" color="#595959" textAlign={'right'}>
                    معلومات استخدام الموقع
                </Typography>
                <Typography variant="body1" color="secondary.other" textAlign={'right'}>
                    <p>عندما يقوم زائر الموقع بتصفح مواده أو تحميلها قد يتم جمع معلومات عامة عن هذه الزيارة. على سبيل المثال، قد يسجل المخدم (server) عنوان بروتوكول الإنترنت IP لجهاز الكمبيوتر الخاص بك، وقد يسجل معلومات عن المتصفح الذي تستخدمه ونظام التشغيل، وتاريخ ووقت الزيارة، وعناوين الإنترنت والصفحات التي يتم تصفحها أثناء الزيارة. وإذا كنت تبحث في الموقع، قد يتم تسجيل طلبات البحث التي تقوم بها. نستخدم هذه المعلومات لقياس عدد زوار الموقع إلى أقسامه المختلفة، ونستفيد منها في تشخيص أخطاء الموقع وتصحيحها، وإجراء الأبحاث بهدف تحسين خدمة المعلومات، وميزات وسهولة الاستخدام للموقع.
                        {/* كما نستخدم خدمة غوغل أناليتكس (Google Analytics) لمساعدتنا في فهم كيفية تفاعل زوار موقعنا مع المعلومات والمواد التي ننشرها. 
                        نستخدم غوغل أناليتكس فقط للحصول على المعلومات غير الشخصية لزوار الموقع، بما في ذلك السن والجنس والمنطقة الجغرافية التي يأتون منها. تساعدنا هذه المعلومات على فهم احتياجات زوار الموقع وتحسينه لتلبية متطلباتهم بشكل أفضل حسب اهتماماتهم.
                         */}
                    </p>
                </Typography>
                {/* <Typography sx={{ pt: 5 }} variant="body1" color="primary.main" textAlign={'right'}>
                    الكوكيز Cookies
                </Typography>
                <Typography variant="body1" color="secondary.other" textAlign={'right'}>
                    <p>يستخدم الموقع الكوكيز على شبكة الإنترنت، وهي آلية تسمح للموقع ومتصفح الويب "تذكر" معلومات جلسات تصفح الموقع التي يقوم بها الزائر. الكوكيز مفيدة لأنها يمكن أن تحسن تجربة المستخدم وتفاعله مع الموقع بسهولة. يستخدم موقعنا الكوكيز لتحسين تجربة المستخدم في إطار جلسة عمل مستعرض واحدة أو بين زيارات الموقع. قد تحتوي الكوكيز على ملفات تعريفية بحيث لا تحتاج للدخول في كل مرة تقوم فيها بزيارة صفحة جديدة، أو عند العودة إلى الموقع من نفس الكمبيوتر في وقت لاحق. لا نستخدم الكوكيز لأغراض التسويق التجاري. تذكر، إذا كنت تستخدم جهاز كمبيوتر عام، ولم تقم بتسجيل الخروج من الموقع بعد الانتهاء من جلسة العمل الخاصة بك، شخص آخر قد يكون قادراً على الوصول إلى حسابك.
                        الروابط للمواقع الأخرى على شبكة الإنترنت
                        قد يشتمل موقعنا على روابط لمواقع أخرى على شبكة الإنترنت ولا نعتبر مسؤولين عن أساليب تجميع البيانات من قبل تلك المواقع، يمكنك الاطلاع على سياسات الخصوصية والمحتويات الخاصة بتلك المواقع التي يتم الدخول إليها.</p>
                </Typography> */}
                <Typography sx={{ pt: 5 }} variant="body1" color="primary.main" textAlign={'right'}>
                    التزامنا بأمن المعلومات
                </Typography>
                <Typography variant="body1" color="secondary.other" textAlign={'right'}>
                    <p>قمنا بوضع إجراءات إلكترونية وإدارية مناسبة لحماية وتأمين المعلومات التي يتم جمعها على الإنترنت وذلك لمنع الدخول غير المصرح به، والحفاظ على دقة البيانات وضمان الاستخدام الصحيح للمعلومات.</p>
                </Typography>

                <Typography sx={{ pt: 5 }} variant="body1" color="primary.main" textAlign={'right'}>
                    التعديلات على سياسة سرية وخصوصية المعلومات
                </Typography>

                <Typography variant="body1" color="secondary.other" textAlign={'right'}>
                    <p>

                        نحتفظ بحق إجراء تغييرات على هذه السياسة إن لزم الأمر، وعند حدوث ذلك سيتم إعلام زوار الموقع بذلك وإعلان ذلك على موقعنا في مكان يسهل الوصول إليه على الموقع.</p>
                </Typography>
                <a href="./contactus" style={{ color: 'inherit', textDecoration: 'none' }}>
                    <Typography sx={{ pt: 5 }} variant="body1" color="primary.main" textAlign={'right'}>
                        الاتصال بنا
                    </Typography></a>
                <Typography variant="body1" color="secondary.other" textAlign={'right'}>
                    <p>إذا كان لديكم أسئلة أو استفسارات أخرى حول سياسات الخصوصية هذه، يرجى مراسلتنا على البريد الإلكتروني عن طريق النقر على "
                        <a href="./contactus" style={{ color: 'inherit', textDecoration: 'none' }}>
                            تواصلي معنا
                        </a>
                        " في الموقع.</p>
                </Typography>

                <Typography sx={{ pt: 5 }} variant="body2" color="" textAlign={'center'}>
                    اتعهد
                </Typography>

                <Typography variant="body1" color="secondary.other" textAlign={'right'}>
                    <ul className='privacy--policy--list'>
                        <li>ان اتحمل كامل المسؤولية عن سلوكياتي و اغراضي الشخصية داخل الاستديو</li>
                        <li>ان اتحمل كامل المسؤولية عن ابلاغ المدربة – الإدارة بوجود أي مرض او إصابة تمنع من ممارسة
                            الرياضة</li>
                        <li>ان اتحمل كامل المسؤولية في حالة عدم مناسبة التمارين للحالة الصحية او في حال عدم ابالغ المدربة
                            بذلك</li>
                        <li> كأن تصدر سلوكيات غير لائقة او إيذاء الآخرين والعبث بممتلكات الاستديو فسوف اتحمل أي قرار يصدر من إدارة الاستديو ولللإدارة الحق في اتخاذ الإجراء المناسب  (لاقدر الله)  في حال حدوث أي وضع مخالف للأنظمة   </li>
                        <li>بالإضافة إلى الشروط والأحكام الواردة أعلاه،
                            يجب على المشتركين والمشتركات اتباع التعليمات
                            والإشعارات المقدمة من طرف     <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                                &nbsp; استديو لوكات مي&nbsp;
                            </Typography>
                            سواء كانت مقدمة في نشرات خطية أو إشعارات
                            مكتوبة أو بصورة شفوية من موظفات     <Typography sx={{ pt: 5 }} component={'span'} variant="body1" color="primary.main" textAlign={'right'}>
                                &nbsp; استديو لوكات مي&nbsp;
                            </Typography>
                        </li>
                    </ul>
                </Typography>

            </div>
        </ThemeProvider >
    )
}

export default TermsAndConditions