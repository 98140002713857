import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from '../../../GlobalStyle';
import TraineeClassCard from './TraineeClassCard';
import { ThemeProvider } from '@mui/material/styles';
import '../../../../styles/userStyles/Trainee.css';
import Axios from 'axios';
import { useAuth } from '../../../../context/auth';

function TraineeDashboard() {

    const [subscription, setSubscription] = useState([])
    const [auth, setAuth] = useAuth()
    const API_URL = process.env.REACT_APP_API_URL;

    //Get the logged in user id to show their enrolled classes 
    const traineeId = auth?.user._id
    const token = auth?.token

    useEffect(() => {
        const fetchData = async () => {
            const data = await Axios.get(`${API_URL}/lukatme/subscription_class/${traineeId}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Assuming token is stored in localStorage
                        'Content-Type': 'application/json',
                        Accept: 'application/json',

                    },
                }).then((data) => {
                    setSubscription(data.data)
                    console.log("classes", data.data)


                }).catch((err) => {
                    console.log("the error is", err)
                })
        };
        fetchData();

    }, []);
    console.log("sub", subscription)



    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <div className='trainee--classes'>
                <Typography sx={{ pt: 5 }} variant="body2" color="" textAlign={'right'}>
                    الملف الشخصي
                </Typography>

                <Typography sx={{ pt: 5, pb: 5 }} variant="body4" color="" textAlign={'right'}>
                    تقدري تشيكي على ملفك الشخصي وعلى تقدمك و على الكلاسات المتوفرة للتسجيل
                </Typography>

                <Typography sx={{ pt: 5 }} variant="body2" color="" textAlign={'right'}>
                    الكلاسات المسجله
                </Typography>
                {
                    subscription && subscription.map((sub, index) => {
                        return (
                            <div>
                                {sub.class_limit == 0 ? (
                                    <div style={{ paddingTop: '30px' }}>
                                        <p> حضرتي {sub.enrolledClasses.filter(classInfo => classInfo.attendance).length} كلاس  <strong> {sub.package_name}</strong> من باكج </p>
                                    </div>) :
                                    <div style={{ paddingTop: '30px' }}>
                                        <p><strong> {sub.package_name}</strong></p>
                                        <p>متبقي لك {sub.class_limit - sub.enrolledClasses.filter(classInfo => classInfo.attendance).length} من {sub.class_limit} كلاس </p>
                                    </div>
                                }
                                {sub.enrolledClasses.map((classInfo) => {
                                    return (
                                        traineeId ?
                                            <div className='traineeclass--classes '>
                                                <TraineeClassCard type="dashboard" key={classInfo.class._id} class_id={classInfo.class._id} attendance={classInfo.attendance} class_name={classInfo.class.class_name} no_of_seats={classInfo.class.details.no_of_seats} studio_number={classInfo.class.details.studio_number}
                                                    class_duration={classInfo.class.details.class_duration} trainer={classInfo.class.trainer_info.trainer_name} date={classInfo.class.date.start_date} time={classInfo.class.date.time}
                                                    number_of_trainees={classInfo.class.enrolled_trainees.length} package_name={sub.package_name}
                                                >
                                                </TraineeClassCard>
                                            </div>
                                            : console.log('no class')
                                    )
                                })}
                            </div>
                        )
                    })
                }

            </div>
        </ThemeProvider >
    )
}

export default TraineeDashboard
