import React, { useEffect, useState } from 'react'
import '../styles/Register.css';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import GlobalStyleOverrides from './GlobalStyle';
import { ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FormControl } from '@mui/material';
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { useFormik } from 'formik';
import {
    Dialog, DialogTitle, DialogContent, DialogContentText,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as Yup from 'yup';

function Register() {
    const [address, setAddress] = useState('');
    const [registration_goal, setRegistration_goal] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('زودينا بإسمك الثنائي من فضلك'),
        email: Yup.string().email('إيميل غير صحيح').required('زودينا بإيميلك من فضلك'),
        pwd: Yup.string()
            .required('اكتبي كلمة سر من فضلك')
            .min(8, ' كلمة السر يجب أن تكون من 8 خانات على الأقل'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('pwd'), null], 'يجب أن تكون كلمة السر متطابقة')
            .required('الرجاء كتابة كلمة السر مرة آخرى'),
        national_id: Yup.string().required('زودينا برقم هويتك من فضلك')
            .matches(/^[0-9]{10}$/, ' يرجى كتابة رقم الهوية بشكل صحيح '),
        date_of_birth: Yup.date()
            .required('زودينا بتاريخ ميلادك')
            .max(new Date(), 'تاريخ ميلاد غير صحيح')
            .min('1900-01-01', 'تاريخ ميلاد غير صحيح')
        ,

        phone_number: Yup.string().required('زودينا برقم جوالك يبدأ ب05')
            .matches(/^05[0-9]{8}$/, 'رقم جوال غير صحيح'),

        emergency_name: Yup.string().required('زودينا بإسم قريب لك'),
        emergency_phone: Yup.string().required('زودينا برقم جوال يبدأ ب05')
            .matches(/^05[0-9]{8}$/, 'رقم جوال غير صحيح'),
        emergency_relation: Yup.string().required('وضحيلنا صلة القرابة'),

        is_health_issues: Yup.boolean(),
        health_issues: Yup.string().when('is_health_issues', {
            is: true,
            then: () => Yup.string().required('زودينا بمشاكلك الصحية'),
            otherwise: () => Yup.string().notRequired(),
        }),

        is_past_injuries: Yup.boolean(),
        past_injuries: Yup.string().when('is_past_injuries', {
            is: true,
            then: () => Yup.string().required('زودينا باصاباتك السابقة '),
            otherwise: () => Yup.string().notRequired(),
        }),


    });

    const formik = useFormik({
        initialValues: {
            name: '',
            date_of_birth: '',
            address: '',
            email: '',
            national_id: '',
            phone_number: '',
            pwd: '',
            confirmPassword: '',
            registration_goal: '',
            is_health_issues: false,
            health_issues: '',
            is_past_injuries: false,
            past_injuries: '',
            is_taking_meds: false,
            emergency_name: '',
            emergency_phone: '',
            emergency_relation: '',


        },

        validationSchema: validationSchema,
        onSubmit: async (e) => {
            try {
                console.log('before axios')
                const formData = {
                    national_id: e.national_id,
                    phone_number: e.phone_number,
                    address: e.address,
                    email: e.email,
                    password: e.pwd,
                    name: e.name,
                    date_of_birth: e.date_of_birth,
                    registration_goal: e.registration_goal,
                    is_health_issues: e.is_health_issues,
                    health_issues: e.health_issues,
                    is_past_injuries: e.is_past_injuries,
                    past_injuries: e.past_injuries,
                    is_taking_meds: e.is_taking_meds,
                    emergency_name: e.emergency_name,
                    emergency_phone: e.emergency_phone,
                    emergency_relation: e.emergency_relation,


                };
                const response = await Axios.post(`${API_URL}/lukatme/register`, formData)
                    .then((res) => {
                        setIsRegistered(true);
                        toast.success("اهلا وسهلا");
                        console.log('Register success')
                    })
            } catch (error) {
                if (error.response) {
                    let message = '';
                    console.log(error.response.status, error.response)
                    switch (error.response.status) {
                        case 404:
                            message = 'رقم الجوال مسجل من قبل';
                            break;
                        case 401:
                            message = 'الإيميل مسجل من قبل';
                            break;
                        case 405:
                            message = 'الإيميل أو رقم الجوال أو رقم الهوية مسحل من قبل، المستخدم مسجل من قبل';
                            break;
                        case 500:
                        case 201:
                            setIsRegistered(true);
                            break;
                        default:
                            message = 'خطأ في النظام ';
                    }
                    formik.setErrors({
                        errorLabel: message,
                    });
                    window.scrollTo(0, 0);  // scroll to top when there is an error
                } else {
                    console.error(error);
                }
            }
        }
    });


    const handleInputChange = (event) => {
        formik.handleChange(event);
        const { name, value } = event.target;
        if (name !== "pwd" && name !== "confirmPassword" && formik.initialValues.hasOwnProperty(name)) {
            localStorage.setItem(name, value);
        }
    };
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            Object.entries(formik.values).forEach(([key, value]) => {
                if (key !== 'pwd' && key !== 'confirmPassword') {
                    localStorage.setItem(key, value || '');
                }
            });
        }, 500); // Only save once every 500 ms to prevent performance issues
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timeoutId);
    }, [formik.values]);

    useEffect(() => {
        Object.keys(formik.initialValues).forEach(key => {
            const value = localStorage.getItem(key);
            if (value && key !== 'pwd' && key !== 'confirmPassword') {
                formik.setFieldValue(key, value);
            }
        });
    }, []);

    useEffect(() => {
        if (isRegistered) {
            Object.keys(formik.initialValues).forEach(key => {
                localStorage.removeItem(key);
            });
        }
    }, [isRegistered]);

    const handleChange = event => {
        setRegistration_goal(event.target.value);
    };
    useEffect(() => {
        if (isRegistered) {
            const timer = setTimeout(() => {
                setIsRegistered(false);
                navigate("/signin");
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [isRegistered, navigate]);


    return (
        <ThemeProvider theme={GlobalStyleOverrides()} >
            <Grid container sx={{ height: "100vh" }}>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(/assets/47.jpg)',
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        alignContent: "center"

                    }}
                />

                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 10,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            alignContent: "center"

                        }}
                    >
                        <Typography variant="body2" color="secondary.main" mb={4}>
                            {formik.errors.errorLabel && <div style={{ color: 'red' }}>{formik.errors.errorLabel}</div>}
                            نورينا بإنشاء حسابك معانا

                        </Typography>

                        {/*  somewhere in your form JSX */}

                        <Box component="form" sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            alignContent: "center",
                            textAlign: "center"
                        }} >
                            {/* <Validate name="internal key 1" custom={[() => !!linkedValue, 'Textfield 2 is empty']} reference={linkedRef}> */}
                            <TextField
                                label=" ممكن اسمك الثنائي"
                                name="name"
                                className="Signup--form"
                                margin="normal"
                                required
                                fullWidth
                                type="text"
                                textalign="right"
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.name}
                                onBlur={formik.handleBlur}
                                onChange={handleInputChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                sx={{ outline: formik.touched.name && formik.errors.name ? 'none' : '' }}
                            />

                            <TextField
                                label=" متى عيد ميلادك؟ "
                                name="birthday"
                                className="Signup--form"
                                margin="normal"
                                required
                                fullWidth
                                type="date"
                                textalign="right"
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.date_of_birth}
                                onBlur={formik.handleBlur}
                                onChange={handleInputChange && ((event) => {
                                    formik.setFieldValue("date_of_birth", event.target.value);
                                })}
                                error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                                helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                                inputProps={{
                                    max: new Date().toISOString().split('T')[0], // YYYY-MM-DD format
                                }}
                            />
                            <TextField
                                label=" فين ساكنة؟"
                                name="address"
                                value={formik.values.address}
                                className="Signup--form"
                                margin="normal"
                                fullWidth
                                type="text"
                                textalign="right"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setAddress(e.target.value);
                                }}
                                placeholder="جدة، حي"
                                dir='rtl'
                            />
                            <TextField
                                className="Signup--form"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="البريد الالكتروني"
                                name="email"
                                autoComplete="email"
                                //autoFocus
                                // autoFocus
                                InputLabelProps={{ shrink: true }}
                                type="email"
                                value={formik.values.email}
                                onChange={handleInputChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <TextField
                                className="Signup--form"
                                margin="normal"
                                required
                                fullWidth
                                id="number"
                                name="national_id"
                                value={formik.values.national_id}
                                label=" رقم هويتك "
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                onBlur={formik.handleBlur}
                                onChange={handleInputChange}
                                error={formik.touched.national_id && Boolean(formik.errors.national_id)}
                                helperText={formik.touched.national_id && formik.errors.national_id}
                            />
                            <TextField
                                className="Signup--form"
                                margin="normal"
                                required
                                fullWidth
                                id="phone"
                                name="phone_number"
                                value={formik.values.phone_number}
                                label=" رقم جوالك يبدأ ب05"
                                type="telephone"
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                InputLabelProps={{ shrink: true }}
                                onBlur={formik.handleBlur}
                                onChange={handleInputChange}
                                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                helperText={formik.touched.phone_number && formik.errors.phone_number}
                            />
                            <TextField
                                className="Signup--form"
                                margin="normal"
                                required
                                fullWidth
                                name="pwd"
                                label="حطي كلمة السر"
                                type="password"
                                id="pwd"
                                autoComplete="current-password"
                                InputLabelProps={{ shrink: true }}
                                value={formik.values.pwd}
                                onBlur={formik.handleBlur}
                                onChange={handleInputChange}
                                error={formik.touched.pwd && Boolean(formik.errors.pwd)}
                                helperText={formik.touched.pwd && formik.errors.pwd}
                            />
                            <TextField
                                className="Signup--form"
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label=" كلمة سرك كمان مرة"
                                type="password"
                                id="confirmPassword"
                                autoComplete="current-password"
                                InputLabelProps={{ shrink: true }}
                                onBlur={formik.handleBlur}
                                onChange={handleInputChange}
                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                            />

                            <Typography variant="body2" color="secondary.main" mt={4} mb={4} textalign='center'>
                                ايش هدفك من الإنضمام لينا
                            </Typography>

                            <FormControl fullWidth  >
                                <InputLabel id="objectives-select-label"> هدفك من الإلتحاق بالجيم؟</InputLabel>
                                <Select
                                    labelId="objectives-select-label"
                                    id="objectives-select"
                                    name="registration_goal"
                                    value={formik.values.registration_goal}
                                    onChange={handleInputChange}
                                    label="Age"
                                >
                                    <MenuItem value={'ممارسة الرياضة في بيئة ممتعة'}>ممارسة الرياضة في بيئة ممتعة</MenuItem>
                                    <MenuItem value={'الزيادة من اللياقة '}>الزيادة من اللياقة </MenuItem>
                                    <MenuItem value={'تجربة مهارة جديدة '}>تجربة مهارة جديدة </MenuItem>
                                    <MenuItem value={'ملئ وقت الفراغ بالرياضة '} selected>  ملئ وقت الفراغ بالرياضة </MenuItem>
                                </Select>
                            </FormControl>

                            <FormGroup mt={4} dir='rtl' sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}>
                                <FormControlLabel
                                    name="is_health_issues"
                                    control={
                                        <Checkbox
                                            checked={formik.values.is_health_issues}
                                            onChange={formik.handleChange}
                                        />
                                    }
                                    label=" عندك مشاكل صحية؟ عرفينا عشان نساعدك"
                                />

                                <TextField
                                    className="Signup--form"
                                    margin="normal"
                                    label=" مشاكلك الصحية"
                                    fullWidth
                                    name="health_issues"
                                    value={formik.values.health_issues}
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.health_issues && Boolean(formik.errors.health_issues)}
                                    helperText={formik.touched.health_issues && formik.errors.health_issues}
                                    onChange={formik.handleChange}
                                />

                            </FormGroup>

                            <FormGroup mt={4} dir='rtl' sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}>

                                <FormControlLabel
                                    name="is_past_injuries"
                                    control={
                                        <Checkbox
                                            checked={formik.values.is_past_injuries}
                                            onChange={formik.handleChange}
                                        />
                                    }
                                    label=" عندك اصابات سابقة؟  "
                                />

                                <TextField
                                    className="Signup--form"
                                    margin="normal"
                                    label="اصاباتك السابقة"
                                    fullWidth
                                    name="past_injuries"
                                    value={formik.values.past_injuries}
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.past_injuries && Boolean(formik.errors.past_injuries)}
                                    helperText={formik.touched.past_injuries && formik.errors.past_injuries}
                                    onChange={formik.handleChange}
                                />
                            </FormGroup>


                            <FormGroup mt={4} dir='rtl' sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}>
                                <FormControlLabel
                                    name="is_taking_meds"
                                    control={
                                        <Checkbox
                                            checked={formik.values.is_taking_meds}
                                            onChange={formik.handleChange}
                                        />}
                                    label=" بتاخذي أدوية بوصفة طبية؟ "
                                />
                            </FormGroup>

                            <Typography variant="body2" color="secondary.main" mt={4} mb={4} alignContent={'center'}>
                                نكلم مين في حالة الطوارئ لاسمح الله
                            </Typography>

                            <TextField
                                className="Signup--form"
                                margin="normal"
                                required
                                fullWidth
                                name="emergency_name"
                                value={formik.values.emergency_name}
                                label="الاسم الكامل"
                                type="text"
                                InputLabelProps={{ shrink: true }}
                                onBlur={formik.handleBlur}
                                onChange={handleInputChange}
                                error={formik.touched.emergency_name && Boolean(formik.errors.emergency_name)}
                                helperText={formik.touched.emergency_name && formik.errors.emergency_name}
                            />
                            <TextField
                                className="Signup--form"
                                margin="normal"
                                required
                                fullWidth
                                id="emergency_phone"
                                name="emergency_phone"
                                value={formik.values.emergency_phone}
                                label=" رقم الجوال"
                                type="telephone"
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                InputLabelProps={{ shrink: true }}
                                onBlur={formik.handleBlur}
                                onChange={handleInputChange}
                                error={formik.touched.emergency_phone && Boolean(formik.errors.emergency_phone)}
                                helperText={formik.touched.emergency_phone && formik.errors.emergency_phone}
                            />
                            <TextField
                                className="Signup--form"
                                margin="normal"
                                required
                                fullWidth
                                name="emergency_relation"
                                value={formik.values.emergency_relation}
                                label=" صلة القرابة "
                                type="text"
                                InputLabelProps={{ shrink: true }}
                                onBlur={formik.handleBlur}
                                onChange={handleInputChange}
                                error={formik.touched.emergency_relation && Boolean(formik.errors.emergency_relation)}
                                helperText={formik.touched.emergency_relation && formik.errors.emergency_relation}
                            />

                            <FormControlLabel
                                dir='rtl'
                                control={
                                    <Checkbox
                                        id="termscheckbox" onChange={(e) => setIsTermsAccepted(e.target.checked)}
                                    />
                                }
                                label={
                                    <label htmlFor="termscheckbox" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <Typography>قرأت وأوافق على</Typography>
                                        <Link className='register--link' to="/terms-and-conditions">
                                            <Typography>&nbsp;  الشروط والأحكام</Typography>
                                        </Link>
                                    </label>
                                }
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}
                            />


                            <Button
                                onClick={formik.handleSubmit}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={!isTermsAccepted}
                            >
                                انشاء الحساب
                            </Button>
                            <Grid container justifyContent={'center'}>
                                <Grid item >
                                    <Typography variant="body3" color="secondary.main" mt={4} mb={4} textalign='center'>
                                        عندك حساب قبل؟
                                    </Typography>
                                    <Link className='register--link' to={"/signin"}> سجلي دخول  </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Dialog open={isRegistered}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CheckCircleIcon style={{ color: 'green', fontSize: '50px', marginRight: '10px', marginTop: '10px' }} />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>تم التسجيل بنجاح</span>
                            <span style={{ fontSize: '10px', marginTop: '20px' }}>سيتم تحويلك إلى صفحة تسجيل الدخول بعد ثانيتين</span>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </ThemeProvider>
    );
}

export default Register;
